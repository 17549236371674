import React, { useState, useEffect, useRef, forwardRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  Modal,
  Box,
} from "@mui/material";
import {
  CommanTableContain,
  ContainerCustom,
  PaginationContain,
  SmartSearchContain,
  TableModal,
  modalButton,
} from "../../style/table";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import { ModalContain } from "../../style/HO/MVRManualCreation";
import CloseIcon from "@mui/icons-material/Close";

const MuiTable = forwardRef(
  ({ headers, data, defaultRowsPerPage = 10, total, colSpan }, ref) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
    const [search, setSearch] = useState("");
    const [columnWidths, setColumnWidths] = useState([]);
    const headerRefs = useRef([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    // modal start
    const handleModalOpen = (image) => {
      setSelectedImage(image);
      setModalOpen(true);
    };

    const handleModalClose = () => {
      setModalOpen(false);
      setSelectedImage(null);
    };
    // mdoal end

    // Handle Pagination
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    // Filter data for search
    const filteredData = data.filter((row) =>
      headers.some((header) =>
        row[header.id]?.toString().toLowerCase().includes(search.toLowerCase())
      )
    );

    // Paginate the filtered data
    const paginatedData = filteredData.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );

    // Measure column widths after rendering
    useEffect(() => {
      const calculateWidths = () => {
        if (headerRefs.current.length) {
          const widths = headerRefs.current.map((ref) => {
            const rect = ref?.getBoundingClientRect();
            return rect ? rect.width : 0; // Include borders in the width calculation
          });
          setColumnWidths(widths);
        }
      };

      calculateWidths();
    }, [headers, filteredData]);

    // Calculate the grand totals for numeric fields
    const calculateGrandTotal = (field) => {
      return data.reduce((total, row) => {
        const value = parseFloat(row[field]) || 0; // Ensure it is a number
        return total + value;
      }, 0);
    };

    // Calculate totals for specific fields in paginated data (e.g., total_mom_points)
    const calculateColumnTotal = (field) => {
      return paginatedData.reduce((total, row) => {
        const value = parseFloat(row[field]) || 0;
        return total + value;
      }, 0);
    };

    return (
      <>
        {/* Search Field */}
        <ContainerCustom>
          <SmartSearchContain>
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              fullWidth
              marginleft="auto"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <SearchIcon />
          </SmartSearchContain>
        </ContainerCustom>

        {/* Table */}
        <CommanTableContain ref={ref}>
          <Table stickyHeader>
            <TableHead>
              {/* Group Row */}
              {headers.some((header) => header.group) && ( // Check if any header has a group
                <TableRow>
                  {headers.reduce((acc, header, index) => {
                    if (
                      index === 0 ||
                      header.group !== headers[index - 1].group
                    ) {
                      const groupHeaders = headers.filter(
                        (h) => h.group === header.group
                      );
                      acc.push(
                        <TableCell
                          key={header.group}
                          colSpan={groupHeaders.length}
                          align="center"
                          sx={{
                            backgroundColor: "#f5f5f5",
                            fontWeight: "bold",
                            textAlign: "center",
                            position: "sticky",
                            top: 0,
                            zIndex: 5,
                          }}
                        >
                          {header.group}
                        </TableCell>
                      );
                    }
                    return acc;
                  }, [])}
                </TableRow>
              )}

              {/* Column Header Row */}
              <TableRow>
                {headers.map((header, index) => {
                  // Calculate left position for sticky columns
                  const leftPosition = columnWidths
                    .slice(0, index)
                    .reduce((acc, width) => acc + width, 0);

                  return (
                    <TableCell
                      key={header.id}
                      ref={(el) => (headerRefs.current[index] = el)} // Store refs for measuring width
                      sx={{
                        position: "sticky",
                        left: header.sticky ? `${leftPosition}px` : "auto",
                        top: headers.some((header) => header.group)
                          ? "40px"
                          : "0px", // Adjust if group headers exist
                        zIndex: header.sticky ? 4 : 3,
                        backgroundColor: "white", // Ensure contrast
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {header.label}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {paginatedData.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {headers.map((header, index) => {
                    // Check if the column is the dealer code column
                    const isLink = header.link; // Replace 'dealerCode' with the actual field name

                    const isStatus = header.id === "evaluation_status";
                    const status = row.evaluation_status;

                    // Calculate left position for sticky cells
                    const leftPosition = columnWidths
                      .slice(0, index)
                      .reduce((acc, width) => acc + width, 0);

                    return (
                      <TableCell
                        key={header.id}
                        className={`Form_${status}  ${
                          isStatus ? "Status" : ""
                        }`}
                        sx={{
                          position: header.sticky ? "sticky" : "static",
                          left: header.sticky ? `${leftPosition}px` : "auto",
                          zIndex: 2,
                          backgroundColor: "white",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                      >
                        {isLink ? (
                          // If it's the dealerCode column, wrap in a Link
                          // <Link to={`/mvrformfill/${row[header.id]}`}>{row[header.id] || "-"}</Link>
                          <Link to={header.link}>{row[header.id] || "-"}</Link>
                        ) : header.mediaModal ? (
                          // If mediaModal is true, show the button with an image
                          <div
                            onClick={() => handleModalOpen(row[header.id])}
                            className="ModalImag"
                            style={{
                              width: "35px",
                              overflow: "hidden",
                              margin: "auto",
                            }}
                          >
                            <img
                              src={row[header.id]}
                              alt="img"
                              style={{ width: "100%" }}
                            />
                          </div>
                        ) : (
                          // Otherwise, just display the value
                          row[header.id] || "-"
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
              {/* Grand Total Row */}
              {total && (
                <TableRow>
                  <TableCell
                    colSpan={colSpan}
                    sx={{ position: "sticky", left: "0px", zIndex: 2 }}
                  >
                    Grand Total
                  </TableCell>
                  {headers.slice(colSpan).map((header) => {
                    if (header.total) {
                      return (
                        <TableCell key={header.id}>
                          {calculateColumnTotal(header.id)}
                        </TableCell>
                      );
                    }
                    return <TableCell key={header.id} />;
                  })}
                </TableRow>
              )}
            </TableBody>
          </Table>
        </CommanTableContain>

        {/* Pagination */}
        <PaginationContain>
          <TablePagination
            component="div"
            count={filteredData.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </PaginationContain>

        {/* image modal start */}
        {modalOpen && (
          <ModalContain>
            <TableModal>
              <div className="ModalHeader">
                <h4 className="Title">Image Preview</h4>
                <button
                  className="CloseButton"
                  onClick={() => handleModalClose()}
                >
                  <CloseIcon />
                </button>
              </div>
              <div className="ModalBody">
                {/* Image Preview */}
                {selectedImage && <img src={selectedImage} alt="Preview" />}
              </div>
            </TableModal>
          </ModalContain>
        )}

        {/* image modal end */}
      </>
    );
  }
);

export default MuiTable;
