import { Box, Button, TableContainer } from "@mui/material";
import { minWidth, styled, width } from "@mui/system";
import { Colors } from "../theme";
import { padding } from "polished";



export const CommanTableContain = styled(TableContainer)(()=>({
    maxHeight: '400px',
    borderBottom:'1px solid rgba(224, 224, 224, 1)',
    '& table tr th,& table tr td':{
        borderRight:'1px solid rgba(224, 224, 224, 1)',
        borderBottom:'1px solid rgba(224, 224, 224, 1)',
        borderLeft:'none',
        borderTop:'none',
        textAlign:'center',
        textTransform:'capitalize',
    },
    '& table thead tr:first-of-type th':{
        borderTop:'1px solid rgba(224, 224, 224, 1)',
        // backgroundColor:Colors.headColor,
        backgroundColor:'#bec4cb',
        color:Colors.black, 
        whiteSpace: 'nowrap',
        '& span':{
            fontSize:'12px',
            display:'block',
        },
    },
    '& table tr td':{
        backgroundColor:'#fff',
        padding:'7px',
        '& button.action':{
            color: '#000',
            border: 'none',
            outline: 'none',
            padding: '7px 15px',
            borderRadius: '5px',
            fontSize: '12px',
            height: '30px',
            '&.save':{
                color:'#fff',
                backgroundColor: '#009000',
            },
        },
        '& a':{
        fontSize:'12px',
        color:'#007FFF',
        },
        '& input,& textarea, & select':{
            fontSize:'12px',
            width:'100%',
            padding:'5px',
            borderRadius:'5px',
        },
        '& .MuiStack-root':{
            padding:'0',
            '& .MuiFormControl-root':{
                minWidth:'150px',
                maxWidth:'150px',
                margin:"auto",
                border:'1px solid rgba(0, 0, 0, 0.23)',
                borderRadius:'5px',
                '& label':{
                    fontSize:'11px',
                    top:'-5px'
                },
                '& .MuiInputBase-input':{
                    padding:'9px 14px',
                    height:'auto',
                },
                '& .MuiOutlinedInput-notchedOutline':{
                    display:'none', 
                }
            }
        }
    },
    '& table tr:last-of-type td':{
        borderBottom:'none',
    },
    '& table tr td:first-of-type,& table tr th:first-of-type':{
        borderLeft:'1px solid rgba(224, 224, 224, 1)',
    },
    '& table tr':{
        '&:hover':{
            '& td.Form_accepted,& td.Form_submitted':{
                backgroundColor:Colors.formAcceptedBg,
            },
            '& td.Form_in-process,& td.Form_reconsideration':{
                backgroundColor:Colors.formInprocessBg,
            },
            '& td.Form_pending':{
                backgroundColor:Colors.formInprocessBg,
            },
        },
        '& td':{
            '&.Form_accepted,&.Form_submitted':{
                color:Colors.formAccepted,},
                '&.Form_in-process,&.Form_reconsideration':{
                    color:Colors.formInprcess,},
                    '&.Form_pending':{
                        color:Colors.formPending,}
        },
    },

}))

export const SmartSearchContain = styled(Box)(()=>({
    width:'200px',
    position:'relative',
    // marginLeft:'auto',
    '& .MuiFormControl-root':{
        margin:'0'
    },
    '& label':{
        fontSize:'12px',
    },
    '& input':{
        fontSize:'12px',
        paddingRight:'25px',
        backgroundColor:'#fff',
        '&::placeholder':{
            fontSize:'12px',
        },
    },
    '& svg':{
        position:'absolute',
        right:'3px',
        top:'7px',
        color:Colors.light_gray,
    },
}))

export const PaginationContain = styled(Box)(()=>({
    '& .MuiTablePagination-select':{
        zIndex:11,
    },

}))

export const PdfButton = styled(Button)(()=>({
    backgroundColor:'transparent',
    minWidth:'30px',
    borderRadius:'5px',
    color:Colors.pending,
}))

export const ContainerCustom = styled(Box)(()=>({
    display:'flex',
    justifyContent:'end',
    alignItems:'center',
    backgroundColor:'#f5f5f5',
    marginBottom:'10px',
    padding:' 0',
    borderRadius:'5px',
}))

export const TableModal = styled(Box)(() => ({
    maxWidth: '550px',
    width: '100%',
    maxHeight: '500px',
    backgroundColor: Colors.white,
    display: 'flex',
    flexDirection: 'column',
    '& .ModalHeader': {
      backgroundColor: Colors.primary,
      color: Colors.white,
      fontSize: '14px',
      position: 'relative',
      padding: '15px',
      '& .Title': {
        paddingRight: '30px',
      },
      '& .CloseButton': {
        position: 'absolute',
        right: '5px',
        top: '8px',
        width:'30px',
        height:'30px',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        backgroundColor:'transparent',
        color:Colors.white,
        '& svg': {
          color: 'inherit',
        },
      },
    },
    '& .ModalBody': {
      padding: '10px',
      overflow: 'hidden', // Correct spelling
      flex: '1 1 auto', // Ensures modal body stretches to fill available space
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height:'420px',
      '& img': {
        maxWidth: '530px', // Constrain the image width
        width:'100%',
        maxHeight: '400px', // Constrain the image height to the modal body
        objectFit: 'contain', // Ensures the image fits inside the container
      },
    },
  }));
  
