import React, { useEffect, useState } from 'react'
import {  Tab, Tabs } from '@mui/material';
import { ButtonContain, Card, CardContain, FieldContain, TabsContain } from '../../style/Home'
import Grid from '@mui/material/Grid2';
import Filter from '../../Components/filters';
import Table from '../../Components/table/Table';
import ExcelIcon from '../../assets/images/icons/excel.png'
import { GuidelineContain } from '../../style/AM/MoMStatusUpdate';

const MoMEscalatedPoints = () => {
  const [value, setValue] = useState('momstatus'); 
  const [isSticky, setIsSticky] = useState(false); // To track scroll position
  const [filterValues, setFilterValues] = useState({});

  // position sticy start
  useEffect(() => {

    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true); // Add 'sticky' class when scroll > 56px
      } else {
        setIsSticky(false); // Remove 'sticky' class
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  // position sticy end







  const filtersData = [
    {
      xs: 6, sm: 4 , lg: 2,
      key:'region',
      label: 'Region',
      type: 'input',
      value:'Delhi',
      disable:true,
    },
    {
      xs: 6, sm: 4 , lg: 2,
      key:'zone',
      label: 'Zone',
      type: 'input',
      value:'Delhi South',
      disable:true,
    },
    {
      xs: 6, sm: 4 , lg: 2,
      key:'area',
      label: 'Area',
      type: 'input',
      value:'Lajpat Nagar',
      disable:true,
    },
    {
      xs: 6, sm: 4 , lg: 2,
      key:'dealer_code_name',
      label: 'Dealer Code Name',
      type: 'select',
      options: [
        { value: 'nikhil', label: '1010 - Nikhil Kumar' },
        { value: 'Girija', label: '1011 - Girija Kumar' },
      ],
    },
    {
      xs: 6, sm: 4 , lg: 2,
      key:'year',
      label: 'Financial Year',
      type: 'select',
      options: [
        { value: '2024-2025', label: '2024-2025' },
        { value: '2023-2024', label: '2023-2024' },
        { value: '2022-2023', label: '2022-2023' },
      ],
    },
    {
      xs: 6, sm: 4 , lg: 2,
      key:'month',
      label: 'Month',
      type: 'select',
      options: [
        { value: 'january', label: 'January' },
        { value: 'february', label: 'February' },
        { value: 'march', label: 'March' },
      ],
    },
    {
      xs: 6, sm: 4 , lg: 2,
      key:'escalated_days',
      label: 'Escalated Days',
      type: 'select',
      options: [
        { value: '1to4', label: '1 To 4' },
        { value: '5to6', label: '5 To 6' },
        { value: '7to9', label: '7 To 9' },
      ],
    },
  ];


  const handleFilterChange = (key, value) => {
    setFilterValues((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  // table data start
  const headers = [
    { id: 'dealer_code', label: 'Dealer Code', sticky: true },
    { id: 'dealership_name', label: 'Dealership Name' , sticky: true },
    { id: 'mvr_created_date', label: 'MVR Created Date' },
    { id: 'concern_points', label: 'Discussion/Concern Points' },
    { id: 'countermeasure_plan', label: 'Countermeasure Plan' },
    { id: 'escalated_days', label: 'Escalated Days' },
    { id: 'target_date', label: 'Target Date' },
    { id: 'revised_target_date', label: 'Revised Target Date' },
    { id: 'escalation_level', label: 'Escalation Level' },
    { id: 'status', label: 'Status' },
  ];

  const data = [
    { dealer_code: 'AP010001', dealership_name:'Nikhil PVT LTD' , mvr_created_date: '26-10-2024', concern_points:'Well Groomed Manpower Wearing Uniform & Badge',countermeasure_plan:'asasa',escalated_days:'2',target_date:'30-10-2024',revised_target_date:' ',escalation_level:'RM',status:'2'},
    { dealer_code: 'AP010002', dealership_name:'Nikhil PVT LTD' , mvr_created_date: '26-10-2024', concern_points:'Well Groomed Manpower Wearing Uniform & Badge',countermeasure_plan:'asasa',escalated_days:'2',target_date:'30-10-2024',revised_target_date:' ',escalation_level:'RM',status:'2'},
    { dealer_code: 'AP010003', dealership_name:'Nikhil PVT LTD' , mvr_created_date: '26-10-2024', concern_points:'Well Groomed Manpower Wearing Uniform & Badge',countermeasure_plan:'asasa',escalated_days:'2',target_date:'30-10-2024',revised_target_date:' ',escalation_level:'RM',status:'2'},
    { dealer_code: 'AP010004', dealership_name:'Nikhil PVT LTD' , mvr_created_date: '26-10-2024', concern_points:'Well Groomed Manpower Wearing Uniform & Badge',countermeasure_plan:'asasa',escalated_days:'2',target_date:'30-10-2024',revised_target_date:' ',escalation_level:'RM',status:'2'},
  ];

  // table data end


  return (
   <>
   <CardContain>
     <Card mt={1} className='Filter_bg'>
       <Grid container spacing={1}>
       <Filter filters={filtersData} onFilterChange={handleFilterChange}></Filter>
         <Grid item size={{xs:12 , sm:8 , lg:10}}>
         <ButtonContain sx={{height:'100%', alignItems:'end', gap:'5px'}}>
               <button className="SearchButton">Search</button>
               <button className="ResetButton">Reset</button>
               <button className="ExportButton"><img src={ExcelIcon} alt="Excel icon" /></button>
         </ButtonContain>
         </Grid>
       </Grid>
     </Card>
     </CardContain>
     <CardContain>
     <Card mt={2}>
            <GuidelineContain>
              <div className="Guidelines">
                <p><span>Escalation Guidelines N = Target Date : </span> </p>
              </div>
              <div className="Guidelines">
                <p> N + 1 Day at AM | n + 3 Days at ZM | N + 5 Days at RM</p>
              </div>
            </GuidelineContain>
     <Table headers={headers} data={data} defaultRowsPerPage={10} colSpan={2}/>
     </Card>
     </CardContain>
   </>
  )
}

export default MoMEscalatedPoints