import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Grid from "@mui/material/Grid";
import { FieldContain } from "../../style/Home";

export default function BasicDatePicker({ startDate, setStartDate, endDate, setEndDate }) {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    if (endDate && date > endDate) {
      setEndDate(null);
    }
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <FieldContain>
          <label htmlFor="datefrom">Valid From</label>
          <DatePicker
            selected={startDate}
            onChange={handleStartDateChange}
            dateFormat="dd-MM-yyyy"
            placeholderText="Select Start Date"
            className="datepicker-input"
            minDate={today}
            maxDate={endDate || null}
          />
        </FieldContain>
      </Grid>
      <Grid item xs={6}>
        <FieldContain>
          <label htmlFor="dateto">Valid To</label>
          <DatePicker
            selected={endDate}
            onChange={handleEndDateChange}
            dateFormat="dd-MM-yyyy"
            placeholderText="Select End Date"
            className="datepicker-input"
            minDate={startDate || today}
          />
        </FieldContain>
      </Grid>
    </Grid>
  );
}
