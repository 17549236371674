import React, { useEffect, useState, useRef } from "react";
import { ScheduleVisitContain, ScheduleTabContain } from '../../style/AM/ScheduleVisit'
import { ButtonContain, Card, CardContain } from "../../style/Home";
import Filter from "../../Components/filters";
import Grid from "@mui/material/Grid2";
import ExcelIcon from "../../assets/images/icons/excel.png";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  CommanTableContain,
  ContainerCustom,
  SmartSearchContain,
} from "../../style/table";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { Loader } from "../../utils/loader";
import { GetDropdownData, GetMvrVisits, handleVisitPlan, handleRevisedPlanAPI } from "../../services/scheduleVisit";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getCurrentFinancialYear, getCurrentMonth } from "../../utils/helper";
import { NotAvailable } from "../../utils/constants";
// import { formatDate } from "react-datepicker/dist/date_utils";
import ClipLoader from "react-spinners/ClipLoader";

const ScheduleVisit = () => {
  const [dateValue, setDateValue] = useState();
  const [loader, setLoader] = useState(false)
  const [dropDownData, setDropDownData] = useState([])
  const [selectedRegions, setSelectedRegions] = useState([])
  const [selectedZone, setAvailableZone] = useState([])
  const [selectedAreas, setSelectedAreas] = useState([])
  const [selectedFinYear, setSelectedFinYear] = useState(getCurrentFinancialYear())
  const [selectedPeriod, setSelectedPeriod] = useState(getCurrentMonth())
  const [selectedDealer, setSelectedDealer] = useState([])
  const [mvrVisits, setMvrVisits] = useState([])

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [loadingIndex, setLoadingIndex] = useState(null);
  // date function state
  const [rowData, setRowData] = useState([]);
  useEffect(() => {
    if (mvrVisits?.data?.visits) {
      setRowData(mvrVisits.data.visits.map(() =>
      ({
        date: null,
        reason: "",
        revisedVisitPlanDate: null,
        isVisitPlanSubmitted: false
      })));
    }
  }, [mvrVisits]);
  useEffect(() => {
  }, [rowData]);

  //useRef for storing textarea value
  const textAreaRef = useRef([])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page when rows per page change
  };

  const filtersData = [
    {
      xs: 6,
      sm: 4,
      lg: 2,
      key: "region",
      label: "Region",
      type: "select",
      multipleSelect: true,
      options: dropDownData.regions,
    },
    {
      xs: 6,
      sm: 4,
      lg: 2,
      key: "zone",
      label: "Zone",
      type: "select",
      multipleSelect: true,
      options: dropDownData.zones,
    },
    {
      xs: 6,
      sm: 4,
      lg: 2,
      key: "area",
      label: "Area",
      type: "select",
      multipleSelect: true,
      options: dropDownData.areas,
    },
    {
      xs: 6,
      sm: 4,
      lg: 2,
      key: "dealerCodeName",
      label: "Dealer Code Name",
      type: "select",
      multipleSelect: true,
      options: dropDownData.dealerCodeName,
    },
    {
      xs: 6,
      sm: 4,
      lg: 2,
      key: "year",
      label: "Financial Year",
      type: "select",
      options: dropDownData.financialYears,
    },
    {
      xs: 6,
      sm: 4,
      lg: 2,
      key: "period",
      label: "Period",
      type: "select",
      options: dropDownData.periods,
    },
  ];

  const filterZone = () => {
    const updatedZones = []
    const zoneData = []
    if (selectedRegions.length) {
      if (dropDownData?.locationData?.length) {
        dropDownData.locationData.forEach(el => {
          if (selectedRegions.includes(el.region)) {
            if (!updatedZones.some(zone => zone.value === el.zone)) {
              updatedZones.push({ value: el.zone, label: el.zone });
              zoneData.push(el.zone)
            }
          }
        })

      }
    } else {
      dropDownData?.locationData?.forEach(el => {
        if (!updatedZones.some(zone => zone.value === el.zone)) {
          updatedZones.push({ value: el.zone, label: el.zone });
        }
      })
    }
    const updatedData = {
      ...dropDownData,
      zones: updatedZones
    }
    setDropDownData(updatedData)
    setAvailableZone(zoneData)
  }

  const handleFilterChange = (filterKey, value) => {
    const setters = {
      region: setSelectedRegions,
      zone: setAvailableZone,
      area: setSelectedAreas,
      year: setSelectedFinYear,
      period: setSelectedPeriod,
      dealerCodeName: setSelectedDealer
    };
    setters[filterKey]?.(value);
  };

  const filterArea = () => {
    if (!selectedZone.length || !dropDownData?.locationData?.length) return;

    const updatedAreas = [];
    const areaData = [];

    dropDownData.locationData.forEach(el => {
      if (selectedZone.includes(el.zone) && !updatedAreas.some(area => area.value === el.area)) {
        updatedAreas.push({ value: el.area, label: el.area });
        areaData.push(el.area);
      }
    });

    setDropDownData(prev => ({
      ...prev,
      areas: updatedAreas
    }));

    setSelectedAreas(areaData);
  };

  const filterDealer = () => {
    if (!selectedZone.length || !dropDownData?.locationData?.length) return;

    const updatedDealer = [];
    const dealerCodes = [];

    dropDownData.locationData.forEach(el => {
      if (selectedAreas.includes(el.area) && !updatedDealer.some(dealer => dealer.value === el.dealerCode)) {
        updatedDealer.push({ value: el.dealerCode, label: el.dealerCodeName });
        dealerCodes.push(el.dealerCode);
      }
    });

    setDropDownData(prev => ({
      ...prev,
      dealerCodeName: updatedDealer,
    }));
  };

  useEffect(() => {
    filterZone()
  }, [selectedRegions])

  useEffect(() => {
    filterZone()
  }, [selectedRegions])

  useEffect(() => {
    filterArea()
  }, [selectedZone])

  useEffect(() => {
    filterDealer()
  }, [selectedAreas])



  // tabs start
  const [selectedTab, setSelectedTab] = useState("dvr_visit");

  const handleChange = (event) => {
    setSelectedTab(event.target.value);
  };

  const getDropdownData = async () => {
    try {
      setLoader(true)
      const res = await GetDropdownData()
      if (res.success) {
        setDropDownData(res)
      }
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoader(false)
    }
  }
  const GetMvrVisit = async () => {
    try {
      setLoader(true)
      const payload = {
        "financialYear": selectedFinYear,
        "period": selectedPeriod,
        "dealerCodes": selectedDealer,
        "regions": selectedRegions,
        "zones": selectedZone,
        "areas": selectedAreas,
        "search": "",
        "page": page,
        "offset": rowsPerPage
      }
      const res = await GetMvrVisits({ payload: payload })
      if (res.success) {
        setMvrVisits(res);
      }
    } catch (error) {
      console.log('error: ', error);
      if (error.code === "ERR_NETWORK") {
        toast.error("Network error");
      }
    } finally {
      setLoader(false)
    }
  }

  //Handle form submission

  // const handleFormData=async()=>{
  //        try{
  //         setLoader(true)
  //        const payload={
  //            "dealearCodes":selectedDealer,
  //            "date":dateValue ? dateValue.format("DD-MM-YYYY") : ""
  //        }; 
  //       }
  //       catch{}
  // }


  useEffect(() => {
    GetMvrVisit()
  }, [page, rowsPerPage])

  useEffect(() => {
    getDropdownData()
  }, [])

  const handleSearch = async() => {
    console.log('Search button clicked', selectedAreas, selectedDealer)
    await GetMvrVisit()
  }


  const radioButtons = [
    { value: "dvr_visit", label: "MVR Visit" },
    { value: "monthly_visit", label: "Monthly Visit" },
    { value: "de_visit", label: "QVR Visit" },
  ]

  const mvrTableCols = [
    "S.no.",
    "Dealer Code",
    "Dealer Ship Name",
    "Location",
    "Area",
    "Visit Plan",
    "Revised Visit Plan (Multiple revision are acceptable)",
    "Reason For Date Revision (Revised Visit Plan)",
    "Actual Visit Date (DVR Submitted)",
    "Days of Variance",
    "Reason For Variance (Actual Visit)",
    "Action",
  ]

  const handleSubmit = async (e, visit, index) => {
    e.preventDefault();
    setLoadingIndex(index);
    const currentRowData = rowData?.[index];
    try {
      if (!currentRowData.isVisitPlanSubmitted) {
        if (!currentRowData || !currentRowData.date) {
          toast.error("Please fill Date")
          setLoadingIndex(null);
          return;
        }
        // Construct the payload
        const payload = {
          dealerCode: visit?.dealerCode,
          visitPlannedDate: currentRowData.date,
        };

        // Make the API call
        const res = await handleVisitPlan({ payload: payload });

        if (res.success) {
          console.log('API Call Successful:', res);
          toast.success("Successfully Submitted");
          setRowData((prev) =>
            prev.map((row, i) =>
              i === index ? { ...row, isVisitPlanSubmitted: true } : row
            )
          )
          setMvrVisits(prevVisits => {
            if (prevVisits?.data?.visits) {
              prevVisits.data.visits[index].canPlanReVisit = true
              prevVisits.data.visits[index].canPlanVisit = false
            }
            return prevVisits
          })

        } else {
          console.error('API Response indicates failure:', res);
          toast.error("Data not Submitted");
          console.log('Visit Data:', visit);
        }
      }
      else {
        const reason = textAreaRef.current[index]?.value;
        if (!currentRowData.revisedPlanDate || !reason) {
          toast.error("Please fill both the revised plan date and reason!");
          return;
        }
        const payload = {
          dealerCode: visit?.dealerCode,
          reVisitPlannedDate: currentRowData.revisedPlanDate,
          reVisitReason: reason,
        }
        try {
          const response = await handleRevisedPlanAPI({ payload: payload })
          if (response.success)
            toast.success("Revised Plan Submitted")
          else {
            toast.error("Revised Plan Submission Failed");
          }
        }
        catch (error) {
          console.error("Error submitting revised plan:", error);
        }
      }
    } catch (error) {
      console.error('Error in handleSubmit:', error);
      toast.error("Data not Submitted");
    }
    finally {
      setLoadingIndex(null);
    }
  }

  // const handleDateChanges = (value, index, visit) => {
  //   const formattedDate = value ? value.format('YYYY-MM-DD') : null;
  //   console.log(formattedDate);
  //   setFormattedDate(formattedDate);
  // }
  // console.log(date);

  const handleDateChanges = (value, index) => {
    const formattedDate = value ? value.format("YYYY-MM-DD") : null;
    console.log("Formatted Date:", formattedDate);

    setRowData((prev) => {
      console.log("Previous rowData in State:", prev);
      return prev.map((row, i) =>
        i === index ? { ...row, date: formattedDate } : row
      );
    });
  };

  const handleRevisePlanDate = (value, index) => {
    const formattedDate = value ? value.format("YYYY-MM-DD") : null;
    setRowData((prev) =>
      prev.map((row, i) =>
        i === index ? { ...row, revisedPlanDate: formattedDate } : row
      )
    );
  };


  return (
    <>
      {loader && <Loader fullScreen={true} />}
      <ScheduleVisitContain>
        {/* filter start  */}
        <CardContain>
          <Card mt={1} className="Filter_bg">
            <Grid container spacing={1}>
              <Filter
                filters={filtersData}
                onFilterChange={handleFilterChange}
              ></Filter>
              <Grid item size={12}>
                <ButtonContain>
                  <button onClick={handleSearch} className="SearchButton">Search</button>
                  <button className="ResetButton">Reset</button>
                  <button className="ExportButton">
                    <img src={ExcelIcon} alt="Excel icon" />
                  </button>
                </ButtonContain>
              </Grid>
            </Grid>
          </Card>
        </CardContain>
        {/* filter end */}
        <CardContain>
          <Card mt={2}>
            <ScheduleTabContain>
              <RadioGroup
                row
                value={selectedTab}
                onChange={handleChange}
                sx={{ justifyContent: "center" }}
              >
                {radioButtons.map((item) => (
                  <FormControlLabel
                    key={item.value}
                    value={item.value}
                    control={<Radio />}
                    label={item.label}
                  />
                ))}

              </RadioGroup>
            </ScheduleTabContain>

            {selectedTab === "dvr_visit" && (
              <>
                {/*  table start */}

                <ContainerCustom>
                  <SmartSearchContain>
                    <TextField
                      label="Search"
                      variant="outlined"
                      size="small"
                      fullWidth
                      sx={{ marginLeft: "auto" }}
                    />
                    <SearchIcon />
                  </SmartSearchContain>
                </ContainerCustom>

                {/* Table */}
                <CommanTableContain>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        {mvrTableCols.map((text, index) => (
                          <TableCell key={index}>{text}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {mvrVisits?.data?.visits?.map((visit, index) => (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{visit?.dealerCode}</TableCell>
                          <TableCell>{visit?.dealerName}</TableCell>
                          <TableCell>{visit?.city ? visit.city : NotAvailable}</TableCell>
                          <TableCell>{visit?.area}</TableCell>
                          <TableCell>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DateTimePicker"]}>
                                <DatePicker
                                  label={visit?.visitPlan || "DD-MM-YYYY"}
                                  disabled={!visit?.canPlanVisit}
                                  value={dateValue}
                                  className="dateTimePicker"
                                  onChange={(newValue) => { handleDateChanges(newValue, index, visit) }}
                                  format="DD-MM-YYYY"
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      value={
                                        dateValue
                                          ? dateValue.format("DD-MM-YYYY")
                                          : ""
                                      }
                                      placeholder="dd-MM-yyyy"
                                    />
                                  )}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </TableCell>
                          <TableCell>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DateTimePicker"]}>
                                <DatePicker
                                  label="DD-MM-YYYY"
                                  disabled={!visit?.canPlanReVisit}
                                  value={dateValue}
                                  className="dateTimePicker"
                                  onChange={(newValue) => handleRevisePlanDate(newValue, index)}
                                  format="DD-MM-YYYY"
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      value={
                                        dateValue
                                          ? dateValue.format("DD-MM-YYYY")
                                          : ""
                                      }
                                      placeholder="dd-MM-yyyy"
                                    />
                                  )}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </TableCell>
                          <TableCell>
                            <textarea disabled={!visit?.canPlanReVisit} placeholder="Reason for revision" ref={(el) => (textAreaRef.current[index] = el)} />
                          </TableCell>
                          <TableCell>{visit?.actualVisitDate ? visit.actualVisitDate : NotAvailable}</TableCell>
                          <TableCell>{visit?.daysOfVariance ? visit.daysOfVariance : NotAvailable}</TableCell>
                          <TableCell>
                            <textarea disabled={true} placeholder="Reason for variance" />
                          </TableCell>
                          <TableCell>
                            <button onClick={(e) => handleSubmit(e, visit, index)} className="action save">
                              {loadingIndex === index && <ClipLoader color="#ffffff" size={15} />}
                              {loadingIndex === index ? "" : "Submit"}</button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>

                  </Table>
                </CommanTableContain>

                {/* table end */}
              </>
            )}
            {selectedTab === "monthly_visit" && (
              <>
                {/*  table start */}

                <ContainerCustom>
                  <SmartSearchContain>
                    <TextField
                      label="Search"
                      variant="outlined"
                      size="small"
                      fullWidth
                      sx={{ marginLeft: "auto" }}
                    />
                    <SearchIcon />
                  </SmartSearchContain>
                </ContainerCustom>

                {/* Table */}
                <CommanTableContain>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>S.no.</TableCell>
                        <TableCell>Dealer Code</TableCell>
                        <TableCell>Dealer Ship Name</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell>Area</TableCell>
                        <TableCell>Visit Plan</TableCell>
                        <TableCell>
                          Revised Visit Plan{" "}
                          <span>(Multiple revision are acceptable)</span>
                        </TableCell>
                        <TableCell>
                          Reason For Date Revision{" "}
                          <span>(Revised Visit Plan)</span>
                        </TableCell>
                        <TableCell>
                          Actual Visit Date <span>(DVR Submitted)</span>
                        </TableCell>
                        <TableCell>Days of Variance</TableCell>
                        <TableCell>
                          Reason For Variance <span>(Actual Visit)</span>
                        </TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>1</TableCell>
                        <TableCell>DL010002</TableCell>
                        <TableCell>Nikhil PVT LTD</TableCell>
                        <TableCell>Noida</TableCell>
                        <TableCell>Sector 62</TableCell>
                        <TableCell>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DateTimePicker"]}>
                              <DatePicker
                                label="DD-MM-YYYY"
                                value={dateValue}
                                className="dateTimePicker"
                                onChange={(newValue) => setDateValue(newValue)}
                                format="DD-MM-YYYY"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    value={
                                      dateValue
                                        ? dateValue.format("DD-MM-YYYY")
                                        : ""
                                    }
                                    placeholder="dd-MM-yyyy"
                                  />
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DateTimePicker"]}>
                              <DatePicker
                                label="DD-MM-YYYY"
                                value={dateValue}
                                className="dateTimePicker"
                                onChange={(newValue) => setDateValue(newValue)}
                                format="DD-MM-YYYY"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    value={
                                      dateValue
                                        ? dateValue.format("DD-MM-YYYY")
                                        : ""
                                    }
                                    placeholder="dd-MM-yyyy"
                                  />
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell>
                          {" "}
                          <textarea placeholder="Hey" />
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          <textarea placeholder="Hey" />
                        </TableCell>
                        <TableCell>
                          {" "}
                          <button className="action save">Submit</button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>1</TableCell>
                        <TableCell>DL010002</TableCell>
                        <TableCell>Nikhil PVT LTD</TableCell>
                        <TableCell>Noida</TableCell>
                        <TableCell>Sector 62</TableCell>
                        <TableCell>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DateTimePicker"]}>
                              <DatePicker
                                label="DD-MM-YYYY"
                                value={dateValue}
                                className="dateTimePicker"
                                onChange={(newValue) => setDateValue(newValue)}
                                format="DD-MM-YYYY"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    value={
                                      dateValue
                                        ? dateValue.format("DD-MM-YYYY")
                                        : ""
                                    }
                                    placeholder="dd-MM-yyyy"
                                  />
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DateTimePicker"]}>
                              <DatePicker
                                label="DD-MM-YYYY"
                                value={dateValue}
                                className="dateTimePicker"
                                onChange={(newValue) => setDateValue(newValue)}
                                format="DD-MM-YYYY"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    value={
                                      dateValue
                                        ? dateValue.format("DD-MM-YYYY")
                                        : ""
                                    }
                                    placeholder="dd-MM-yyyy"
                                  />
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell>
                          {" "}
                          <textarea placeholder="Hey" />
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          <textarea placeholder="Hey" />
                        </TableCell>
                        <TableCell>
                          {" "}
                          <button className="action save">Submit</button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>1</TableCell>
                        <TableCell>DL010002</TableCell>
                        <TableCell>Nikhil PVT LTD</TableCell>
                        <TableCell>Noida</TableCell>
                        <TableCell>Sector 62</TableCell>
                        <TableCell>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DateTimePicker"]}>
                              <DatePicker
                                label="DD-MM-YYYY"
                                value={dateValue}
                                className="dateTimePicker"
                                onChange={(newValue) => setDateValue(newValue)}
                                format="DD-MM-YYYY"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    value={
                                      dateValue
                                        ? dateValue.format("DD-MM-YYYY")
                                        : ""
                                    }
                                    placeholder="dd-MM-yyyy"
                                  />
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DateTimePicker"]}>
                              <DatePicker
                                label="DD-MM-YYYY"
                                value={dateValue}
                                className="dateTimePicker"
                                onChange={(newValue) => setDateValue(newValue)}
                                format="DD-MM-YYYY"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    value={
                                      dateValue
                                        ? dateValue.format("DD-MM-YYYY")
                                        : ""
                                    }
                                    placeholder="dd-MM-yyyy"
                                  />
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell>
                          {" "}
                          <textarea placeholder="Hey" />
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          <textarea placeholder="Hey" />
                        </TableCell>
                        <TableCell>
                          {" "}
                          <button className="action save">Submit</button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>1</TableCell>
                        <TableCell>DL010002</TableCell>
                        <TableCell>Nikhil PVT LTD</TableCell>
                        <TableCell>Noida</TableCell>
                        <TableCell>Sector 62</TableCell>
                        <TableCell>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DateTimePicker"]}>
                              <DatePicker
                                label="DD-MM-YYYY"
                                value={dateValue}
                                className="dateTimePicker"
                                onChange={(newValue) => setDateValue(newValue)}
                                format="DD-MM-YYYY"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    value={
                                      dateValue
                                        ? dateValue.format("DD-MM-YYYY")
                                        : ""
                                    }
                                    placeholder="dd-MM-yyyy"
                                  />
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DateTimePicker"]}>
                              <DatePicker
                                label="DD-MM-YYYY"
                                value={dateValue}
                                className="dateTimePicker"
                                onChange={(newValue) => setDateValue(newValue)}
                                format="DD-MM-YYYY"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    value={
                                      dateValue
                                        ? dateValue.format("DD-MM-YYYY")
                                        : ""
                                    }
                                    placeholder="dd-MM-yyyy"
                                  />
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell>
                          {" "}
                          <textarea placeholder="Hey" />
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          <textarea placeholder="Hey" />
                        </TableCell>
                        <TableCell>
                          {" "}
                          <button className="action save">Submit</button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>1</TableCell>
                        <TableCell>DL010002</TableCell>
                        <TableCell>Nikhil PVT LTD</TableCell>
                        <TableCell>Noida</TableCell>
                        <TableCell>Sector 62</TableCell>
                        <TableCell>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DateTimePicker"]}>
                              <DatePicker
                                label="DD-MM-YYYY"
                                value={dateValue}
                                className="dateTimePicker"
                                onChange={(newValue) => setDateValue(newValue)}
                                format="DD-MM-YYYY"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    value={
                                      dateValue
                                        ? dateValue.format("DD-MM-YYYY")
                                        : ""
                                    }
                                    placeholder="dd-MM-yyyy"
                                  />
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DateTimePicker"]}>
                              <DatePicker
                                label="DD-MM-YYYY"
                                value={dateValue}
                                className="dateTimePicker"
                                onChange={(newValue) => setDateValue(newValue)}
                                format="DD-MM-YYYY"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    value={
                                      dateValue
                                        ? dateValue.format("DD-MM-YYYY")
                                        : ""
                                    }
                                    placeholder="dd-MM-yyyy"
                                  />
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell>
                          {" "}
                          <textarea placeholder="Hey" />
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          <textarea placeholder="Hey" />
                        </TableCell>
                        <TableCell>
                          {" "}
                          <button className="action save">Submit</button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </CommanTableContain>

                {/* table end */}
              </>
            )}
            {selectedTab === "de_visit" && (
              <>
                {/*  table start */}

                <ContainerCustom>
                  <SmartSearchContain>
                    <TextField
                      label="Search"
                      variant="outlined"
                      size="small"
                      fullWidth
                      sx={{ marginLeft: "auto" }}
                    />
                    <SearchIcon />
                  </SmartSearchContain>
                </ContainerCustom>

                {/* Table */}
                <CommanTableContain>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>S.no.</TableCell>
                        <TableCell>Dealer Code</TableCell>
                        <TableCell>Dealer Ship Name</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell>Area</TableCell>
                        <TableCell>Visit Plan</TableCell>
                        <TableCell>
                          Actual Visit Date <span>(DE Submitted)</span>
                        </TableCell>
                        <TableCell>
                          Reason For Variance <span>(Actual Visit)</span>
                        </TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>1</TableCell>
                        <TableCell>DL010002</TableCell>
                        <TableCell>Nikhil PVT LTD</TableCell>
                        <TableCell>Noida</TableCell>
                        <TableCell>Sector 62</TableCell>
                        <TableCell>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DateTimePicker"]}>
                              <DatePicker
                                label="DD-MM-YYYY"
                                value={dateValue}
                                className="dateTimePicker"
                                onChange={(newValue) => setDateValue(newValue)}
                                format="DD-MM-YYYY"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    value={
                                      dateValue
                                        ? dateValue.format("DD-MM-YYYY")
                                        : ""
                                    }
                                    placeholder="dd-MM-yyyy"
                                  />
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          <textarea placeholder="Hey" />
                        </TableCell>
                        <TableCell>
                          {" "}
                          <button className="action save">Submit</button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>1</TableCell>
                        <TableCell>DL010002</TableCell>
                        <TableCell>Nikhil PVT LTD</TableCell>
                        <TableCell>Noida</TableCell>
                        <TableCell>Sector 62</TableCell>
                        <TableCell>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DateTimePicker"]}>
                              <DatePicker
                                label="DD-MM-YYYY"
                                value={dateValue}
                                className="dateTimePicker"
                                onChange={(newValue) => setDateValue(newValue)}
                                format="DD-MM-YYYY"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    value={
                                      dateValue
                                        ? dateValue.format("DD-MM-YYYY")
                                        : ""
                                    }
                                    placeholder="dd-MM-yyyy"
                                  />
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          <textarea placeholder="Hey" />
                        </TableCell>
                        <TableCell>
                          {" "}
                          <button className="action save">Submit</button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>1</TableCell>
                        <TableCell>DL010002</TableCell>
                        <TableCell>Nikhil PVT LTD</TableCell>
                        <TableCell>Noida</TableCell>
                        <TableCell>Sector 62</TableCell>
                        <TableCell>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DateTimePicker"]}>
                              <DatePicker
                                label="DD-MM-YYYY"
                                value={dateValue}
                                className="dateTimePicker"
                                onChange={(newValue) => setDateValue(newValue)}
                                format="DD-MM-YYYY"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    value={
                                      dateValue
                                        ? dateValue.format("DD-MM-YYYY")
                                        : ""
                                    }
                                    placeholder="dd-MM-yyyy"
                                  />
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          <textarea placeholder="Hey" />
                        </TableCell>
                        <TableCell>
                          {" "}
                          <button className="action save">Submit</button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>1</TableCell>
                        <TableCell>DL010002</TableCell>
                        <TableCell>Nikhil PVT LTD</TableCell>
                        <TableCell>Noida</TableCell>
                        <TableCell>Sector 62</TableCell>
                        <TableCell>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DateTimePicker"]}>
                              <DatePicker
                                label="DD-MM-YYYY"
                                value={dateValue}
                                className="dateTimePicker"
                                onChange={(newValue) => setDateValue(newValue)}
                                format="DD-MM-YYYY"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    value={
                                      dateValue
                                        ? dateValue.format("DD-MM-YYYY")
                                        : ""
                                    }
                                    placeholder="dd-MM-yyyy"
                                  />
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          <textarea placeholder="Hey" />
                        </TableCell>
                        <TableCell>
                          {" "}
                          <button className="action save">Submit</button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>1</TableCell>
                        <TableCell>DL010002</TableCell>
                        <TableCell>Nikhil PVT LTD</TableCell>
                        <TableCell>Noida</TableCell>
                        <TableCell>Sector 62</TableCell>
                        <TableCell>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DateTimePicker"]}>
                              <DatePicker
                                label="DD-MM-YYYY"
                                value={dateValue}
                                className="dateTimePicker"
                                onChange={(newValue) => setDateValue(newValue)}
                                format="DD-MM-YYYY"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    value={
                                      dateValue
                                        ? dateValue.format("DD-MM-YYYY")
                                        : ""
                                    }
                                    placeholder="dd-MM-yyyy"
                                  />
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          <textarea placeholder="Hey" />
                        </TableCell>
                        <TableCell>
                          {" "}
                          <button className="action save">Submit</button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>1</TableCell>
                        <TableCell>DL010002</TableCell>
                        <TableCell>Nikhil PVT LTD</TableCell>
                        <TableCell>Noida</TableCell>
                        <TableCell>Sector 62</TableCell>
                        <TableCell>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DateTimePicker"]}>
                              <DatePicker
                                label="DD-MM-YYYY"
                                value={dateValue}
                                className="dateTimePicker"
                                onChange={(newValue) => setDateValue(newValue)}
                                format="DD-MM-YYYY"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    value={
                                      dateValue
                                        ? dateValue.format("DD-MM-YYYY")
                                        : ""
                                    }
                                    placeholder="dd-MM-yyyy"
                                  />
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          <textarea placeholder="Hey" />
                        </TableCell>
                        <TableCell>
                          {" "}
                          <button className="action save">Submit</button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>1</TableCell>
                        <TableCell>DL010002</TableCell>
                        <TableCell>Nikhil PVT LTD</TableCell>
                        <TableCell>Noida</TableCell>
                        <TableCell>Sector 62</TableCell>
                        <TableCell>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DateTimePicker"]}>
                              <DatePicker
                                label="DD-MM-YYYY"
                                value={dateValue}
                                className="dateTimePicker"
                                onChange={(newValue) => setDateValue(newValue)}
                                format="DD-MM-YYYY"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    value={
                                      dateValue
                                        ? dateValue.format("DD-MM-YYYY")
                                        : ""
                                    }
                                    placeholder="dd-MM-yyyy"
                                  />
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          <textarea placeholder="Hey" />
                        </TableCell>
                        <TableCell>
                          {" "}
                          <button className="action save">Submit</button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>1</TableCell>
                        <TableCell>DL010002</TableCell>
                        <TableCell>Nikhil PVT LTD</TableCell>
                        <TableCell>Noida</TableCell>
                        <TableCell>Sector 62</TableCell>
                        <TableCell>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DateTimePicker"]}>
                              <DatePicker
                                label="DD-MM-YYYY"
                                value={dateValue}
                                className="dateTimePicker"
                                onChange={(newValue) => setDateValue(newValue)}
                                format="DD-MM-YYYY"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    value={
                                      dateValue
                                        ? dateValue.format("DD-MM-YYYY")
                                        : ""
                                    }
                                    placeholder="dd-MM-yyyy"
                                  />
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          <textarea placeholder="Hey" />
                        </TableCell>
                        <TableCell>
                          {" "}
                          <button className="action save">Submit</button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </CommanTableContain>
              </>
            )}
          </Card>
        </CardContain>
        <TablePagination
          component="div"
          count={mvrVisits?.data?.totalCount || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </ScheduleVisitContain>
      <ToastContainer />

    </>
  );
};

export default ScheduleVisit;
