import React, { useEffect, useState } from 'react'
import {  Tab, Tabs } from '@mui/material';
import { ButtonContain, Card, CardContain, FieldContain, TabsContain } from '../../style/Home'
import Grid from '@mui/material/Grid2';
import Filter from '../../Components/filters';
import Table from '../../Components/table/Table';
import ExcelIcon from '../../assets/images/icons/excel.png';
import { getCurrentFinancialYear, getCurrentMonth } from "../../utils/helper";
import {GetMvrTableData} from "../../services/amdWiseMvrService"

const AMDStatus = () => {
  const [value, setValue] = useState('MVRTabs'); 
  const [isSticky, setIsSticky] = useState(false); // To track scroll position
  const [filterValues, setFilterValues] = useState({});
  const [selectedFinYear, setSelectedFinYear] = useState(getCurrentFinancialYear());
  const [selectedPeriod, setSelectedPeriod] = useState(getCurrentMonth())
  const [loader, setLoader] = useState(false);
  const [mvrTableData, setMvrTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setTableData] = useState([]);
  // set table data
  const getMvrTableData = async () => {
    try{
      setLoader(true);
      const payload = {
        "financialYear": selectedFinYear,
        "period": selectedPeriod,
        "dealerCodes": [],
        "regions": [],
        "zones": [],
        "areas": [],
        "status": "",
        "search": "",
        "page": page,
        "offset": rowsPerPage
      }

      const res = await GetMvrTableData({payload: payload});

      if(res.success){
        const mappedData = res.data.MvrTableData.map(item => ({
          dealer_code: item.dealerCode,
          dealership_name: item.dealerName,
          region: item.region,
          zone: item.zone,
          area: item.area,
          city: item.city,
          evaluation_plan_date: item.evaluationDate,
          accepted_date: item.acceptedDate,
          evaluation_status: item.evaluationStatus
        }))
        setTableData(mappedData)
      }
    }catch(error){
      console.log('Error on fetching mvr table data');
    }finally{
      setLoader(false);
    }
  }
  
  useEffect(()=>{
    getMvrTableData();
  }, [])

  // tabs handle change start
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // tabs handle change end

  // position sticy start
  useEffect(() => {

    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true); // Add 'sticky' class when scroll > 56px
      } else {
        setIsSticky(false); // Remove 'sticky' class
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  // position sticy end


  // filter input start
  const [formData, setFormData] = useState({
    input1: '2024-2025', 
    input2: 'PMB',
    input3: 'Central', 
    input4: 'UP Central', 
    input5: '2024-2025', 
    input6: 'H2', 
  });
    // Handle change for all inputs
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setFormData(prevData => ({
        ...prevData,
        [name]: value, // Update the value of the input based on its name
      }));
    };
  // filter input end



  const filtersData = [
    {
      xs: 6, sm: 4 , lg: 2,
      key:'zone',
      label: 'Zone',
      type: 'select',
      multipleSelect: true,
      options: [
        { value: 'delhi-south', label: 'Delhi South' },
        { value: 'delhi-north', label: 'Delhi North' },
        { value: 'delhi-east', label: 'Delhi East' },
        { value: 'delhi-West', label: 'Delhi West' },
      ],
    },
    {
      xs: 6, sm: 4 , lg: 2,
      key:'area',
      label: 'Area',
      type: 'select',
      options: [
        { value: 'up-south', label: 'UP South' },
        { value: 'up-north', label: 'UP North' },
        { value: 'up-east', label: 'UP East' },
        { value: 'up-West', label: 'UP West' },
      ],
    },
    {
      xs: 6, sm: 4 , lg: 2,
      key:'year',
      label: 'Financial Year',
      type: 'select',
      options: [
        { value: '2024-2025', label: '2024-2025' },
        { value: '2023-2024', label: '2023-2024' },
        { value: '2022-2023', label: '2022-2023' },
      ],
    },
    {
      xs: 6, sm: 4 , lg: 2,
      key:'period',
      label: 'Period',
      type: 'select',
      options: [
        { value: 'H1', label: 'H1' },
        { value: 'H2', label: 'H2' },
      ],
    },
    {
      xs: 6, sm: 4 , lg: 2,
      key:'status',
      label: 'Status',
      type: 'select',
      options: [
        { value: 'inprocess', label: 'In Process' },
        { value: 'pending', label: 'Pending' },
        { value: 'resolved', label: 'Resolved' },
      ],
    },
  ];


  const handleFilterChange = (key, value) => {
    setFilterValues((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };
  console.log(filterValues,"filterValues")

  // table data start
  const headers = [
    { id: 'dealer_code', label: 'Dealer Code', sticky: true , link:'/mvrformfill'},
    { id: 'dealership_name', label: 'Dealership Name' , sticky: true },
    { id: 'region', label: 'Region' },
    { id: 'zone', label: 'Zone'},
    { id: 'area', label: 'Area' },
    { id: 'city', label: 'City' },
    { id: 'evaluation_plan_date', label: 'Evaluation Plan Date' },
    { id: 'accepted_date', label: 'Accepted Date' },
    { id: 'evaluation_status', label: 'Evaluation Status' },
    { id: 'sales_score', label: 'Sales Score' },
    { id: 'sales_band', label: 'Sales Band' },
    { id: 'cs_score', label: 'CS Score' },
    { id: 'overall_status', label: 'Overall Status' },
    { id: 'pdf_signature', label: 'PDF Signature by Dealer' },
    { id: 'final_scoresheet', label: 'Final Scoresheet & MoM' },
  ];

  // const data = [
  //   { dealer_code: 'AP010001', dealership_name:'Nikhil PVT LTD' ,region: 'South', zone: 'Talangana', area: 'TS1', city: 'Ghaziabad', evaluation_plan_date:'-',accepted_date:'01-01-2024',evaluation_status:'accepted',sales_score:'101',sales_band:'-',cs_score:'-',overall_status:'_',pdf_signature:'-',final_scoresheet:'-' },
  //   {dealer_code: 'AP010001', dealership_name:'Girija PVT LTD' , region: 'South', zone: 'Talangana', area: 'TS1', city: 'Ghaziabad', evaluation_plan_date:'-',accepted_date:'01-01-2024',evaluation_status:'pending',sales_score:'101',sales_band:'-',cs_score:'-',overall_status:'_',pdf_signature:'-',final_scoresheet:'-' },
  //   { dealer_code: 'AP010001', dealership_name:'Tamanna PVT LTD' ,region: 'South', zone: 'Talangana', area: 'TS1', city: 'Ghaziabad', evaluation_plan_date:'-',accepted_date:'01-01-2024',evaluation_status:'in-process',sales_score:'101',sales_band:'-',cs_score:'-',overall_status:'_',pdf_signature:'-',final_scoresheet:'-' },
  //   {  dealer_code: 'AP010001', dealership_name:'Dheeraj PVT LTD' ,region: 'South', zone: 'Talangana', area: 'TS1', city: 'Ghaziabad',evaluation_plan_date:'-',accepted_date:'01-01-2024',evaluation_status:'submitted',sales_score:'101',sales_band:'-',cs_score:'-',overall_status:'_',pdf_signature:'-',final_scoresheet:'-' },
  //   { dealer_code: 'AP010001', dealership_name:'Ramkesh PVT LTD' , region: 'South', zone: 'Talangana', area: 'TS1', city: 'Ghaziabad',evaluation_plan_date:'-',accepted_date:'01-01-2024',evaluation_status:'reconsideration',sales_score:'101',sales_band:'-',cs_score:'-',overall_status:'_',pdf_signature:'-',final_scoresheet:'-' },
  // ];
  // table data end


  return (
   <>
   <TabsContain className='TabsContain'>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="tabs example"
        className={`HomeTabs ${isSticky ? "StickyShadow" : ""}`}
      >
        <Tab label="MVR" value="MVRTabs" />
        <Tab label="QVR" value="QVRTabs" />
      </Tabs>
      </TabsContain>
      {value === "MVRTabs" && (
        <>
        <CardContain>
          <Card mt={1} className='Filter_bg'>
            <Grid container spacing={1}>
            <Filter filters={filtersData} onFilterChange={handleFilterChange}></Filter>
              <Grid item size={12}>
              <ButtonContain>
                    <button className="SearchButton">Search</button>
                    <button className="ResetButton">Reset</button>
                    <button className="ExportButton"><img src={ExcelIcon} alt="Excel icon" /></button>
              </ButtonContain>
              </Grid>
            </Grid>
          </Card>
          </CardContain>
          <CardContain>
          <Card mt={2}>
          <Table headers={headers} data={data} defaultRowsPerPage={10} />
          </Card>
          </CardContain>
        </>
      )}
      {value === "QVRTabs" && (
        <>
        <CardContain>
          <Card mt={1} className='Filter_bg'>
            <Grid container spacing={1}>
            <Filter filters={filtersData} onFilterChange={handleFilterChange}></Filter>
              <Grid item size={12}>
              <ButtonContain>
                    <button className="SearchButton">Search</button>
                    <button className="ResetButton">Reset</button>
                    <button className="ExportButton">Export</button>
              </ButtonContain>
              </Grid>
            </Grid>
          </Card>
          </CardContain>
        </>)}
   </>
  )
}

export default AMDStatus