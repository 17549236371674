import { Box } from "@mui/material";
import { styled } from "@mui/system";


export const ChartContain = styled(Box)(()=>({
    width: "100%", 
    margin: "30px auto",
    textAlign: "center",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    '& .Chart':{
        position: "relative", 
        height: "400px",
    },

}))
export const ChartHeader = styled(Box)(()=>({
    display: "flex", 
    justifyContent: "space-between" ,
    padding:'10px 20px',
    marginBottom:'20px',
    alignItems:'center',
    backgroundColor:'#f5f5f5',
    '& .Title':{},
    '& .Export':{
        padding: "10px 20px",
        backgroundColor: "#4caf50",
        color: "white",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
    },
}))