import { Box, styled } from "@mui/system";
import { Colors } from "../theme";
import { Button } from "@mui/material";


export const ApprovalConatin = styled(Box)(()=>({
    padding:'20px 10px',
    '& .ApprovalTitle':{
        backgroundColor:'#f5f5f5',
        color:Colors.primary,
        padding:'20px 10px',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    }

}))
export const ApprvalFielContain = styled(Box)(()=>({
    // padding:'10px',
    '& label':{
        display:'block',
        fontWeight:500,
        marginBottom:'7px',
        fontSize:'13px',
    },
    '& input,& select':{
        width:'100%',
        padding:'8px',
        border:'1px solid',
        borderColor:Colors.bordercolor,
        fontSize:'12px',
        borderRadius:'5px',
        minWidth:'150px',
    }
}))

export const ApprovalButton = styled(Button)(()=>({
    backgroundColor:'#0baedc',
    color:'#fff',
}))

export const CloseButton = styled(Button)(()=>({
    color:'red',
    padding:'0'
}))