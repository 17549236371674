import React, { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom";
import {  Card, CardContain } from '../../style/Home'
import Filter from '../../Components/filters'
import Grid from '@mui/material/Grid2';
import { TimeLineContain } from '../../style/timeline';
import Timeline from '../../Components/timeline';
import { CustomTab, FormTabs, FormTabsContain, FormTotalScore } from '../../style/HO/MVRFormFill';
import { Tab, Tabs } from '@mui/material';
import DynamicAccordion from '../../Components/accordian';
import { NotAvailable } from '../../utils/constants';

const MVRFormFill = () => {
  const [formTab, setFormTab] = useState('BussinessDataTab'); 
  const location = useLocation();
  const row = location.state?.row;

  useEffect(()=>{
    console.log('============>', row)
  }, [])
    // filter input start

  
    const filtersData = [
      {
        xs: 6, sm: 4 , lg: 2,
        key:'dealer-code',
        label: 'Dealer Code',
        type: 'input',
        disable: true,
        value: row?.dealer_code,
      },
      {
        xs: 6, sm: 4 , lg: 2,
        key:'dealership-name',
        label: 'Dealership Name',
        type: 'input',
        disable: true,
        value: row?.dealership_name
      },
      {
        xs: 6, sm: 4 , lg: 2,
        key:'city',
        label: 'City',
        type: 'input',
        disable: true,
        value: row?.city || NotAvailable
      },
      {
        key:'evaluator-name',
        label: 'Evaluator Name',
        type: 'input',
        disable: true,
        value:'Nikhil',
      },
      {
        xs: 6, sm: 4 , lg: 2,
        key:'period',
        label: 'Evaluation Period',
        type: 'input',
        disable: true,
        value:'H2',
      },
    ];
  
  
    const handleFilterChange = (filterKey, value) => {
      setFilterValues((prev) => ({
        ...prev, // Keep previous values
        [filterKey]: value, // Update the specific field
      }));
    };


  // tabs coding start
  const handleTabs = (event, newValue) => {
    setFormTab(newValue);
  };
  // tabs coding end

  // accordian code start

  const photoBased = [
    { id: 1, title: "photoBased Business Overview", content: "Details about Business Overview" },
    { id: 2, title: "photoBased Market Analysis", content: "Details about Market Analysis" },
    { id: 3, title: "photoBased Growth Opportunities", content: "Details about Growth Opportunities" },
  ];
  const Jugment = [
    { id: 1, title: "Judgment Business Overview", content: "Details about Business Overview" },
    { id: 2, title: "Judgment Market Analysis", content: "Details about Market Analysis" },
    { id: 3, title: "Judgment Growth Opportunities", content: "Details about Growth Opportunities" },
  ];
  // accordian code end
  // const accordionData = [
  //   { id: 1, title: "Business Overview Details about Business Overview Business Overview Details about Business OverviewBusiness Overview Details about Business OverviewBusiness Overview Details about Business Overview", content: "Details about Business Overview" },
  //   { id: 2, title: "Market Analysis", content: "Details about Market Analysis" },
  //   { id: 3, title: "Growth Opportunities", content: "Details about Growth Opportunities" },
  // ];

  

  return (
    <>
    <CardContain className='Sticky'>
      <Card mt={1} className='Filter_bg StickyChild'>
        <Grid container spacing={1}>
        <Filter filters={filtersData} onFilterChange={handleFilterChange}></Filter>
        </Grid>
        <Grid container pt={2}>
          <Timeline />
        </Grid>

      </Card>
      </CardContain>
      <FormTabsContain mt={1}>
      <FormTabs  >
        <Tabs 
        className='tascontain'
        value={formTab}
        onChange={handleTabs}
        aria-label="tabs example">
        <Tab label="Bussiness Data" value="BussinessDataTab" />
        <Tab label="Photo Based" value="PhotoBasedData" />
        <Tab label="Evaluator Judgement" value="EvaluatorJudgement" />
        </Tabs>
      </FormTabs>
      <CardContain className='white_bg' sx={{position:'relative'}}>
        <FormTotalScore>
          <p className="MomContain"><span className='MoM'>M</span> - MoM <span className='Reconsideration'>R</span> - Reconsideration</p>
          <p className='TotalScore'>Total: <span>__</span>/100</p>
        </FormTotalScore>
        <Card className='p-0'>
        {formTab  === "BussinessDataTab" && (<>
          <DynamicAccordion/>

        </>)}
        {formTab  === "PhotoBasedData" && (<>
          <DynamicAccordion accordionData={photoBased}/>
        </>)}
        {formTab  === "EvaluatorJudgement" && (<>
          <DynamicAccordion accordionData={Jugment}/>
          </>)}

        </Card>
      </CardContain>
      </FormTabsContain>
    </>
  )
}

export default MVRFormFill