import React, { useEffect, useState } from 'react'
import {  Tab, Tabs } from '@mui/material';
import { ButtonContain, Card, CardContain, FieldContain, TabsContain } from '../../style/Home'
import Grid from '@mui/material/Grid2';
import Filter from '../../Components/filters';
import Table from '../../Components/table/Table';
import ExcelIcon from '../../assets/images/icons/excel.png'

const MoMStatus = () => {
  const [value, setValue] = useState('momstatus'); 
  const [isSticky, setIsSticky] = useState(false); // To track scroll position
  const [filterValues, setFilterValues] = useState({});

  // tabs handle change start
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // tabs handle change end

  // position sticy start
  useEffect(() => {

    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true); // Add 'sticky' class when scroll > 56px
      } else {
        setIsSticky(false); // Remove 'sticky' class
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  // position sticy end


  // filter input start
  const [formData, setFormData] = useState({
    input1: '2024-2025', 
    input2: 'PMB',
    input3: 'Central', 
    input4: 'UP Central', 
    input5: '2024-2025', 
    input6: 'H2', 
  });
    // Handle change for all inputs
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setFormData(prevData => ({
        ...prevData,
        [name]: value, // Update the value of the input based on its name
      }));
    };
  // filter input end



  const filtersData = [
    {
      xs: 6, sm: 4 , lg: 2,
      key:'year',
      label: 'Financial Year',
      type: 'select',
      options: [
        { value: '2024-2025', label: '2024-2025' },
        { value: '2023-2024', label: '2023-2024' },
        { value: '2022-2023', label: '2022-2023' },
      ],
    },
    {
      xs: 6, sm: 4 , lg: 2,
      key:'month',
      label: 'Month',
      type: 'select',
      options: [
        { value: 'november', label: 'November ' },
        { value: 'december', label: 'December' },
      ],
    },
  ];


  const handleFilterChange = (filterKey, value) => {
    setFilterValues((prev) => ({
      ...prev, // Keep previous values
      [filterKey]: value, // Update the specific field
    }));
  };

  // table data start
  const headers = [
    { id: 'dealer_code', label: 'Dealer Code', sticky: true ,link:'/momstatusupdate'},
    { id: 'dealership_name', label: 'Dealership Name' , sticky: true },
    { id: 'city', label: 'City' },
    { id: 'total_mom_points', label: 'Total MoM Points',total: true },
    { id: 'open_mom_points', label: 'Open MoM Points',total: true},
    { id: 'mom_points_completed_by_dealer', label: 'MoM Points Completed By Dealers',total: true },
    { id: 'mom_points_closed_by_am', label: 'MoM Points Closed By AM' ,total: true},
    { id: 'escalated_to_am', label: 'Escalated To AM' ,total: true},
    { id: 'escalated_to_zm', label: 'Escalated To ZM',total: true },
    { id: 'escalated_to_rm', label: 'Escalated To RM' ,total: true},
  ];

  const data = [
    { dealer_code: 'AP010001', dealership_name:'Nikhil PVT LTD' , city: 'Ghaziabad', total_mom_points:'1',open_mom_points:'2',mom_points_completed_by_dealer:'2',mom_points_closed_by_am:'5',escalated_to_am:'4',escalated_to_zm:'5',escalated_to_rm:'2'},
    { dealer_code: 'AP010001', dealership_name:'Nikhil PVT LTD' , city: 'Noida', total_mom_points:'1',open_mom_points:'3',mom_points_completed_by_dealer:'',mom_points_closed_by_am:'',escalated_to_am:'3',escalated_to_zm:'4',escalated_to_rm:'3'},
    { dealer_code: 'AP010001', dealership_name:'Nikhil PVT LTD' , city: 'Delhi', total_mom_points:'2',open_mom_points:'1',mom_points_completed_by_dealer:'3',mom_points_closed_by_am:'5',escalated_to_am:'2',escalated_to_zm:'3',escalated_to_rm:'2'},
    { dealer_code: 'AP010001', dealership_name:'Nikhil PVT LTD' , city: 'Gurugram', total_mom_points:'0',open_mom_points:'0',mom_points_completed_by_dealer:'',mom_points_closed_by_am:'',escalated_to_am:'',escalated_to_zm:'',escalated_to_rm:''},
  ];

  // table data end


  return (
   <>
   <TabsContain className='TabsContain'>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="tabs example"
        className={`HomeTabs ${isSticky ? "StickyShadow" : ""}`}
      >
        <Tab label="MoM Status" value="momstatus" />
        <Tab label="QVR" value="QVRTabs" />
      </Tabs>
      </TabsContain>
      {value === "momstatus" && (
        <>
        <CardContain>
          <Card mt={1} className='Filter_bg'>
            <Grid container spacing={1}>
            <Filter filters={filtersData} onFilterChange={handleFilterChange}></Filter>
              <Grid item size={{xs:12 , sm:4 , lg:8}}>
              <ButtonContain sx={{height:'100%', alignItems:'end', gap:'5px'}}>
                    <button className="SearchButton">Search</button>
                    <button className="ResetButton">Reset</button>
                    <button className="ExportButton"><img src={ExcelIcon} alt="Excel icon" /></button>
              </ButtonContain>
              </Grid>
            </Grid>
          </Card>
          </CardContain>
          <CardContain>
          <Card mt={2}>
          <Table headers={headers} data={data} defaultRowsPerPage={10} colSpan={2} total="true"/>
          </Card>
          </CardContain>
        </>
      )}
      {value === "QVRTabs" && (
        <>
        <CardContain>
          <Card mt={1}>
            <Grid container spacing={1}>
            <Filter filters={filtersData} onFilterChange={handleFilterChange}></Filter>
              <Grid item size={12}>
              <ButtonContain>
                    <button className="SearchButton">Search</button>
                    <button className="ResetButton">Reset</button>
                    <button className="ExportButton">Export</button>
              </ButtonContain>
              </Grid>
            </Grid>
          </Card>
          </CardContain>
        </>)}
   </>
  )
}

export default MoMStatus