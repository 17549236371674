import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineController,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { ChartContain, ChartHeader } from "../../style/charts";
import ExcelIcon from '../../assets/images/icons/excel.png'
import { ButtonContain } from "../../style/Home";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  LineController,
  Title,
  Tooltip,
  Legend
);

const MultiTypeChart = ({ multiTypeChartData }) => {
  const { title, labels, barData, lineData1, lineData2 } = multiTypeChartData;
  const data = {
    labels,
    datasets: [
      {
        type: "bar",
        label: "Bar Dataset",
        data: barData,
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
      {
        type: "line",
        label: "Line Dataset 1",
        data: lineData1,
        borderColor: "rgba(255, 99, 132, 1)",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        pointRadius: 5,
        showLine: true, // Ensure line is drawn
      },
      {
        type: "line",
        label: "Line Dataset 2",
        data: lineData2,
        borderColor: "rgba(54, 162, 235, 1)",
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        pointRadius: 5,
        showLine: true, // Ensure line is drawn
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Make chart responsive while keeping its aspect ratio flexible
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          boxWidth: 20,
        }
      },
      datalabels: {
        display: false, // Disables the display of raw values inside the line
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context) {
            return `${context.raw}%`;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        // Adjust category percentage (space allocated to each group)
        categoryPercentage: 0.8, // You can adjust this value for desired spacing
      },
      y: {
        position: "left",
        beginAtZero: true,
        ticks: {
          stepSize: 20,
          callback: (value) => `${value}%`,
        },
        grid: {
          drawOnChartArea: false,
        },
      },
      yRight: {
        position: "right", // Position the second y-axis on the right side
        beginAtZero: true, // Start the scale from 0
        max: 60, // Maximum value for the right y-axis
        ticks: {
          stepSize: 20, // Step size is 20 (0, 20, 40, 60)
          callback: (value) => `${value}%`, // Show actual values (0, 20, 40, 60)
        },
        grid: {
          drawOnChartArea: false, // Disables grid lines on the right y-axis
        },
      },
    },
    elements: {
      bar: {
        borderWidth: 1,  // Controls the bar's border width
      },
    },
    // Controls the width of the bars themselves
    barPercentage: 0.6, // Adjust this value (0.6 means 60% width of the category space)
  };
  

  // Custom Plugin for Conditional Percentage Display
  const percentageLabelsPlugin = {
    id: "percentageLabels",
    afterDatasetsDraw: (chart) => {
      const { ctx } = chart;
      chart.data.labels.forEach((label, index) => {
        // Get values for bar and line datasets
        const barValue = barData[index];
        const dataset1Value = lineData1[index];
        const dataset2Value = lineData2[index];

        // Get points for the datasets
        const metaBar = chart.getDatasetMeta(0);
        const meta1 = chart.getDatasetMeta(1);
        const meta2 = chart.getDatasetMeta(2);

        // Ensure the points exist for the datasets
        if (metaBar.data[index]) {
          const barPoint = metaBar.data[index].getProps(["x", "y"]);

          // Draw the bar value above the bar
          ctx.font = "12px Arial";
          ctx.textAlign = "center";
          ctx.fillStyle = "gray";
          ctx.fillText(`${barValue}%`, barPoint.x, barPoint.y - 10);
        }

        // Draw percentage labels for line datasets
        if (meta1.data[index] && meta2.data[index]) {
          const point1 = meta1.data[index].getProps(["x", "y"]);
          const point2 = meta2.data[index].getProps(["x", "y"]);

          ctx.font = "12px Arial";
          ctx.textAlign = "center";

          // Adjust positioning to avoid overflow
          const offset = 15; // Vertical offset for labels
          const maxLabelY = chart.chartArea.bottom - 20; // Maximum Y position to avoid overflow

          // Adjust label positions to stay within bounds
          const label1Y = dataset1Value > dataset2Value ? point1.y - offset : point1.y + offset;
          const label2Y = dataset1Value > dataset2Value ? point2.y + offset : point2.y - offset;

          // Prevent overflow and ensure labels are visible
          const safeLabel1Y = Math.min(Math.max(label1Y, chart.chartArea.top + 20), maxLabelY);
          const safeLabel2Y = Math.min(Math.max(label2Y, chart.chartArea.top + 20), maxLabelY);

          ctx.fillStyle = "gray";
          ctx.fillText(`${dataset1Value}%`, point1.x, safeLabel1Y);
          ctx.fillText(`${dataset2Value}%`, point2.x, safeLabel2Y);
        }
      });
    },
  };

  return (
    <>
      <ChartContain>
      <ChartHeader>
        <h2 className="Title">{title}</h2>
        <ButtonContain>
                    <button className="ExportButton" style={{margin:'0'}} onClick={() => console.log("Exporting...")}><img src={ExcelIcon} alt="Excel icon" /></button>
        </ButtonContain>
      </ChartHeader>
      <div className="Chart">
        <Bar data={data} options={options} plugins={[percentageLabelsPlugin]} />
      </div>
      </ChartContain>
    </>
  );
};

export default MultiTypeChart;
