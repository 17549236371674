import React, { useState } from 'react'
import MultiTypeChart from '../../Components/charts/MultiTypeChart'
import StakedChart from '../../Components/charts/StakedChart';
import BarChart from '../../Components/charts/BarChart';
import { ButtonContain, Card, CardContain } from '../../style/Home';
import Filter from '../../Components/filters';
import Grid from '@mui/material/Grid2';
import ExcelIcon from '../../assets/images/icons/excel.png'
import { CommanTableContain } from '../../style/table';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Colors } from '../../style/theme';
import { ChartHeader } from '../../style/charts';


const StakedChartData = {
  title: "Parameters wise Band",
  labels: ["January", "February", "March", "April", "May"],
  group1: {
    dataset1: [10, 20, 30, 40, 50],
    dataset2: [40, 60, 30, 20, 10],
    dataset3: [30, 10, 15, 14, 27],
  },
  group2: {
    dataset1: [15, 25, 35, 45, 55],
    dataset2: [35, 55, 25, 15, 5],
    dataset3: [25, 15, 20, 18, 30],
  },
};
const BarChartData = {
  title: "Region wise Score",
  labels: ["January", "February", "March", "April", "May"],
  group1: {
    dataset1: [10, 20, 30, 40, 50],
  },
  group2: {
    dataset1: [15, 25, 35, 45, 55],
  },
};
const multiTypeChartData = { 
  title: "Source Wise Average Score",
   labels : ["Photo Based", "HO Based", "Evaluator"],
   barData : [10, 5, 7],
   lineData1 : [100, 45, 35],
   lineData2 : [40, 45, 90],
};
const parametersWiseDAta ={ 
  title: "Parameters Wise  Score",
   labels : ["First", "Second", "Third", "Fourth", "Five", "Six", "Seven", "Eight"],
   barData : [10, 5, 7,3,2,1,0,4],
   lineData1 : [100, 45, 35,22,33,4,11,34],
   lineData2 : [40, 45, 90,12,11,5,8,60],
};

const Analytics = () => {
  const [filterValues, setFilterValues] = useState({});
  const [value, setValue] = React.useState(0);


     // filter input start
  const [formData, setFormData] = useState({
    input1: '2024-2025', 
    input2: 'PMB',
    input3: 'Central', 
    input4: 'UP Central', 
    input5: '2024-2025', 
    input6: 'H2', 
  });
    // Handle change for all inputs
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setFormData(prevData => ({
        ...prevData,
        [name]: value, // Update the value of the input based on its name
      }));
    };
  // filter input end
  
  // filter card start
  const filtersData = [
    {
      xs: 6, sm: 4 , lg: 2,
      key:'region',
      label: 'Region',
      type: 'select',
      options: [
        { value: 'south', label: 'South' },
        { value: 'north', label: 'North' },
        { value: 'east', label: 'East' },
        { value: 'West', label: 'West' },
      ],
    },
    {
      xs: 6, sm: 4 , lg: 2,
      key:'zone',
      label: 'Zone',
      type: 'select',
      options: [
        { value: 'delhi-south', label: 'Delhi South' },
        { value: 'delhi-north', label: 'Delhi North' },
        { value: 'delhi-east', label: 'Delhi East' },
        { value: 'delhi-West', label: 'Delhi West' },
      ],
    },
    {
      xs: 6, sm: 4 , lg: 2,
      key:'area',
      label: 'Area',
      type: 'select',
      options: [
        { value: 'up-south', label: 'UP South' },
        { value: 'up-north', label: 'UP North' },
        { value: 'up-east', label: 'UP East' },
        { value: 'up-West', label: 'UP West' },
      ],
    },
    {
      xs: 6, sm: 4 , lg: 2,
      key:'amd-code-name',
      label: 'AMD Code Name',
      type: 'select',
      options: [
        { value: 'DL010001', label: 'DL010001' },
        { value: 'DL020002', label: 'DL020002' },
        { value: 'DL010003', label: 'DL010003' },
        { value: 'DL020004', label: 'DL020004' },
      ],
    },
    {
      xs: 6, sm: 4 , lg: 2,
      key:'year',
      label: 'Financial Year',
      type: 'select',
      options: [
        { value: '2024-2025', label: '2024-2025' },
        { value: '2023-2024', label: '2023-2024' },
        { value: '2022-2023', label: '2022-2023' },
      ],
    },
    {
      xs: 6, sm: 4 , lg: 2,
      key:'period',
      label: 'Period',
      type: 'select',
      options: [
        { value: 'H1', label: 'H1' },
        { value: 'H2', label: 'H2' },
      ],
    },
  ];


  const handleFilterChange = (filterKey, value) => {
    setFilterValues((prev) => ({
      ...prev, // Keep previous values
      [filterKey]: value, // Update the specific field
    }));
  };
  // filter card end
  // tabs start
  const handleTabsChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabData = [
    "Data for Commercial Aspects",
    "Data for Process Management",
    "Data for Infrastructure",
    "Data for Dealer Facility",
    "Data for Customer Delight",
    "Data for Effort Management",
    "Data for Manpower",
    "Data for Management Information System",
    "Data for Management Admin",
  ];
  // tabs end
// table data start
const chartTableHeader = ['Rank','Region','Zone','City','Dealer Code', 'Trade name','N/A','DE24-25V5'];
const chartTable = ['1','Central','MP East','Jabalpur','MP030001', 'Sahil Automobiles','N/A','73%'];
// table data end
  return (
    <>
    {/* chart start */}
        <CardContain>
          <Card mt={1} className='Filter_bg'>
            <Grid container spacing={1}>
            <Filter filters={filtersData} onFilterChange={handleFilterChange}></Filter>
              <Grid item size={12}>
              <ButtonContain sx={{height:'100%', alignItems:'end', gap:'5px'}}>
                    <button className="SearchButton">Search</button>
                    <button className="ResetButton">Reset</button>
                    {/* <button className="ExportButton"><img src={ExcelIcon} alt="Excel icon" /></button> */}
              </ButtonContain>
              </Grid>
            </Grid>
          </Card>
          </CardContain>
      <MultiTypeChart multiTypeChartData={multiTypeChartData}/>
      <MultiTypeChart multiTypeChartData={parametersWiseDAta}/>
      <StakedChart  StakedChartData={StakedChartData} />
      {/* tabs start */}
      <Box>
      <Tabs
        value={value}
        onChange={handleTabsChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="scrollable force tabs example"
        sx={{ bgcolor: '#708090' ,color: '#fff',boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
          '& .Mui-selected.MuiButtonBase-root':{
            color: '#fff'
          },
    '& .MuiTabs-indicator': {
      backgroundColor: Colors.primary, // Indicator color for the selected tab
    },}}
      >
        <Tab label="Commercial Aspects" sx={{color: '#fff' }}/>
        <Tab label="Process Management" sx={{color: '#fff' }}/>
        <Tab label="Infrastructure" sx={{color: '#fff' }}/>
        <Tab label="Dealer Facility" sx={{color: '#fff' }}/>
        <Tab label="Customer Delight" sx={{color: '#fff' }}/>
        <Tab label="Effort Management" sx={{color: '#fff' }}/>
        <Tab label="Manpower" sx={{color: '#fff' }}/>
        <Tab label="Management Information System" sx={{color: '#fff' }}/>
        <Tab label="Management Admin" sx={{color: '#fff' }}/>
      </Tabs>
         {/* Render Tab Content */}
         <Box >
         {value === 0 && <>
          <Grid container spacing={2}>
          <Grid item size={{xs:12,md:6}}><BarChart chartData={BarChartData} /></Grid>
          <Grid item size={{xs:12,md:6}}><MultiTypeChart multiTypeChartData={multiTypeChartData}/></Grid>
          <Grid item size={{xs:12,md:6}}><MultiTypeChart multiTypeChartData={multiTypeChartData}/></Grid>
          <Grid item size={{xs:12,md:6}}><MultiTypeChart multiTypeChartData={multiTypeChartData}/></Grid>
         </Grid>
         </> }
        
        {/* <Typography>{tabData[value]}</Typography> */}
      </Box>
    </Box>
      {/* <BarChart chartData={BarChartData} /> */}
      {/* tabs end */}
      {/* chat end  */}
      {/* table satrt */}
      
      <ChartHeader sx={{mb:0,mt:3}}>
        <h2 className="Title">Top 10 Ranked Dealers</h2>
        <ButtonContain>
                    <button className="ExportButton" style={{margin:'0'}} onClick={() => console.log("Exporting...")}><img src={ExcelIcon} alt="Excel icon" /></button>
        </ButtonContain>
      </ChartHeader>
      <CommanTableContain>
        <Table>
          <TableHead>
            <TableRow>
              {chartTableHeader.map((data,index)=>(
              <TableCell key={index}>{data}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {chartTable.map((data,index)=>(
                <TableCell key={index}>{data}</TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </CommanTableContain>
      {/* table end */}
    </>
  )
}

export default Analytics