import { apiCall } from "./authServices";

export const GetAllHo = async () => {
    try {
        const endpoint = `api/manual/mvr/get-all-ho`
        const res = await apiCall({
            endpoint: endpoint,
            method: "get",
        });
        return res
    } catch (error) {
        throw error;
    }
}


export const SendForApproval = async ({mvrId, payload= {}}) => {
    try {
        if(payload && mvrId){
            const approvalPayload = formatApprovalPayload(payload)
            const url = `api/manual/mvr/send-for-approval/${mvrId}`
            const res = await apiCall({
                endpoint: url,
                payload: approvalPayload,
                method: "POST",
            });
            return res
        }
    } catch (error) {
        throw error;
    }
}
export const GetApprovalHistory = async ({mvrId}) => {
    try {
        if(mvrId){
            const url = `api/manual/mvr/approvals-and-history/${mvrId}`
            const res = await apiCall({
                endpoint: url,
                method: "GET",
            });
            return res
        }
    } catch (error) {
        throw error;
    }
}
export const UpdateApproveHistory = async ({mvrId, approvalId, payload}) => {
    console.log('payload: ', payload);
    try {
        if(mvrId && approvalId && payload){
            const url = `api/manual/mvr/approval-action/${mvrId}/${approvalId}`
            const res = await apiCall({
                endpoint: url,
                payload: payload,
                method: "PATCH",
            });
            return res
        }
    } catch (error) {
        throw error;
    }
}


function formatApprovalPayload(payload){
    if(payload){
        payload = JSON.parse(JSON.stringify(payload))
        const formattedPayload = payload.map((el, index)=>{
            el.sendToId = el.value,
            el.sendToName = el.display,
            el.sendToRole = el.role,
            el.sequence = index+1
            delete el.value
            delete el.display
            delete el.role
            return el
        })
        const newPayload = {
            approvalList: formattedPayload
        }
        return newPayload
    }
}