import { Box } from "@mui/material";
import { styled } from "@mui/system";
import { Colors } from "../theme";

export const FilterField = styled(Box)(()=>({
    '& input,& select':{
    fontSize: '11px',
    padding: '0 5px',
    borderRadius: '5px',
    border: '1px solid gray',
    width: '100%',
    outline: 'none',
    height: '28px',
    backgroundColor: '#fff',
    color:Colors.black,
    '&:disabled,&:read-only':{
        backgroundColor:Colors.disabled
    },
    },
    '& label':{
        display:'block',
        fontSize:'12px',
        marginBottom:'5px',
        fontWeight:500,
    },
    '& div.react-datepicker-wrapper':{
        width:'100%',
    }

}))