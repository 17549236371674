import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels"; // Import the plugin
import { ChartContain, ChartHeader } from "../../style/charts";
import ExcelIcon from '../../assets/images/icons/excel.png'
import { ButtonContain } from "../../style/Home";

// Register Chart.js components and plugins
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const BarChart = ({ chartData }) => {
  const { title, labels, group1, group2 } = chartData;

  // Define colors for datasets
  const colors = ["rgba(75, 192, 192, 0.6)", "rgba(255, 99, 132, 0.6)"];

  // Prepare datasets
  const datasets = [
    {
      label: "Group 1",
      data: group1.dataset1,
      backgroundColor: colors[0],
      borderColor: colors[0],
      borderWidth: 1,
      // barThickness: 0,
    },
    {
      label: "Group 2",
      data: group2.dataset1,
      backgroundColor: colors[1],
      borderColor: colors[1],
      borderWidth: 1,
      // barThickness: 20,
    },
  ];

  // Chart data
  const data = {
    labels: labels,
    datasets: datasets,
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context) {
            return `${context.dataset.label}: ${context.raw}%`; // Show value with %
          },
        },
      },
      legend: {
        position: "bottom",
        labels: {
          boxWidth: 20,
        },
      },
      datalabels: {
        display: true, // Enable datalabels
        color: "gray",
        formatter: (value) => `${value}%`, // Format the value with %
        anchor: "end",
        align: "top", // Adjust alignment to inside the bar
        font: {
        //   weight: "bold",
          size: 12,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        categoryPercentage: 0.8,
        barPercentage: 0.7,
        
      },
      y: {
        beginAtZero: true,
        max: 100,
        ticks: {
          stepSize: 10,
          callback: (value) => `${value}%`, // Append % to y-axis ticks
        },
        grid: {
          drawOnChartArea: false, // Disables grid lines on the right y-axis
        },
      },
    },
  };

  return (
    <ChartContain>
    <ChartHeader>
      <h2 className="Title">{title}</h2>
      <ButtonContain>
                    <button className="ExportButton" style={{margin:'0'}}  onClick={() => console.log("Exporting...")}><img src={ExcelIcon} alt="Excel icon" /></button>
              </ButtonContain>
    </ChartHeader>
    <div className="Chart">
      <Bar data={data} options={options} />
    </div>
  </ChartContain>
  );
};

export default BarChart;
