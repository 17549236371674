import React, { useState, useEffect } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import Grid from '@mui/material/Grid2';
import DoughnutChart from './charts/Chart';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { ButtonContain, Card, CardContain, CardCustomList, CardFooter, CardTitle, CustomButtonContain, FieldContain, TableContain, TabsContain } from '../../style/Home';
import { Colors } from '../../style/theme';

const MyTabs = () => {
  const [value, setValue] = React.useState('MVRTabs');
  const [isSticky, setIsSticky] = useState(false); // To track scroll position

  const MVRchart = {
    labels: ['Total', 'Submitted', 'Accepted', 'Reconsideration', 'Pending'],
    datasets: [
      {
        data: [6, 2, 1, 1, 2],
        backgroundColor: [Colors.total, Colors.submitted, Colors.accepted, Colors.reconsidertion, Colors.pending], 
        borderColor: [Colors.total, Colors.submitted, Colors.accepted, Colors.reconsidertion, Colors.pending],
        borderWidth: 1,
      },
    ],
  };
  const QVRchart = {
    labels: ['Total AMD', 'Eligible AMD', 'Visit Plan', 'Evaluation By AM', 'Reconsideration Request','Accepted By AMD','Avrage Score'],
    datasets: [
      {
        data: [6, 1, 1, 1, 1,1,1 ],
        backgroundColor: [Colors.total, Colors.submitted,Colors.pending,Colors.total,  Colors.reconsidertion, Colors.accepted,Colors.pending], 
        borderColor: [Colors.total, Colors.submitted,Colors.pending,Colors.total,  Colors.reconsidertion, Colors.accepted,Colors.pending],
        borderWidth: 1,
      },
    ],
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [formData, setFormData] = useState({
    input1: '2024-2025', 
    input2: 'PMB',
    input3: 'Central', 
    input4: 'UP Central', 
    input5: '2024-2025', 
    input6: 'H2', 
  });

  // Handle change for all inputs
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value, // Update the value of the input based on its name
    }));
  };

  useEffect(() => {
    console.log("-----Separated Route-----1-->", process.env);

    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true); // Add 'sticky' class when scroll > 56px
      } else {
        setIsSticky(false); // Remove 'sticky' class
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <TabsContain>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="tabs example"
        className={`HomeTabs ${isSticky ? "StickyShadow" : ""}`}
      >
        <Tab label="MVR" value="MVRTabs" />
        <Tab label="QVR" value="QVRTabs" />
      </Tabs>

      {value === "MVRTabs" && (
        <>
          {/* <CardContain>
            <Card mt={1}>
              <Grid container spacing={2}>
                <Grid item size={6}>
                  <FieldContain>
                    <input
                      type="text"
                      name="input1" 
                      value={formData.input1}
                      onChange={handleInputChange}
                    />
                  </FieldContain>
                </Grid>
                <Grid item size={6}>
                  <FieldContain>
                    <select name="month" id="month">
                      <option value="November">November</option>
                      <option value="October">October</option>
                    </select>
                  </FieldContain>
                </Grid>
                <Grid item size={12}>
                  <FieldContain>
                    <select name="month" id="month">
                      <option value="">Select AMD</option>
                      <option value="nikhil">Nikhil Kumar</option>
                    </select>
                  </FieldContain>
                </Grid>
                <Grid item size={12}>
                  <ButtonContain>
                    <button className="SearchButton">Search</button>
                    <button className="ResetButton">Reset</button>
                  </ButtonContain>
                </Grid>
              </Grid>
            </Card>
          </CardContain>

          <CardContain mt={2}>
            <CardTitle variant='h4'>MVR Status</CardTitle>
            <Card>
              <Grid container spacing={2}>
                <Grid item size={{ xs: 6 }}>
                  <CardCustomList>
                      <li>
                        Total AMD :
                        <span className="StatusCount total">6</span>
                      </li>
                      <li>
                        Submitted By AM :
                        <span className="StatusCount submitted">2</span>
                      </li>
                      <li>
                        Accepted By AMD :
                        <span className="StatusCount accepted">1</span>
                      </li>
                      <li>
                        Reconsideration By AMD :
                        <span className="StatusCount reconsidertion">1</span>
                      </li>
                      <li>
                        Pending By AM :
                        <span className="StatusCount pending">2</span>
                      </li>
                      </CardCustomList>
                </Grid>
                <Grid item size={{ xs: 6 }}>
                  <DoughnutChart data={MVRchart}/>
                </Grid>
              </Grid>
            </Card>
            <CardFooter>
              <div><p> Total MVR Submitted : <span className="MVRCount">1</span></p></div>

              <CustomButtonContain>
                <button>AMD Wise Status <KeyboardDoubleArrowRightIcon /></button>
              </CustomButtonContain>
            </CardFooter>
          </CardContain>

          <CardContain mt={2}>
            <TableContain>
              <table className='table'>
                <thead>
                  <tr className='TableTitle'>
                    <th colSpan={7}><h4 >MVR-MoM Status</h4></th>
                  </tr>
                  <tr>
                    <th>Total MoM Points</th>
                    <th>Open Mom Points</th>
                    <th>MoM Points Completed By Dealers</th>
                    <th>MoM Points Completed By AM</th>
                    <th>Escalated MoM Points at AM(Cum.)</th>
                    <th>Escalated MoM Points at ZM(Cum.)</th>
                    <th>Escalated MoM Points at RM(Cum.)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>6</td>
                    <td>6</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                </tbody>
              </table>
            </TableContain>
            <CardFooter>
              <div><p> Carry forward open points : <span className="MVRCount">0</span></p></div>

              <CustomButtonContain>
                <button>MVR MoM Status <KeyboardDoubleArrowRightIcon /></button>
              </CustomButtonContain>
            </CardFooter>
          </CardContain> */}
        </>
      )}

      {value === "QVRTabs" && (
        <>
        {/* <CardContain>
          <Card mt={1}>
            <Grid container spacing={2}>
              <Grid item size={6}>
                <FieldContain>
                  <input type="text" 
                      name="input2" 
                      value={formData.input2}
                      onChange={handleInputChange}
                      disabled
                      />
                </FieldContain>
              </Grid>
              <Grid item size={6}>
                <FieldContain>
                  <input type="text" 
                      name="input3" 
                      value={formData.input3}
                      onChange={handleInputChange}
                      disabled
                      />
                </FieldContain>
              </Grid>
              <Grid item size={6}>
                <FieldContain>
                  <input type="text" 
                      name="input4" 
                      value={formData.input4}
                      onChange={handleInputChange}
                      disabled
                      />
                </FieldContain>
              </Grid>
              <Grid item size={6}>
                <FieldContain>
                    <select name="month" id="month">
                      <option value="">Select AMD</option>
                      <option value="nikhil">Nikhil Kumar</option>
                    </select>
                </FieldContain>
              </Grid>
              <Grid item size={6}>
                <FieldContain>
                  <input type="text" 
                      name="input5" 
                      value={formData.input5}
                      onChange={handleInputChange}/>
                </FieldContain>
              </Grid>
              <Grid item size={6}>
                <FieldContain>
                  <input type="text" 
                      name="input6" 
                      value={formData.input6}
                      onChange={handleInputChange}/>
                </FieldContain>
              </Grid>
              <Grid item size={12}>
              <ButtonContain>
                    <button className="SearchButton">Search</button>
                    <button className="ResetButton">Reset</button>
                  </ButtonContain>
              </Grid>
            </Grid>
          </Card>
        </CardContain>
        <CardContain mt={2}>
          <CardTitle variant='h4'>QVR Status</CardTitle>
          <Card>
            <Grid container spacing={2}>
              <Grid item size={6}>
              <CardCustomList>
                  <li>Total AMD :
                        <span className="StatusCount total">6</span>
                  </li>
                  <li>Eligible AMD :
                        <span className="StatusCount submitted">1</span>
                  </li>
                  <li>Visit Plan :
                        <span className="StatusCount pending">1</span>
                  </li>
                  <li>Evaluation by AM :
                        <span className="StatusCount total">1</span>
                  </li>
                  <li>Reconsideration Request :
                        <span className="StatusCount reconsidertion">1</span>
                  </li>
                  <li>Accepted by AMD :
                        <span className="StatusCount accepted">1</span>
                  </li>
                  <li>Avrage Score :
                        <span className="StatusCount pending">1</span>
                  </li>
                  </CardCustomList>
              </Grid>
              <Grid item size={6}>
                <DoughnutChart data={QVRchart}/>
              </Grid>
            </Grid>
          </Card>
            <CardFooter>
              <div><p> Scores of AMDs who have accepted the evaluaton</p></div>

              <CustomButtonContain>
                <button>AMD Evaluation Status <KeyboardDoubleArrowRightIcon /></button>
              </CustomButtonContain>
            </CardFooter>
        </CardContain>
        <CardContain mt={2}>
            <TableContain>
              <table className='table'>
                <thead>
                  <tr className='TableTitle'>
                    <th colSpan={7}><h4 >MoM Status</h4></th>
                  </tr>
                  <tr>
                    <th>Total Points</th>
                    <th>Open Points</th>
                    <th>Completed By AMD</th>
                    <th>Completed By AM</th>
                    <th>Escalated To AM</th>
                    <th>Escalated To ZM</th>
                    <th>Escalated To RM</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>6</td>
                    <td>6</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                </tbody>
              </table>
            </TableContain>
            <CardFooter>
              <div><p style={{visibility:'hidden'}}> Carry <span className="MVRCount"></span></p></div>

              <CustomButtonContain>
                <button>QVR MoM Status <KeyboardDoubleArrowRightIcon /></button>
              </CustomButtonContain>
            </CardFooter>
          </CardContain> */}
        </>
       ) }
    </TabsContain>
  );
};

export default MyTabs;
