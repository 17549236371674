import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Tooltip,
  TextField,
  Dialog, DialogTitle, DialogContent, DialogActions,IconButton, Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandIcon from "@mui/icons-material/KeyboardArrowDown";
import CollapseIcon from "@mui/icons-material/KeyboardArrowUp";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Grid from "@mui/material/Grid2";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { DatePickerContain, FormAccordianContain, FormModal, InvisibleLabel, UploadContain } from "../../style/formAccordian";
import { SmartSearchContain } from "../../style/table";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import SamlpeImage from '../../assets/images/logo.png';
import evaluateImage from '../../assets/images/icons/employes.png';
import { ModalContain } from "../../style/HO/MVRManualCreation";
import { useSelector } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const DynamicAccordion = () => {
  const Drawer = useSelector((state) => state.drawer);
  const [expanded, setExpanded] = useState([]); // Tracks expanded accordion IDs
  const [dateValue, setDateValue] = useState();
  
  const [modalOpen, setOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const accordianData = [
    { id: 1, title: "Accordion 1" },
    { id: 2, title: "Accordion 2" },
    { id: 3, title: "Accordion 3" },
  ];

  const allIds = accordianData.map((data) => data.id);

  // Toggle individual accordion
  const handleAccordionChange = (id) => {
    setExpanded((prev) =>
      prev.includes(id) ? prev.filter((data) => data !== id) : [...prev, id]
    );
  };

  // Expand or collapse all accordions
  const toggleAll = (action) => {
    if (action === "expand") {
      setExpanded(allIds); // Expand all
    } else {
      setExpanded([]); // Collapse all
    }
  };


// upload image modal start
  const handleOpenModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
    setOpenModal(false);
  };
  // upload image modal end
  // Get current date and time
  const getCurrentDateTime = () => {
    const now = new Date();
    return now.toLocaleString(); // Formats as "MM/DD/YYYY, HH:MM:SS AM/PM"
  };

  return (
    <FormAccordianContain>
      <div className="ExpandButtonContain">
        <SmartSearchContain>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            fullWidth
            margin="dense"
          />
          <SearchIcon />
        </SmartSearchContain>
        <div style={{ display: "flex", gap: "10px" }}>
          <Tooltip title="Expand All" arrow>
            <span>
            <Button
              variant="contained"
              onClick={() => toggleAll("expand")}
              disabled={expanded.length === allIds.length}
              style={{
                backgroundColor: expanded.length === allIds.length ? "#ccc" : "#4caf50",
                color: "#fff",
                minWidth: "40px",
              }}
            >
              <ExpandIcon />
            </Button>
            </span>
          </Tooltip>
          <Tooltip title="Collapse All" arrow>
            <span>
            <Button
              variant="contained"
              onClick={() => toggleAll("collapse")}
              disabled={expanded.length === 0}
              style={{
                backgroundColor: expanded.length === 0 ? "#ccc" : "#f44336",
                color: "#fff",
                minWidth: "40px",
              }}
            >
              <CollapseIcon />
            </Button>
            </span>
          </Tooltip>
        </div>
      </div>

      {accordianData.map((data) => (
        <Accordion
          key={data.id}
          expanded={expanded.includes(data.id)}
          onChange={() => handleAccordionChange(data.id)}
          className="borderL"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className="FormAccordianSummary borderB"
          >
            <span className="CheckIcon">
              <CheckCircleIcon />
            </span>
            <span className="numbering">{data.id}.</span>
            <span className="AccrdianTitle">{data.title}</span>
            <span className="ResivedScore">_/6</span>
          </AccordionSummary>
          <AccordionDetails className="FormAccordianContent">
            <Grid container rowSpacing={1} columnSpacing={1}>
              <Grid item size={{xs:12,sm:4,md:3,lg:3,xl:2}}>
              <div className="FieldContain" style={{height:'100%'}}>
              <label htmlFor='upload'>Sample / Evaluator Uploads :</label>
                <UploadContain>
                <div className="UploadbtnContain">
        <Tooltip title="Sample Image" arrow>
          <button
            className="UploadImg"
            onClick={() => handleOpenModal(SamlpeImage)}
          >
            <img src={SamlpeImage} alt="sample image" />
          </button>
        </Tooltip>
      </div>
      <div className="UploadDivider"></div>
      {/* <div className="EvaluaterUploadContain"> */}
        <div className="UploadbtnContain">
          <Tooltip title="Evaluator Image" arrow>
            <button
              className="UploadImg"
              onClick={() => handleOpenModal(SamlpeImage)}
            >
              <img src={evaluateImage} alt="sample image" />
            </button>
          </Tooltip>
        </div>
        <div className="UploadbtnContain">
          <Tooltip title="Evaluator Upload" arrow>
            <button className="UploadImg">
              <CameraAltIcon />
            </button>
          </Tooltip>
        </div>
      {/* </div> */}
      </UploadContain>
{modalOpen && (<ModalContain className={Drawer ? " " : "DrawerOpen"}
        open={modalOpen}>
          <FormModal>
                  <div className="ModalHeader">
                    <h4>Accordian Title</h4>
                    <button
                      className="CloseButton"
                      onClick={handleCloseModal}
                    >
                      <CloseIcon />
                    </button>
                  </div>
                  <div className="ModalBody">
                  <img
            src={SamlpeImage}
            alt="Expanded View"
          /></div>
                  <div className="ModalFooter">
                  <Typography variant="body2">{getCurrentDateTime()}</Typography>
                  </div>
                  </FormModal>

      </ModalContain>)}
      
                </div>
              </Grid>
              <Grid item size={{xs:12,sm:8,md:5,lg:5 , xl: 5}}>
                {/* <div className="DataContain"> */}
                  <div className="FieldContain">
                    <label htmlFor={`idealRequirement-${data.id}`}>Ideal Requirement :</label>
                    <input
                      type="text"
                      value="Ideal Requirement"
                      readOnly
                      disabled
                    />
                  </div>
                  
              </Grid>
                  <Grid item size={{xs:12,sm:6,md:2,lg:2,xl:3}}>
                  <div className="FieldContain">
                    <label htmlFor={`scoringcriteria-${data.id}`}>Scoring Criteria :</label>
                    <textarea name={`scoringcriteria-${data.id}`} defaultValue="Scoring Criteria" />
                  </div>
                  </Grid>
                  <Grid item size={{xs:12,sm:6,md:2,lg: 2, xl:2 }}>
                  <div className="FieldContain">
                    <label htmlFor={`score-${data.id}`}>Score :</label>
                    <select name={`score-${data.id}`}>
                      <option value="select">Select</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                  </Grid>
              <Grid item size={{xs:6,md:3,lg:2,xl:2}}>
                  <div className="FieldContain">
                    <label htmlFor={`target-${data.id}`}>Plan / Target :</label>
                    <input type="text" value="" readOnly disabled />
                  </div>
              </Grid>
              <Grid item size={{xs:6,md:3,lg:2,xl:2}}>
                  <div className="FieldContain">
                    <label htmlFor={`actual-${data.id}`}>Actual :</label>
                    <input type="text" value="" readOnly disabled />
                  </div>
                  </Grid>
                  <Grid item size={{xs:12,md:6,lg:8,xl:8}}>
                  <div className="FieldContain">
                    <label htmlFor={`remarks-${data.id}`}>Remarks :</label>
                    <input
                      type="text"
                      placeholder="Enter Your Remarks"
                    />
                  </div>
                  </Grid>
              <Grid item size={{xs:6,md:3,lg:2,xl:2}}>
                {/* <div className="DataContain"> */}
                <InvisibleLabel>Include Mom</InvisibleLabel>
                <div className="FieldContain Checkbox">
                     <input type="checkbox" id={`include-mom-${data.id}`} />
                     <label htmlFor={`include-mom-${data.id}`}>Include in MoM</label>
                </div>
                  </Grid>
                  <Grid item size={{xs:6,md:3,lg:2,xl:2}}>
                  <div className="FieldContain">
                    <label htmlFor={`target-date-${data.id}`}>Target Date :</label>
                    {/* <input type="date" /> */}
                    <DatePickerContain className="DatePickerContain">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label="DD-MM-YYYY"
                              value={dateValue || null}
                              className="dateTimePicker"
                              onChange={(newValue) => setDateValue(newValue)}
                              format="DD-MM-YYYY"
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  value={
                                    dateValue
                                      ? dateValue.format("DD-MM-YYYY")
                                      : ""
                                  }
                                  placeholder="dd-MM-yyyy"
                                />
                              )}
                            />
                        </LocalizationProvider>
                        </DatePickerContain>
                  </div>
                  </Grid>
                  <Grid item size={{xs:12,md:6,lg:8,xl:8}}>
                  <div className="FieldContain ">
                    <label htmlFor={`countermeasure-${data.id}`}>Countermeasure :</label>
                    <input
                      type="text"
                      placeholder="Enter Countermeasure"
                    />
                  </div>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </FormAccordianContain>
  );
};

export default DynamicAccordion;
