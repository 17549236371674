import styled from "@emotion/styled";
import { Box, Button } from "@mui/material";
import { Colors } from "../theme";


export const ModalContain = styled(Box)(()=>({
    backgroundColor:'#00000057',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    padding:'10px',
    position:'fixed',
    right:'0',
    top:'0',
    bottom:'0',
    width:'100%',
    height:'100vh',
    zIndex:1011,
    transition:'225ms', 
    '@media (min-width:899px)':{
        '&.DrawerOpen':{
          width: 'calc(100% - 260px)',
        },
    },

}))

export const CreateManualModal = styled(Box)(()=>({
    width:'100%',
    maxWidth:'400px',
    boxShadow:'rgba(60, 64, 67, 0.3) 0px 2px 4px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 1px',
    backgroundColor:'#fff',
    '& .ModalHeader': {
    padding: '10px',
    position:'relative',
    backgroundColor:Colors.primary,
    color:Colors.white,
    '& h4':{
        fontSize:'14px',
        fontWeight:500,
    },
    '& .CloseModal':{
        position:'absolute',
        right:'3px',
        top:'3px',
        backgroundColor:'transparent',
        border:'none',
        outline:'none',
        '& svg':{
            color:Colors.white,
        }
    }
  },
    '& .ModalCard':{
        padding:'10px',
        paddingTop:'20px',
        '& .ModalField':{
            
        }
    },
    '& .ModalFooter':{
        display:'flex',
        justifyContent:'end',
        alignItems:'center',
        gap:'5px',
        padding:'10px',
        paddingBottom:'20px',
        paddingTop:'0',
    }
}))

export const ContainedButtton = styled(Button)(()=>({
    // backgroundColor:Colors.skyblue
}))
export const CloseButton = styled(Button)(()=>({
    backgroundColor:Colors.delete,
}))

export const SearchContain = styled(Box)(()=>({
    position:'relative',
    width:'fit-content',
    '& input':{
        fontSize: '11px',
        padding: '0 5px',
        paddingRight:'25px',
        borderRadius: '5px',
        border: '1px solid gray',
        width: '150px',
        outline: 'none',
        height: '28px',
        backgroundColor: '#fff',
        color:Colors.black,
    },
    '& svg':{
        position:'absolute',
        right:'3px',
        top:'3px',
        color:Colors.light_gray,
    },
}))
export const DflexContain = styled(Box)(()=>({
    display:'flex',
    alignItems:'end',
    justifyContent:'space-between',
    gap:'10px',
}))
