import React from 'react'
import MyTabs from '../../Components/HO/Tabs';

function HoHome() {
  return (
       <MyTabs/>
  )
}

export default HoHome

