import React , { useRef }from 'react'
import {  ButtonContain, Card, CardContain } from '../../style/Home'
import Filter from '../../Components/filters'
import Grid from '@mui/material/Grid2';
import Timeline from '../../Components/timeline';
import Table from '../../Components/table/Table';
import ExcelIcon from '../../assets/images/icons/excel.png'
import { GuidelineContain } from '../../style/AM/MoMStatusUpdate';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
const MoMStatusUpdate = () => {

  const filtersData = [
    {
      xs: 6, sm: 4 , lg: 2,
      key:'dealer-code',
      label: 'Dealer Code',
      type: 'input',
      disable: true,
      value:'AP010001',
    },
    {
      xs: 6, sm: 4 , lg: 2,
      key:'dealership-name',
      label: 'Dealership Name',
      type: 'input',
      disable: true,
      value:'Fortune Motors PVT  LTD'
    },
    {
      xs: 6, sm: 4 , lg: 2,
      key:'city',
      label: 'City',
      type: 'input',
      disable: true,
      value:'Noida'
    },
    {
      xs: 6, sm: 4 , lg: 2,
      key:'dvr-submitted-date',
      label: 'DVR Submitted Date',
      type: 'input',
      disable: true,
      value:' ',
    },
    {
      xs: 6, sm: 4 , lg: 2,
      key:'holder-name',
      label: 'LOI Holder Name',
      type: 'input',
      disable: true,
      value:'MR. Nikhil Kumar',
    },
    {
      xs: 6, sm: 4 , lg: 2,
      key:'meet-with',
      label: 'Meet With',
      type: 'input',
      disable: true,
      value:' ',
    },
  ];


  const handleFilterChange = (filterKey, value) => {
    setFilterValues((prev) => ({
      ...prev, // Keep previous values
      [filterKey]: value, // Update the specific field
    }));
  };


  // table data start
    // table data start
    const headers = [
      { id: 'concern_points', label: 'Discussion/Concern Points',group: "MOM Points"},
      { id: 'concern_points_source', label: 'Discussion/Concern Points Source',group: "MOM Points" },
      { id: 'open_since_month', label: 'Open Since Month (Days)' ,group: "MOM Points"},
      { id: 'countermeasure_plan', label: 'Countermeasure Plan',group: "MOM Points" },
      { id: 'target_date', label: 'Target Date',group: "MOM Points"},
      { id: 'acceptance_status', label: 'Acceptance Status' ,group: "MOM Points"},
      { id: 'remarks_by_dealer', label: 'Acceptance/Reconsideration Remarks By Dealer' ,group: "MOM Points"},
      { id: 'am_remarks', label: 'AM Remarks',group: "MOM Points" },
      { id: 'escalation_level', label: 'Escalation Level',group: "MOM Points" },
      { id: 'escalated_to_rm', label: 'Escalated To RM',group: "MOM Points" },
      { id: 'revised_target_date', label: 'Revised Target Date (Entry By AM)',group: "MOM Implementation Status" },
      { id: 'reason_for_revised_target_date', label: 'Reason For Revised Target Date (Entry By AM)',group: "MOM Implementation Status" },
      { id: 'activity_completed_remarks', label: 'Activity Completed Remarks (Entry By AMD)',group: "MOM Implementation Status" },
      { id: 'amd_evidence', label: 'AMD Evidence (Photo Upload)',group: "MOM Implementation Status" },
      { id: 'activity_completed_date', label: 'Activity Completed Date ( By AMD)',group: "MOM Implementation Status" },
      { id: 'mom_status', label: 'Mom Status (Entry By AM)',group: "MOM Implementation Status" },
      { id: 'reopen_remarks', label: 'Reopen Remarks',group: "MOM Implementation Status" },
      { id: 'activity_closure_date', label: 'Activity Closure Date (Display After closed  By AM)',group: "MOM Implementation Status" },
      { id: 'closure_remarks', label: 'Closure Remarks',group: "MOM Implementation Status" },
    ];
  
    const data = [
      { concern_points: '-', concern_points_source:'-' , open_since_month: '-', countermeasure_plan:'-',target_date:'-',acceptance_status:'-',remarks_by_dealer:'-',am_remarks:'-',escalation_level:'-',escalated_to_rm:'-',
        revised_target_date:'-',reason_for_revised_target_date:'-',activity_completed_remarks:'-',amd_evidence:'-',activity_completed_date:'-',mom_status:'-',reopen_remarks:'-',activity_closure_date:'-',closure_remarks:'-',
      },
    ];
  
    // table data end

    const tableRef = useRef();

    const handleScrollRight = () => {
      if (tableRef.current) {
        tableRef.current.scrollBy({
          // left: 200, // Adjust the scroll amount
          left: tableRef.current.scrollWidth - tableRef.current.clientWidth,
          behavior: 'smooth',
        });
      }
    };

  // table data end


  return (
    <>   
    <CardContain className='Sticky'>
    <Card mt={1} className='Filter_bg StickyChild'>
      <Grid container spacing={1}>
      <Filter filters={filtersData} onFilterChange={handleFilterChange}></Filter>
      </Grid>
      <Grid container pt={2}>
      <Grid item size={{xs:10 , sm:10}}>
        <Timeline />
        </Grid>
      <Grid item size={{xs:2 , sm:2}}>
              <ButtonContain sx={{height:'100%', alignItems:'end', gap:'5px'}}>
                    <button className="ExportButton" style={{marginTop:'0'}}><img src={ExcelIcon} alt="Excel icon" /></button>
              </ButtonContain>
              </Grid>
      </Grid>

    </Card>
    </CardContain>
          <CardContain>
          <Card mt={2}>
            <GuidelineContain>
              <div className="ScrollTable">
                <p>Please scroll right to  update MoM Implementation Status</p>
                <button className="ScrollButton" onClick={handleScrollRight}><ArrowForwardIcon/> </button>
              </div>
              <div className="Guidelines">
                <p><span>Guidelines : </span> All the fields in MoM form are auto save.</p>
              </div>
            </GuidelineContain>
          <Table headers={headers} data={data} defaultRowsPerPage={10} ref={tableRef} />
          </Card>
          </CardContain>
    </>
  )
}

export default MoMStatusUpdate