import React from 'react'
import { TimeLineContain } from '../../style/timeline'

const Timeline = () => {
  return (
    <TimeLineContain>
      <div className="TimeLine">
        <h4 className='TimeLineTitle'>Evaluation Timeline :</h4>
        <span className="TimelineStart">23-11-2024</span>
        <span>to</span>
        <span className="TimelineEnd">30-11-2024</span>
      </div>
    </TimeLineContain>
  )
}

export default Timeline