import React, { useState } from "react";
import { useMatches } from "../../style/theme";
import {
  CreationButton,
  CreationCard,
  CreationCardMobile,
  CreationContain,
  ModalContain,
  SelectContain,
} from "../../style/HO/MVRManualCreation";
import Grid from "@mui/material/Grid2";
import AddIcon from "@mui/icons-material/Add";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Checkbox, ListItemText } from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";

import DeleteIcon from "@mui/icons-material/Delete";

const QVRManualCreation = () => {
  const {customMatches} = useMatches();

  // State to track added Parameter, Subparameter, and Checkpoint
  const [parameters, setParameters] = useState([]);
  const [expanded, setExpanded] = useState({});
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [itemValues, setItemValues] = useState({}); // { [checkpointId]: { [itemId]: value } }
  const [pvalue, setPvalue] = useState("hello");
  const [modalState, setModalState] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const [fields, setFields] = useState([]);

  const options = [
    {
      value: "idealRequirement",
      label: "Ideal Requirement",
      letter: "IR",
      class: "irColor",
    },
    {
      value: "scoringCriteria",
      label: "Scoring Criteria",
      letter: "SR",
      class: "scColor",
    },
    {
      value: "dropDown",
      label: "Add Dropdown For Scoring (DD)",
      letter: "DD",
      class: "pColor",
    },
    {
      value: "sampleImage",
      label: "Add Sample Image (SI)",
      letter: "SI",
      class: "spColor",
    },
    {
      value: "evidenceUpload",
      label: "Evidence Upload (EU)",
      letter: "EU",
      class: "cpColor",
    },
    {
      value: "remarks",
      label: "Add Remarks (R)",
      letter: "R",
      class: "irColor",
    },
    {
      value: "textfield",
      label: "Add Text Field (TF)",
      letter: "TF",
      class: "scColor",
    },
  ];



  // drop down modal start
  // drop down modal start
  // drop down modal start


  const handleModalChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleAddField = () => {
    setFields([...fields, { text: "", value: "" }]); // Add a new empty field
  };

  const handleDeleteField = (index) => {
    const updatedFields = fields.filter((_, i) => i !== index); // Remove the field by index
    setFields(updatedFields);
  };

  const handleModalInputChange = (index, field, value) => {
    const updatedFields = [...fields];
    updatedFields[index][field] = value; // Update the specific field value
    setFields(updatedFields);
  };


  // drop down modal end
  // drop down modal end
  // drop down modal end

  const handleChange = (event, checkpointId) => {
    const value = event.target.value;

    setSelectedItems((prevSelected) => ({
      ...prevSelected,
      [checkpointId]: prevSelected[checkpointId]?.includes(value)
        ? prevSelected[checkpointId].filter((item) => item !== value) // Remove if already selected
        : [...(prevSelected[checkpointId] || []), value], // Add if not already selected
    }));
  };
  const handleInputChange = (checkpointId, itemId, value) => {
    setItemValues((prev) => ({
      ...prev,
      [checkpointId]: {
        ...(prev[checkpointId] || {}),
        [itemId]: value,
      },
    }));
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded((prevState) => ({
      ...prevState,
      [panel]: isExpanded,
    }));
  };

  const handleAddParameter = () => {
    const newParameter = {
      id: Date.now(), // Unique ID based on time for each new accordion
      title: "Parameter",
      subparameters: [],
    };
    setParameters([...parameters, newParameter]);
  };

  const handleAddSubparameter = (parameterId) => {
    const updatedParameters = parameters.map((parameter) =>
      parameter.id === parameterId
        ? {
            ...parameter,
            subparameters: [
              ...parameter.subparameters,
              { id: Date.now(), title: "Sub Parameter", checkpoints: [] },
            ],
          }
        : parameter
    );
    setParameters(updatedParameters);
  };

  const handleAddCheckpoint = (parameterId, subparameterId) => {
    const updatedParameters = parameters.map((parameter) =>
      parameter.id === parameterId
        ? {
            ...parameter,
            subparameters: parameter.subparameters.map((subparameter) =>
              subparameter.id === subparameterId
                ? {
                    ...subparameter,
                    checkpoints: [
                      ...subparameter.checkpoints,
                      {
                        id: Date.now(),
                        title: "Check Point",
                        childCheckpoints: [],
                      },
                    ],
                  }
                : subparameter
            ),
          }
        : parameter
    );
    setParameters(updatedParameters);
  };
  const handleDeleteParameter = (parameterId) => {
    setParameters(parameters.filter((param) => param.id !== parameterId));
  };

  const handleDeleteSubparameter = (parameterId, subparameterId) => {
    const updatedParameters = parameters.map((parameter) =>
      parameter.id === parameterId
        ? {
            ...parameter,
            subparameters: parameter.subparameters.filter(
              (subparam) => subparam.id !== subparameterId
            ),
          }
        : parameter
    );
    setParameters(updatedParameters);
  };

  const handleDeleteCheckpoint = (
    parameterId,
    subparameterId,
    checkpointId
  ) => {
    const updatedParameters = parameters.map((parameter) =>
      parameter.id === parameterId
        ? {
            ...parameter,
            subparameters: parameter.subparameters.map((subparameter) =>
              subparameter.id === subparameterId
                ? {
                    ...subparameter,
                    checkpoints: subparameter.checkpoints.filter(
                      (checkpoint) => checkpoint.id !== checkpointId
                    ),
                  }
                : subparameter
            ),
          }
        : parameter
    );
    setParameters(updatedParameters);
  };

  return (
    <CreationContain>
      {customMatches ? (
        <CreationCardMobile>
          <h4>Open in Desktop!</h4>
        </CreationCardMobile>
      ) : (
        <CreationCard>
          <div className="CreationHeader">
            <div>
              <h4>Create Dealer Evaluation Checklist</h4>
              <p>Fill in the request detail to create new checklist</p>
            </div>
          </div>
          <Grid container spacing={2}>
            <Grid item size={12}>
              <div className="CreationButtonCard">
                <h4>Element</h4>
                <button className="CreationButton" onClick={handleAddParameter}>
                  <AddIcon /> Parameter
                </button>
              </div>
            </Grid>
            <Grid item size={12}>
              <div className="CreationPreview">
                <div className="CheckListPreviewCard">
                  <div className="ScoreCard">
                    <p>Used / Total Evaluation Scope</p>
                    <p>
                      (<span className="ScoreCount">0</span> /{" "}
                      <span className="ScoreTotalCount">1000</span>)
                    </p>
                  </div>
                  <div className="CheckpointNameCard">
                    <p className="CheckpointName">Nikhili</p>
                    <p className="ScoreCount">100</p>
                  </div>

                  {/* Dynamically render added Parameters */}
                  {parameters.map((parameter, index) => (
                    <Accordion
                      key={parameter.id}
                      expanded={expanded[`parameter-${parameter.id}`] || false}
                      onChange={handleAccordionChange(
                        `parameter-${parameter.id}`
                      )}
                      className="Border_PL"
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`parameter-${parameter.id}-content`}
                        id={`parameter-${parameter.id}`}
                        className="Border_PB"
                      >
                        <Typography>{parameter.title}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="ChecklistAddCard P">
                          {" "}
                          {/* Delete Parameter button */}
                          <button
                            className="CloseCheckpoint"
                            onClick={() => handleDeleteParameter(parameter.id)}
                          >
                            <CloseIcon />
                          </button>
                          <div className="CheckListInputContain">
                            <span className="FirstName">P</span>
                            <input
                              type="text"
                              placeholder="Enter Parameter"
                              value={pvalue}
                              onChange={(e) => setPvalue(e.target.value)}
                            />
                          </div>
                          <div className="ScoreResievedCount"><input type="number" placeholder='100'/></div>
                          <div className="ScorePersentage">0.00%</div>
                          <div className="ParameterNumber">{index + 1}</div>
                          <CreationButton
                            onClick={() => handleAddSubparameter(parameter.id)}
                          >
                            <AddIcon className="parameter" /> Sub Parameter
                          </CreationButton>
                        </div>

                        {/* Dynamically render Subparameters */}
                        {parameter.subparameters.map(
                          (subparameter, subIndex) => (
                            <Accordion
                              key={subparameter.id}
                              expanded={
                                expanded[`subparameter-${subparameter.id}`] ||
                                false
                              }
                              onChange={handleAccordionChange(
                                `subparameter-${subparameter.id}`
                              )}
                              className="Border_SPL"
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`subparameter-${subparameter.id}-content`}
                                id={`subparameter-${subparameter.id}`}
                                className="Border_SPB"
                              >
                                <Typography>{subparameter.title}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="ChecklistAddCard SP">
                                  {/* Delete Subparameter button */}
                                  <button
                                    className="CloseCheckpoint"
                                    onClick={() =>
                                      handleDeleteSubparameter(
                                        parameter.id,
                                        subparameter.id
                                      )
                                    }
                                  >
                                    <CloseIcon />
                                  </button>
                                  <div className="CheckListInputContain">
                                    <span className="FirstName">SP</span>
                                    <input
                                      type="text"
                                      placeholder="Enter Subparameter"
                                    />
                                  </div>
                                  <div className="ScoreResievedCount"><input type="number" placeholder='100'/></div>
                                  <div className="ScorePersentage">0.00%</div>
                                  <div className="ParameterNumber">
                                    {index + 1}.{subIndex + 1}
                                  </div>
                                  <CreationButton
                                    onClick={() =>
                                      handleAddCheckpoint(
                                        parameter.id,
                                        subparameter.id
                                      )
                                    }
                                  >
                                    <AddIcon className="subparameter " /> Check
                                    Point
                                  </CreationButton>
                                </div>

                                {/* Dynamically render Checkpoints */}
                                {subparameter.checkpoints.map(
                                  (checkpoint, checkpointIndex) => (
                                    <Accordion
                                      key={checkpoint.id}
                                      expanded={
                                        expanded[
                                          `checkpoint-${checkpoint.id}`
                                        ] || false
                                      }
                                      onChange={handleAccordionChange(
                                        `checkpoint-${checkpoint.id}`
                                      )}
                                      className="Border_CPL"
                                    >
                                      <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`checkpoint-${checkpoint.id}-content`}
                                        id={`checkpoint-${checkpoint.id}`}
                                        className="Border_CPB"
                                      >
                                        <Typography>
                                          {checkpoint.title}
                                        </Typography>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <div className="ChecklistAddCard CP">
                                          {/* Delete Checkpoint button */}
                                          <button
                                            className="CloseCheckpoint"
                                            onClick={() =>
                                              handleDeleteCheckpoint(
                                                parameter.id,
                                                subparameter.id,
                                                checkpoint.id
                                              )
                                            }
                                          >
                                            <CloseIcon />
                                          </button>
                                          <div className="CheckListInputContain">
                                            <span className="FirstName">
                                              CP
                                            </span>
                                            <input
                                              type="text"
                                              placeholder="Enter Checkpoint"
                                            />
                                          </div>
                                          <div className="ScoreResievedCount">
                                          <input type="number" placeholder='100'/>
                                          </div>
                                          <div className="ScorePersentage">
                                            0.00%
                                          </div>
                                          <div className="ParameterNumber">
                                            {index + 1}.{subIndex + 1}.
                                            {checkpointIndex + 1}
                                          </div>
                                          <div className="SelectContain">
                                            <FormControl fullWidth>
                                              <Select
                                                labelId="checkbox-select-label"
                                                id="checkbox-select"
                                                sx={{
                                                  height: 50, // Set the desired height
                                                  ".MuiSelect-select": {
                                                    padding: "10px", // Adjust padding as needed
                                                  },
                                                }}
                                                value={
                                                  selectedItems[
                                                    checkpoint.id
                                                  ] || []
                                                }
                                                onChange={(event) =>
                                                  handleChange(
                                                    event,
                                                    checkpoint.id
                                                  )
                                                }
                                                renderValue={() =>
                                                  selectedItems[checkpoint.id]
                                                    ?.length > 0
                                                    ? "Selected"
                                                    : "Select"
                                                }
                                                displayEmpty
                                              >
                                                {options.map((option) => (
                                                  <MenuItem
                                                    key={option.value}
                                                    value={option.value}
                                                  >
                                                    <Checkbox
                                                      checked={
                                                        selectedItems[
                                                          checkpoint.id
                                                        ]?.includes(
                                                          option.value
                                                        ) || false
                                                      }
                                                    />
                                                    {option.label}
                                                  </MenuItem>
                                                ))}
                                              </Select>
                                            </FormControl>
                                          </div>
                                        </div>

                                        {/* Render selected items as cards */}
                                        <Box mt={2}>
                                          {(
                                            selectedItems[checkpoint.id] || []
                                          ).map((item) => {
                                            const option = options.find(
                                              (option) => option.value === item
                                            );
                                            return (
                                              <div
                                                key={item}
                                                className="ChecklistAddCard P CheckpoinChilds"
                                              >
                                                <div className="CheckListInputContain">
                                                  <span
                                                    className={`FirstName ${
                                                      option?.class || ""
                                                    }`}
                                                  >
                                                    {option?.letter === "EU" ? (
                                                      <PhotoCameraIcon />
                                                    ) : (
                                                      option?.letter || ""
                                                    )}
                                                  </span>
                                                  {option?.letter === "DD" ? (
                                                    <select>
                                                      <option value="">
                                                        Select
                                                      </option>
                                                    </select>
                                                  ) : (
                                                    <input
                                                      type={
                                                        option?.letter === "SI"
                                                          ? "file"
                                                          : "text"
                                                      }
                                                      placeholder={
                                                        option?.label || ""
                                                      }
                                                      value={
                                                        itemValues[
                                                          checkpoint.id
                                                        ]?.[item] || ""
                                                      } // Controlled value
                                                      onChange={(e) =>
                                                        handleInputChange(
                                                          checkpoint.id,
                                                          item,
                                                          e.target.value
                                                        )
                                                      } // Update value on change
                                                    />
                                                  )}
                                                </div>
                                                {option?.letter === "DD" && (
                                                  <button
                                                    className="AddIcon"
                                                    onClick={() =>
                                                      setModalState(true)
                                                    }
                                                  >
                                                    <AddIcon />
                                                  </button>
                                                )}
                                                {option?.letter === "EU" && (
                                                  <div className="MandatoryInput">
                                                    <input type="checkbox" />
                                                    Mandatory Image
                                                  </div>
                                                )}
                                                {(option?.letter === "IR" ||
                                                  option?.letter === "SR") && (
                                                  <>
                                                    <div className="ScoreResievedCount">
                                                    <input type="number" placeholder='100'/>
                                                    </div>
                                                    <div className="ScorePersentage">
                                                      0.00%
                                                    </div>
                                                  </>
                                                )}
                                              </div>
                                            );
                                          })}
                                        </Box>
                                      </AccordionDetails>
                                    </Accordion>
                                  )
                                )}
                              </AccordionDetails>
                            </Accordion>
                          )
                        )}
                      </AccordionDetails>
                    </Accordion>
                  ))}

                  <div className="CheckListButtonContain">
                    <button className="saveDraft">Save as Draft</button>
                    <button className="save">Save</button>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>


          {/* modal start */}

          {modalState && (
            <ModalContain>
              <div className="CheckpointModal">
                <div className="ModalHeader">
                  <h4>Add Dropdown Optaions</h4>
                  <button
                    className="CloseButton"
                    onClick={() => setModalState(false)}
                  >
                    <CloseIcon />
                  </button>
                </div>
                <div className="ModalBody">
                  <h4>Select Option Type:</h4>
                  <div className="ModalTabsContain">
                    <label>
                      <input
                        type="radio"
                        name="DropDown"
                        value="YesNo"
                        onChange={handleModalChange}
                      />{" "}
                      Yes / No
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="DropDown"
                        value="Number"
                        onChange={handleModalChange}
                      />{" "}
                      Number
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="DropDown"
                        value="Customize"
                        onChange={handleModalChange}
                      />{" "}
                      Customize
                    </label>
                  </div>
                  <div>
                    {selectedOption === "YesNo" && (
                      <div className="ModalFeildContain">
                        <input type="text" placeholder="Yes"  />
                        <input type="text" placeholder="No"  />
                      </div>
                    )}
                    {selectedOption === "Number" && (
                      <div className="ModalFeildContain">
                        <input type="text" placeholder="Lower No." />
                        <input type="text" placeholder="Upper No." />
                      </div>
                    )}
                    {selectedOption === "Customize" && (
                      <>
                        {/* Default Field (Always at the Top) */}
                        <div className="ModalFeildContain">
                          <input
                            type="text"
                            placeholder="Text"
                          />
                          <input
                            type="text"
                            placeholder="Value"
                          />
                          <button
                            className="AddCutomize"
                            onClick={handleAddField}
                          >
                            <AddIcon />
                          </button>
                        </div>

                        {/* Dynamically Added Fields */}
                        {fields.map((field, index) => (
                          <div key={index} className="ModalFeildContain">
                            <input
                              type="text"
                              placeholder="Text"
                              value={field.text}
                              onChange={(e) =>
                                handleModalInputChange(
                                  index,
                                  "text",
                                  e.target.value
                                )
                              }
                            />
                            <input
                              type="text"
                              placeholder="Value"
                              value={field.value}
                              onChange={(e) =>
                                handleModalInputChange(
                                  index,
                                  "value",
                                  e.target.value
                                )
                              }
                            />
                            <button
                              className="DeleteField"
                              onClick={() => handleDeleteField(index)}
                            >
                              <DeleteIcon />
                            </button>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
                <div className="ModalFooter">
                  <button>Save</button>
                </div>
              </div>
            </ModalContain>
          )}

          {/* modal end */}
        </CreationCard>
      )}
    </CreationContain>
  );
};

export default QVRManualCreation;
