import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import { Loader } from "../../utils/loader";
import {
  Error,
  LoginContain,
  LoginFooter,
  RequieredStar,
} from "../../style/login/Login";
import { apiCall } from "../../services/authServices";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Forgot() {
  const [credentials, setCredentials] = useState({
    userType: "",
    userId: "",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  // Validate the form
  const validateForm = () => {
    let formErrors = {};
    if (!credentials.userType) formErrors.userType = "User Type is required";
    if (!credentials.userId.trim())
      formErrors.userId = "User ID is required";
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  // Handle form submission
  const submitEmail = async (e) => {
    try {
      e.preventDefault();
      if (validateForm()) {
        const payload = {
          userType: credentials.userType,
          userId: credentials.userId
        }
       const res = await apiCall({endpoint: 'api/auth/init-forgot-password', method: "POST", payload: payload})
       if(res && res.success){
        res['userId'] = credentials.userId,
        res['userType'] = credentials.userType
        navigate("/get-otp", { state: { data: res } });
       }
      }
    } catch (error) {
      toast.error(error?.response?.data?.error ?? "Something went wrong");
    }
  };

  return (
    <>
     <ToastContainer />
      <LoginContain>
        <div className="LoginLogo">
          <img src={Logo} alt="logo" />
        </div>
        <div className="LoginCard">
          <div className="LoginTitle">
            <h4>Login</h4>
            <p>EasyGo Audit support team is just a tap away!</p>
          </div>
          <form onSubmit={submitEmail}>
            <div className="LoginGroup">
              <label htmlFor="userType">
                User Type <RequieredStar>*</RequieredStar>
              </label>
              <select
                name="userType"
                id="userType"
                value={credentials.userType}
                onChange={handleChange}
              >
                <option value="">Please Select</option>
                <option value="employee">Employee</option>
                <option value="dealer">AMD</option>
              </select>
              {errors.userType && <Error>{errors.userType}</Error>}
            </div>
            <div className="LoginGroup">
              <label htmlFor="userId">
                User ID <RequieredStar>*</RequieredStar>
              </label>
              <input
                type="text"
                id="userId"
                name="userId"
                placeholder="Enter Your ID"
                value={credentials.userId}
                onChange={handleChange}
              />
              {errors.userId && <Error>{errors.userId}</Error>}
            </div>
            <div className="LoginGroup LoginBtn">
              <button type="submit" disabled={loading}>
                {loading ? <Loader /> : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </LoginContain>
      <LoginFooter></LoginFooter>
    </>
  );
}

export default Forgot;
