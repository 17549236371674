import { Box } from "@mui/material";
import { styled } from "@mui/system";


export const ScheduleVisitContain = styled(Box)(()=>({
    paddingTop:'5px',

}))

export const ScheduleTabContain = styled(Box)(()=>({
    display:'flex',
    alignItems:'center',
    justifyContent:'start',
    gap:'10px',
    marginBottom:'10px',
    '& .MuiFormGroup-root':{
        '& .MuiTypography-root':{
            fontSize:'12px',
        },
    },
}))