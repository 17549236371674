import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from '@mui/material';
import { ButtonContain, Card, CardContain, CardCustomList, CardFooter, CardTitle, CustomButtonContain, FieldContain, TableContain, TabsContain } from '../../style/Home'
import Grid from '@mui/material/Grid2';
import Filter from '../../Components/filters';
import DoughnutChart from '../../Components/AM/charts/Chart';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Colors } from '../../style/theme';
import { Link } from 'react-router-dom';
import { getCurrentFinancialYear, getCurrentMonth } from "../../utils/helper";
import { GetDashboardData } from '../../services/dashboardService';
import { GetDropdownData } from "../../services/scheduleVisit";



function Home() {
  const [value, setValue] = useState('MVRTabs');
  const [isSticky, setIsSticky] = useState(false); // To track scroll position
  const [filterValues, setFilterValues] = useState({});
  const [dashboardData, setDashboardData] = useState({})
  const [selectedFinYear, setSelectedFinYear] = useState(getCurrentFinancialYear())
  const [selectedPeriod, setSelectedPeriod] = useState(getCurrentMonth())
  const [loader, setLoader] = useState(false)


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {

    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true); // Add 'sticky' class when scroll > 56px
      } else {
        setIsSticky(false); // Remove 'sticky' class
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const filtersData = [
    {
      xs: 6, sm: 4, lg: 2,
      key: 'region',
      label: 'Region',
      type: 'select',
      options: [
        { value: 'south', label: 'South' },
        { value: 'north', label: 'North' },
        { value: 'east', label: 'East' },
        { value: 'West', label: 'West' },
      ],
    },
    {
      xs: 6, sm: 4, lg: 2,
      key: 'zone',
      label: 'Zone',
      type: 'select',
      options: [
        { value: 'delhi-south', label: 'Delhi South' },
        { value: 'delhi-north', label: 'Delhi North' },
        { value: 'delhi-east', label: 'Delhi East' },
        { value: 'delhi-West', label: 'Delhi West' },
      ],
    },
    {
      xs: 6, sm: 4, lg: 2,
      key: 'area',
      label: 'Area',
      type: 'select',
      options: [
        { value: 'up-south', label: 'UP South' },
        { value: 'up-north', label: 'UP North' },
        { value: 'up-east', label: 'UP East' },
        { value: 'up-West', label: 'UP West' },
      ],
    },
    {
      xs: 6, sm: 4, lg: 2,
      key: 'year',
      label: 'Financial Year',
      type: 'select',
      options: [
        { value: '2024-2025', label: '2024-2025' },
        { value: '2023-2024', label: '2023-2024' },
        { value: '2022-2023', label: '2022-2023' },
      ],
    },
    {
      xs: 6, sm: 4, lg: 2,
      key: 'period',
      label: 'Period',
      type: 'select',
      options: [
        { value: 'H1', label: 'H1' },
        { value: 'H2', label: 'H2' },
      ],
    },
    {
      xs: 6, sm: 4, lg: 2,
      key: 'dealer-code-name',
      label: 'Dealer Code Name',
      type: 'select',
      options: [
        { value: 'DL010001', label: 'DL010001' },
        { value: 'DL020002', label: 'DL020002' },
        { value: 'DL010003', label: 'DL010003' },
        { value: 'DL020004', label: 'DL020004' },
      ],
    },
  ];
  const handleFilterChange = (filterKey, value) => {
    console.log(`Filter changed: ${filterKey} -> ${value}`);
    setFilterValues((prev) => ({
      ...prev, // Keep previous values
      [filterKey]: value, // Update the specific field
    }));
  };

  const getDashboardData = async () => {
    try {
      setLoader(true)
      const payload = {
        "financialYear": selectedFinYear,
        "period": selectedPeriod,
        "dealerCodes": [],
        "regions": [],
        "zones": [],
        "areas": []
      }
      const res = await GetDashboardData({ payload: payload })
      if (res.success) {
        setDashboardData(res.data)
      }
    } catch (error) {
      console.log('error: ', error);
      if (error.code === "ERR_NETWORK") {
        toast.error("Network error");
      }
    } finally {
      setLoader(false)
    }
  }


  const getDropdownData = async () => {
    try {
      setLoader(true)
      const res = await GetDropdownData()
      if (res.success) {
        console.log('res: ', res);
      }
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoader(false)
    }
  }
  useEffect(() => {
    console.log("--------------------")
    getDropdownData()
  }, [])


  useEffect(() => {
    getDashboardData()
  }, [])


  // chart data start
  const MVRchart = {
    labels: ['Total', 'Submitted', 'Accepted', 'Reconsideration', 'Pending'],
    datasets: [
      {
        data: dashboardData?.mvrStatus ? Object.values(dashboardData?.mvrStatus) : [],
        backgroundColor: [Colors.total, Colors.submitted, Colors.accepted, Colors.reconsidertion, Colors.pending],
        borderColor: [Colors.total, Colors.submitted, Colors.accepted, Colors.reconsidertion, Colors.pending],
        borderWidth: 1,
      },
    ],
  };
  const QVRchart = {
    labels: ['Total AMD', 'Eligible AMD', 'Visit Plan', 'Evaluation By AM', 'Reconsideration Request', 'Accepted By AMD', 'Avrage Score'],
    datasets: [
      {
        data: [6, 1, 1, 1, 1, 1, 1],
        backgroundColor: [Colors.total, Colors.submitted, Colors.pending, Colors.total, Colors.reconsidertion, Colors.accepted, Colors.pending],
        borderColor: [Colors.total, Colors.submitted, Colors.pending, Colors.total, Colors.reconsidertion, Colors.accepted, Colors.pending],
        borderWidth: 1,
      },
    ],
  };
  // chart data end

  return (
    <>
      <TabsContain>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="tabs example"
          className={`HomeTabs ${isSticky ? "StickyShadow" : ""}`}
        >
          <Tab label="MVR" value="MVRTabs" />
          <Tab label="QVR" value="QVRTabs" />
        </Tabs>
      </TabsContain>
      {value === "MVRTabs" && (
        <>
          <CardContain>
            <Card mt={1} className='Filter_bg'>
              <Grid container spacing={1}>
                <Filter filters={filtersData} onFilterChange={handleFilterChange}></Filter>
                <Grid item size={12}>
                  <ButtonContain>
                    <button className="SearchButton">Search</button>
                    <button className="ResetButton">Reset</button>
                  </ButtonContain>
                </Grid>
              </Grid>
            </Card>
          </CardContain>
          <CardContain mt={2}>
            <CardTitle variant='h4'>MVR Status</CardTitle>
            <Card>
              <Grid container spacing={2}>
                <Grid item size={{ xs: 6 }}>
                  <CardCustomList>
                    <li>
                      Total AMD :
                      <span className="StatusCount total">{dashboardData?.mvrStatus?.
                        total}</span>
                    </li>
                    <li>
                      Submitted By AM :
                      <span className="StatusCount submitted">{dashboardData?.mvrStatus?.
                        submitted
                      }</span>
                    </li>
                    <li>
                      Accepted By AMD :
                      <span className="StatusCount accepted">{dashboardData?.mvrStatus?.
                        accepted
                      }</span>
                    </li>
                    <li>
                      Reconsideration By AMD :
                      <span className="StatusCount reconsidertion">{dashboardData?.mvrStatus?.
                        reconsidered
                      }</span>
                    </li>
                    <li>
                      Pending By AM :
                      <span className="StatusCount pending">{dashboardData?.mvrStatus?.
                        pending
                      }</span>
                    </li>
                  </CardCustomList>
                </Grid>
                <Grid item size={{ xs: 6 }}>
                  <DoughnutChart data={MVRchart} />
                </Grid>
              </Grid>
            </Card>
            <CardFooter>
              <div><p> Total MVR Submitted : <span className="MVRCount">1</span></p></div>

              <CustomButtonContain>
                <Link to='/amdstatus'><button className='Pointer'>AMD Wise Status <KeyboardDoubleArrowRightIcon /></button></Link>
              </CustomButtonContain>
            </CardFooter>
          </CardContain>
          <CardContain mt={2}>
            <TableContain>
              <table className='table'>
                <thead>
                  <tr className='TableTitle'>
                    <th colSpan={7}><h4 >MVR-MoM Status</h4></th>
                  </tr>
                  <tr>
                    <th>Total MoM Points</th>
                    <th>Open Mom Points</th>
                    <th>MoM Points Completed By Dealers</th>
                    <th>MoM Points Completed By AM</th>
                    <th>Escalated MoM Points at AM(Cum.)</th>
                    <th>Escalated MoM Points at ZM(Cum.)</th>
                    <th>Escalated MoM Points at RM(Cum.)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>6</td>
                    <td>6</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                </tbody>
              </table>
            </TableContain>
            <CardFooter>
              <div><p> Carry forward open points : <span className="MVRCount">0</span></p></div>

              <CustomButtonContain>
                <button className='Pointer'>MVR MoM Status <KeyboardDoubleArrowRightIcon /></button>
              </CustomButtonContain>
            </CardFooter>
          </CardContain>
        </>
      )}
      {value === "QVRTabs" && (
        <>

          <CardContain>
            <Card mt={1} className='Filter_bg'>
              <Grid container spacing={1}>
                <Filter filters={filtersData} onFilterChange={handleFilterChange}></Filter>
                <Grid item size={12}>
                  <ButtonContain>
                    <button className="SearchButton">Search</button>
                    <button className="ResetButton">Reset</button>
                  </ButtonContain>
                </Grid>
              </Grid>
            </Card>
          </CardContain>
          <CardContain mt={2}>
            <CardTitle variant='h4'>QVR Status</CardTitle>
            <Card>
              <Grid container spacing={2}>
                <Grid item size={6}>
                  <CardCustomList>
                    <li>Total AMD :
                      <span className="StatusCount total">6</span>
                    </li>
                    <li>Eligible AMD :
                      <span className="StatusCount submitted">1</span>
                    </li>
                    <li>Visit Plan :
                      <span className="StatusCount pending">1</span>
                    </li>
                    <li>Evaluation by AM :
                      <span className="StatusCount total">1</span>
                    </li>
                    <li>Reconsideration Request :
                      <span className="StatusCount reconsidertion">1</span>
                    </li>
                    <li>Accepted by AMD :
                      <span className="StatusCount accepted">1</span>
                    </li>
                    <li>Avrage Score :
                      <span className="StatusCount pending">1</span>
                    </li>
                  </CardCustomList>
                </Grid>
                <Grid item size={6}>
                  <DoughnutChart data={QVRchart} />
                </Grid>
              </Grid>
            </Card>
            <CardFooter>
              <div><p> Scores of AMDs who have accepted the evaluaton</p></div>

              <CustomButtonContain>
                <button>AMD Evaluation Status <KeyboardDoubleArrowRightIcon /></button>
              </CustomButtonContain>
            </CardFooter>
          </CardContain>
          <CardContain mt={2}>
            <TableContain>
              <table className='table'>
                <thead>
                  <tr className='TableTitle'>
                    <th colSpan={7}><h4 >MoM Status</h4></th>
                  </tr>
                  <tr>
                    <th>Total Points</th>
                    <th>Open Points</th>
                    <th>Completed By AMD</th>
                    <th>Completed By AM</th>
                    <th>Escalated To AM</th>
                    <th>Escalated To ZM</th>
                    <th>Escalated To RM</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>6</td>
                    <td>6</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                </tbody>
              </table>
            </TableContain>
            <CardFooter>
              <div><p style={{ visibility: 'hidden' }}> Carry <span className="MVRCount"></span></p></div>

              <CustomButtonContain>
                <button>QVR MoM Status <KeyboardDoubleArrowRightIcon /></button>
              </CustomButtonContain>
            </CardFooter>
          </CardContain>
        </>)}
    </>
  )
}

export default Home

