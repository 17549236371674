import { Box, Button } from "@mui/material";
import {  styled } from "@mui/system";
import { Colors } from "../theme";

export const CreationContain = styled(Box)(() => ({
  paddingTop: "20px",
  paddingLeft: "10px",
}));

export const CreationCardMobile = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  height: "250px",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  backgroundColor: "#64a0af",
  "& h4": {
    color: "#fff",
    fontSize: "40px",
  },
}));

export const CreationCard = styled(Box)(() => ({
  '& .pColor':{
    color: "#0da2ff",},
  '& .spColor':{
    color:'#93c572',},
  '& .cpColor':{
    color:'#cc0001',},
  '& .irColor':{
    color:'#FFC300',},
  '& .scColor':{
    color:'#928766',},
    '& .Border_PL':{
      borderLeft:'2px solid #0da2ff',
    },
    '& .Border_PB':{
      borderBottom:'1px solid #0da2ff',
    },
    '& .Border_SPL':{
      borderLeft:'2px solid #93c572',
    },
    '& .Border_SPB':{
      borderBottom:'1px solid #93c572',
    },
    '& .Border_CPL':{
      borderLeft:'2px solid #cc0001',
    },
    '& .Border_CPB':{
      borderBottom:'1px solid #cc0001',
    },
  "& .CreationHeader": {
    display: "flex",
    alignItems: "start",
    justifyContent: "space-between",
    marginBottom: "20px",
    "& div": {
      width: "50%",
    },
    "& h4": {
      fontSize: "20px",
      color: Colors.gray,
      marginBottom: "10px",
    },
    "& p": {
      color: Colors.light_gray,
      fontSize: "14px",
    },
    "& h3": {
      fontSize: "24px",
      fontWeight: "bolder",
      textAlign: "center",
    },
  },
  "& .CreationButtonCard": {
    "& h4": {
      fontSize: "15px",
      fontWeight: 400,
      position: "relative",
      "&::before": {
        content: '" "', // Empty string content (quotes need to be escaped properly)
        position: "absolute",
        right: "0",
        top: "7px",
        backgroundColor: Colors.gray, // Assuming Colors is defined somewhere
        height: "1px",
        width: "90%",
      },
    },
    "& .CreationButton": {
      backgroundColor: "#f5f5f5",
      border: "none",
      width: "100%",
      textAlign: "left",
      padding: "12px",
      fontSize:'11px',
    //   marginBottom: "10px",
      display: "flex",
    //   textWrap:'nowrap',
      alignItems: "center",
      gap: "5px",
      width:'200px',
      marginTop:'20px',
      "&.parameter svg": {
        color: "#0da2ff",
      },
      '&.subparameter svg':{
        color:'#93c572',
      },
      '&.checkpoint svg':{
        color:'#cc0001',
      },
      '&.idealrequirment svg':{
        color:'#FFC300',
      },
      '&.scoringcriteria svg':{
        color:'#928766',
      }
    },
  },
  '& .CreationPreview':{
    "& h4": {
      fontSize: "15px",
      fontWeight: 400,
      position: "relative",
      "&::before": {
        content: '" "', // Empty string content (quotes need to be escaped properly)
        position: "absolute",
        right: "0",
        top: "7px",
        backgroundColor: Colors.gray, // Assuming Colors is defined somewhere
        height: "1px",
        width: "70%",
      },
    },
    '& .CheckListPreviewCard':{
        marginTop:'24px',
        boxShadow:'rgba(60, 64, 67, 0.3) 0px 2px 4px 0px,rgba(60, 64, 67, 0.15) 0px 2px 6px 1px',
        padding:'10px',
        borderRadius:'5px',
        // maxHeight:'530px',
        // overflow:'auto',
        backgroundColor:'#fff',
        '& .ScoreCard':{
            display:'flex',
            alignItems:'center',
            justifyContent:'space-between',
            marginBottom:'10px',
            '& .ScoreCount,& .ScoreTotalCount':{
                fontWeight:'bold',
            },
        },
        '& .CheckpointNameCard':{
            display:'flex',
            gap:'10px',
            marginBottom:'15px',
            '& p':{
                backgroundColor: "#f5f5f5",
                padding:'10px',
            },
            '& .CheckpointName':{
                width:'80%',
            },
            '& .ScoreCount':{
                width:'20%',
            }
        },
        '& .ChecklistAddCard':{
            display:'flex',
            gap:'10px', 
            marginTop:'15px',
            marginBottom:'15px',
            '&.Controls':{
              marginLeft:'40px',
            },
            // '&.P':{
            //     '& .FirstName':{
            //         color: "#0da2ff",},
            // },
            // '&.SP':{
            //     // marginLeft:'20px',
            //     '& .FirstName':{
            //         color:'#93c572',},
            // },
            // '&.CP':{
            //     // marginLeft:'40px',
            //     '& .FirstName':{
            //         color:'#cc0001',},},
            // '&.IR':{
            //     marginLeft:'60px',
            //     '& .FirstName':{
            //         color:'#FFC300',},},
            //     '&.SC':{
            //         marginLeft:'60px',
            //         '& .FirstName':{
            //             color:'#928766',},},
            '& .CloseCheckpoint':{
                backgroundColor:'transparent',
                color:'red',
                '& svg':{
                    fontSize:'22px',
                }
            },
            '& div':{
                height:'40px',
                paddingLeft:'10px',
                backgroundColor: "#f5f5f5",
                '&.SelectContain':{
                  paddingLeft:'0',
                  height:'auto',
                  minWidth:'120px',
                  fontSize:'12px',
                  '& div':{
                    height:'auto',
                    backgroundColor:'#fff',
                    '&.MuiFormControl-root':{
                      paddingLeft:'0',
                    },
                  }
                }
            },
            '&.CheckpoinChilds':{
              marginLeft:'32px',
            },
            '& .CheckListInputContain':{
                backgroundColor: "#f5f5f5",
                display:'flex',
                alignItems:'center',
                gap:'10px',
                '& .FirstName':{
                    // marginRight:'10px',
                    fontSize:'12px',
                    width:'20px',
                    '&.Icon svg':{
                      color:'#002D62',
                    },
                },  
                '& input,& select':{
                    height:'100%',
                    border:'1px solid #e4e1e1',
                    padding:'10px',
                    fontSize:'13px',
                    minWidth:'200px',
                    '&.FileUpload':{
                      backgroundColor:'#fff',
                    }
                },

            },
            '& .MandatoryInput':{
              display:'flex',
              gap:'5px',
              alignItems:'center',
              justifyContent:'center',
              backgroundColor:'#fff',
              paddingLeft:'0',
              '& span':{
                fontSize:'11px',
              }
            },
            '& .ScoreResievedCount,& .ScorePersentage,& .ParameterNumber':{
                width:'10%',
                display:'flex',
                alignItems:'center',
                justifyContent:'start',
                fontSize:'13px',
                paddingRight:'10px',
                '& input':{
                  width:'100%',
                  height:'100%',
                  border:'none',
                  outline:'none',
                  backgroundColor:'transparent',
                },
            },
            '& .AddIcon':{
              padding:'0',
              display:'flex',
              justifyContent:'center',
              alignItems:'center',
              width:'10%',
            },
            '& .MandatoryInput':{
              display:'flex',
              alignItems:'center',
              gap:'5px',
              backgroundColor:'transparent',
              padding:'0',
              '& input':{
                width:'auto',
              },
            }
        },
        '& .CheckListControls':{

        },
        '& .CheckListButtonContain':{
            display:'flex',
            gap:'10px',
            justifyContent:'center',
            marginTop:'20px',
            '& button':{
                color:'#fff',
                padding:'7px 15px',
                borderRadius:'5px',
                border:'none',
            },
            '& .saveDraft':{
                backgroundColor:'#d21f3c',
            },
            '& .save':{
                backgroundColor:'rgb(163, 184, 149)',
            }

        }
    },

  }
}));

export const CreationButton = styled(Button)(()=>({
    backgroundColor: "#f5f5f5",
    border: "none",
    width: "100%",
    textAlign: "left",
    padding: "12px",
    fontSize:'11px',
    display: "flex",
    alignItems: "center",
    gap: "5px",
    width:'200px',
    color:'#000',
    padding:'0',
    "& svg.parameter": {
      color: "#0da2ff",
    },
    '& svg.subparameter':{
      color:'#93c572',
    },
    '&.checkpoint svg':{
      color:'#cc0001',
    },
    '&.idealrequirment svg':{
      color:'#FFC300',
    },
    '&.scoringcriteria svg':{
      color:'#928766',
    }
}))

export const SelectContain = styled(Box)(()=>({
  minWidth:'120px',
  '& div':{
    backgroundColor:'#fff',
  }

}))

export const ModalContain = styled(Box)(()=>({
  padding:'10px',
  width:'100%',
  height:'100vh',
  position:'fixed',
  top:'0',
  right:'0',
  backgroundColor:'#00000078',
  zIndex:'101',
  display:'flex',
  alignItems:'center',
  justifyContent:'center',
  transition:'225ms',
  '@media (min-width:899px)':{
      '&.DrawerOpen':{
        width: 'calc(100% - 260px)',
      },
  },
  '& .CheckpointModal':{
    backgroundColor:'#fff',
    width:'100%',
    maxWidth:'500px',
    minHeight:'283px',
    maxHeight:'400px',
    overflow:'auto',
    position:'relative',
    '& .ModalHeader':{
      backgroundColor:Colors.primary,
      color:Colors.white,
      padding:'15px 10px',
      position:'sticky',
      left:'0',
      top:'0',
      width:'100%',
      '& h4':{
        fontWeight:'400',
      },
      '& .CloseButton':{
        position:'absolute',
        top:'5px',
        right:'5px',
        backgroundColor:'transparent',
        '& svg':{
          color:Colors.white,

        },
      }

    },
    '& .ModalBody':{
      padding:'10px 20px',
      '& h4':{
        fontWeight:400,
        marginTop:'10px',
      },
      '& .ModalTabsContain':{
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between',
        margin:'20px 0',
        gap:'10px',
        '& label':{
          display:'flex',
          alignItems:'center',
          gap:'5px',
        },
      },
      '& .ModalFeildContain':{
        display:'flex',
        justifyContent:'space-between',
        gap:'10px',
        margin:'10px 0',
        '& input':{
          width:'100%',
          padding:'10px',
          borderRadius:'5px',
          fontSize:'12px',
          outline:'none',
          border:'1px solid',
          borderColor:Colors.bordercolor,
        },
      },
      '& .AddCutomize,& .DeleteField':{
        padding:'0 10px',
      }

    },
    '& .ModalFooter':{
      position:'sticky',
      bottom:'0',
      left:'0',
      width:'100%',
      padding:'20px',
      paddingTop:'10px',
      backgroundColor:'#fff',
        '& button':{
          backgroundColor:Colors.submitted,
          color:Colors.white,
          width:'100%',
          padding:'10px',
          borderRadius:'5px',
        },

    }

    }

}))