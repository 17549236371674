import { apiCall } from "./authServices";

export const GetDashboardData = async ({ payload = null }) => {
    try {
        const endpoint = `api/dashboard/mvr-dashboard-data`
        const res = await apiCall({
            endpoint: endpoint,
            payload: payload,
            method: "post",
        });
        return res
    } catch (error) {
        throw error;
    }
}
