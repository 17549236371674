import { apiCall } from "./authServices";

export const CreateMvr = async ({ payload }) => {
  try {
    const response = await apiCall({
      endpoint: "api/manual/mvr/add-mvr",
      method: "POST",
      payload,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const FetchMvr = async () => {
  try {
    const res = await apiCall({
      endpoint: "api/manual/mvr/get-all-mvr",
      method: "GET",
    });
    if (res.success) {
      const updatedData = [];
      const mvrDetail = res.mvrDetails;
    //   if (mvrDetail && mvrDetail.length) {
    //     for (let i = 0; i < mvrDetail.length; i++) {
    //       const mvr = mvrDetail[i];
    //       const newMvr = {
    //         id: res.id,
    //         dvrVersion: mvr.evaluationName,
    //         createdDate: mvr.createdAt,
    //         submittedDate: "12-10-2024",
    //         status: mvr.mvrStatus,
    //         approvedDate: "12-10-2024",
    //         validFrom: mvr.activeFrom,
    //         validTo: mvr.activeTo,
    //       };
    //       updatedData.push(newMvr)
    //     }
    //   }
      res.mvrDetail = updatedData
    }
    return res;
  } catch (error) {
    throw error;
  }
};

export const UpdateManual = async ({payload}) => {
  try {
    const res = await apiCall({
      endpoint: "api/manual/mvr/add-manual",
      method: "post",
      payload: payload
    });
    if (res.success) {
      console.log(res)
    }
    return res;
  } catch (error) {
    throw error;
  }
};
export const GetMVRDetails = async ({parmas= null}) => {
  try {
    if(parmas){
      const endpoint = `api/manual/mvr/get-manual/${parmas}`
      const res = await apiCall({
        endpoint: endpoint,
        method: "get",
      });
      if (res.success) {
        return res
      }
      return res;
    }
  } catch (error) {
    throw error;
  }
};