import React, { useState, useRef, useEffect } from "react";
import { useMatches } from "../../style/theme";
import { useLocation } from "react-router-dom";
import {
  CreationButton,
  CreationCard,
  CreationCardMobile,
  CreationContain,
  ModalContain,
  SelectContain,
} from "../../style/HO/MVRManualCreation";
import Grid from "@mui/material/Grid2";
import AddIcon from "@mui/icons-material/Add";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Checkbox, ListItemText } from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { GetMVRDetails, UpdateManual } from "../../services/mvrServices";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MVRCheckListMenu } from "../../utils/constants";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";



const MVRManualCreation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const mvrData = location.state?.mvrData;
  const mvrId = mvrData.id;
  const {customMatches} = useMatches();
  const [parameters, setParameters] = useState([]);
  const [expanded, setExpanded] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [itemValues, setItemValues] = useState({});
  const [modalState, setModalState] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const Drawer = useSelector((state) => state.drawer);
  const formDataRef = useRef(new FormData());

  const [fields, setFields] = useState([]);
  const [items, setItems] = useState([[]]); // Or an empty 2D array
  const [currIndex, setCurrIndex] = useState(null)
  const [currentCheckPointIndex, setCurrentCheckPointIndex] = useState(null);
  const [lowerValueChange, setHandleLowerValue] = useState('');
  const [upperValueChange, setHandleUpperValue] = useState('');
  const [dropDownArr, setDropDownArr] = useState([]);
  const tempDropDownArr = [];

  const setItemFunc = (pIndex, cIndex, value) => {
    setItems((prevParameters) => {
      const parametersArr = [...(prevParameters || [])];

      // Ensure that pIndex and cIndex are initialized
      if (!parametersArr[pIndex]) {
        parametersArr[pIndex] = [];
      }
      if (!parametersArr[pIndex][cIndex]) {
        parametersArr[pIndex][cIndex] = [];
      }

      const cItems = [...parametersArr[pIndex][cIndex]];

      // Toggle the item in the cItems array
      if (cItems.includes(value)) {
        parametersArr[pIndex][cIndex] = cItems.filter(
          (item) => item !== value
        );
      } else {
        cItems.push(value);
        parametersArr[pIndex][cIndex] = cItems;
      }
      return parametersArr;
    });
  }

  const updateSelectedItems = (selectedItems, pIndex, cIndex, key) => {
    // Ensure selectedItems has enough space for pIndex
    if (!selectedItems[pIndex]) {
      selectedItems[pIndex] = [];
    }

    // Ensure selectedItems[pIndex] has enough space for cIndex
    if (!selectedItems[pIndex][cIndex]) {
      selectedItems[pIndex][cIndex] = [];
    }

    // Add the key if it doesn't already exist in the array
    if (!selectedItems[pIndex][cIndex].includes(key)) {
      selectedItems[pIndex][cIndex].push(key);
    }

    return selectedItems;
  };

  const fetchMVRDetails = async () => {
    try {
      const selectedItems = [];
      if (mvrData && mvrData.id) {
        const res = await GetMVRDetails({ parmas: mvrData.id });
        if (res.success && res.data && res.data.parameters) {
          const transformedParameters = res.data.parameters.map((param, pIndex) => {
            return {
              id: param.parameterId,
              title: param.parameter,
              totalScore: param.parameterTotalScore,
              checkpoints: param.checklists.map((checkpoint, cIndex) => {
                if (checkpoint.idealRequirement) {
                  updateSelectedItems(selectedItems, pIndex, cIndex, "idealRequirement");
                }
                if (checkpoint.criterionBody) {
                  updateSelectedItems(selectedItems, pIndex, cIndex, "scoringCriteria");
                }
                if (checkpoint.criterionBody) {
                  updateSelectedItems(selectedItems, pIndex, cIndex, "evidenceUpload");
                }

                if (checkpoint.imageSample) {
                  updateSelectedItems(selectedItems, pIndex, cIndex, "sampleImage");
                }
                if (checkpoint.controlType
                ) {
                  updateSelectedItems(selectedItems, pIndex, cIndex, "addDropdown");
                  if (checkpoint.controlType && checkpoint.controlPlaceholder && checkpoint.controlValues) {
                    const dropdownOptions = checkpoint.controlPlaceholder.map((display, index) => ({
                      value: checkpoint.controlValues[index],
                      display: display,
                    }));

                    if (!tempDropDownArr[pIndex]) {
                      tempDropDownArr[pIndex] = [];
                    }
                    tempDropDownArr[pIndex][cIndex] = dropdownOptions;
                  }
                }

                const imageName = checkpoint?.imageSample?.split("/").pop().split("?")[0].split("_")[0];;

                return {
                  id: checkpoint.checklistId,
                  name: checkpoint.checklist,
                  totalScore: checkpoint.checklistTotalScore,
                  imageMandate: checkpoint.imageMandate,
                  imageCount: checkpoint.imageCount,
                  idealRequirement: checkpoint.idealRequirement,
                  controlType: checkpoint.controlType,
                  controlPlaceholder: checkpoint.controlPlaceholder,
                  controlValues: checkpoint.controlValues,
                  criteria: checkpoint.criterionBody,
                  sampleImage: checkpoint.imageSample,
                  imagePreview: checkpoint.imageSample,
                  imageName: imageName,
                  orgUrl: checkpoint?.imageUrl
                };
              }),
            };
          });

          setParameters(transformedParameters);
          setItems(selectedItems);
          setDropDownArr(tempDropDownArr);
        }
      }
    } catch (error) {
      console.log('error: ', error);
      toast.error(error?.response?.data?.error ?? "Something went wrong");
    }
  };

  useEffect(() => {
    fetchMVRDetails();
  }, []);

  const handleRemoveImage = (parameterIndex, checkpointIndex) => {
    // Create a copy of the `parameter` state to avoid direct mutation
    const updatedParameter = [...parameters];
  
    // Update the specific checkpoint
    updatedParameter[parameterIndex].checkpoints[checkpointIndex].sampleImage = null;
    updatedParameter[parameterIndex].checkpoints[checkpointIndex].imageName = null;
  
    // Update the state (assuming you are using a state management method like useState)
    setParameters(updatedParameter);
  };
  
  const handleAddField = () => {
    setFields([...fields, { text: "", value: "" }]);
  };

  const handleDeleteField = (index) => {
    const updatedFields = fields.filter((_, i) => i !== index);
    setFields(updatedFields);
  };

  const handleModalInputChange = (index, field, value) => {
    const updatedFields = [...fields];
    updatedFields[index][field] = field === "value" ? Number(value) : value;
    setFields(updatedFields);
  };

  const setModal = () => {
    setModalState(true)
  }

  const handleChange = (event, checkpointId) => {
    const value = event.target.value;

    setSelectedItems((prevSelected) => ({
      ...prevSelected,
      [checkpointId]: prevSelected[checkpointId]?.includes(value)
        ? prevSelected[checkpointId].filter((item) => item !== value)
        : [...(prevSelected[checkpointId] || []), value],
    }));
  };
  const handleInputChange = (index, checkpointIndex, item, value, event, e) => {
    if (item === "sampleImage" && event?.target?.files) {
      handleFileChange(index, checkpointIndex, event);
    }
    const updatedParameters = [...parameters];

    if (item === "idealRequirement") {
      if (updatedParameters[index]?.checkpoints?.[checkpointIndex]) {
        updatedParameters[index].checkpoints[checkpointIndex].idealRequirement =
          value;
      }
    }
    if (item === "scoringCriteria") {
      if (updatedParameters[index]?.checkpoints?.[checkpointIndex]) {
        updatedParameters[index].checkpoints[checkpointIndex].criteria = value;
      }
    }
    if (item === "evidenceUpload") {
      if (updatedParameters[index]?.checkpoints?.[checkpointIndex]) {
        const imageCountVal = parseInt(value);
        updatedParameters[index].checkpoints[checkpointIndex].imageCount = imageCountVal;
      }
    }

    if (item === "controlType") {
      if (updatedParameters[index]?.checkpoints?.[checkpointIndex]) {
        updatedParameters[index].checkpoints[checkpointIndex].controlType = value;
      }
    }
    if (item === "controlPlaceholder") {
      if (updatedParameters[index]?.checkpoints?.[checkpointIndex]) {
        updatedParameters[index].checkpoints[checkpointIndex].controlPlaceholder = value;
      }
    }
    if (item === "controlValues") {
      if (updatedParameters[index]?.checkpoints?.[checkpointIndex]) {
        updatedParameters[index].checkpoints[checkpointIndex].controlValues = value;
      }
    }
    setParameters(updatedParameters);

    setItemValues((prev) => ({
      ...prev,
      [parameters[index]?.checkpoints?.[checkpointIndex]?.id]: {
        ...(prev[parameters[index]?.checkpoints?.[checkpointIndex]?.id] || {}),
        [item]: value,
      },
    }));

  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded((prevState) => ({
      ...prevState,
      [panel]: isExpanded,
    }));
  };

  const handleAddParameter = () => {
    const newParameter = {
      id: Date.now(),
      title: "Parameter",
      checkpoints: [],
    };
    setParameters([...parameters, newParameter]);
  };

  const handleAddCheckpoint = (parameterId) => {
    setParameters((prevParameters) =>
      prevParameters.map((parameter) => {
        if (parameter.id === parameterId) {
          return {
            ...parameter,
            checkpoints: [
              ...parameter.checkpoints,
              {
                id: Date.now(),
                title: "Checkpoint",
                name: "",
                childCheckpoints: [],
              },
            ],
          };
        }
        return parameter;
      })
    );
  };

  const handleDeleteParameter = (parameterId) => {
    setParameters(parameters.filter((param) => param.id !== parameterId));
  };

  const handleDeleteCheckpoint = (parameterId, checkpointId) => {
    const updatedParameters = parameters.map((parameter) =>
      parameter.id === parameterId
        ? {
          ...parameter,
          checkpoints: parameter.checkpoints.filter(
            (subparam) => subparam.id !== checkpointId
          ),
        }
        : parameter
    );
    setParameters(updatedParameters);
  };

  // handle file change
  const handleFileChange = (parameterIndex, checkpointIndex, event) => {
    const file = event.target.files[0];
    
    if (file && file.name) {
      const fileName = `sample_${parameterIndex}_${checkpointIndex}`;
      formDataRef.current.append(fileName, file);

      // Create a preview URL using FileReader
      const reader = new FileReader();
      reader.onload = () => {
        const previewUrl = reader.result; // Get the data URL for the image
  
        setParameters(prevParameters => {
          const newParameters = [...prevParameters];
          if (newParameters[parameterIndex]?.checkpoints?.[checkpointIndex]) {
            newParameters[parameterIndex].checkpoints[checkpointIndex] = {
              ...newParameters[parameterIndex].checkpoints[checkpointIndex],
              imagePreview: previewUrl,
              sampleImage: fileName, // Store the preview URL
              imageName: file.name,
              file: file
            };
          }
          return newParameters;
        });
      };
      reader.readAsDataURL(file); // Read file as a Data URL
    }
  };
  

  let totalScoreParameters = 0;

  const handleSave = async (e, status) => {
    e.preventDefault();
    try {
      totalScoreParameters = parameters.reduce((total, params) => {
        return total + Number(params.totalScore);
      }, 0);

      if (totalScoreParameters !== mvrData.totalScore && status === 'completed') {
        return toast.error("Total Parameters Score must equal Total Evaluation Score");
      }

      const formattedParameters = [];
      for (const [paramIndex, param] of parameters.entries()) {
        if (!param.title || param.title.trim() === "") {
          return toast.error("Parameter Title is required");
        }

        if (!param.totalScore || isNaN(Number(param.totalScore))) {
          return toast.error("Parameter total score must be ");
        }

        const totalCheckpointScore = param.checkpoints.reduce((total, checkpoint) => {
          return total + (Number(checkpoint.totalScore) || 10);
        }, 0);

        if (totalCheckpointScore !== Number(param.totalScore) && status === 'completed') {
          return toast.error("Total Checkpoints Score must equal Total Parameter Score");
        }

        const formattedChecklists = [];
        for (const [checkpointIndex, checkpoint] of param.checkpoints.entries()) {
          if (!checkpoint.title && !checkpoint.name) {
            return toast.error("Checkpoint must have a title or name");
          }

          if (!checkpoint.totalScore || isNaN(Number(checkpoint.totalScore))) {
            return toast.error("Checkpoint total must be filled");
          }


          // Accessing items based on the parameter index
          const selectedDropdown = items[paramIndex]?.[checkpointIndex] || [];


          if (selectedDropdown.includes('idealRequirement') && !checkpoint.idealRequirement) {
            return toast.error("Checkpoint ideal requirement must be filled");
          }

          if (selectedDropdown.includes('scoringCriteria') && !checkpoint.criteria) {
            return toast.error("Checkpoint scoring criteria should not be empty");
          }

          if (selectedDropdown.includes('sampleImage') && !checkpoint.sampleImage) {
            return toast.error("Checkpoint sample image must be selected");
          }

          if (selectedDropdown.includes('evidenceUpload') && !checkpoint.imageCount) {
            return toast.error('Evidence upload must be filled');
          }

          // if (selectedDropdown.includes('addDropdown') && !checkpoint.controlType) {
          //   return toast.error('Select at least one dropdown');
          // }

          formattedChecklists.push({
            checklist: checkpoint.title || checkpoint.name,
            totalScore: Number(checkpoint.totalScore),
            idealRequirement: checkpoint.idealRequirement,
            criteria: checkpoint.criteria,
            sampleImage: checkpoint.orgUrl,
            priority: checkpoint.priority,
            imageCount: checkpoint.imageCount,
            imageMandate: checkpoint.imageMandate || false,
            controlType: checkpoint?.controlType,
            controlPlaceholder: checkpoint.controlPlaceholder,
            controlValues: checkpoint.controlValues,

          });
        }

        formattedParameters.push({
          parameter: param.title,
          totalScore: Number(param.totalScore) || 10,
          checklists: formattedChecklists,
        });
      }

      const payload = {
        mvrId: mvrId,
        changeStatusTo: status,
        parameters: formattedParameters,
      };
      formDataRef.current.append("body", JSON.stringify(payload));

      const res = await UpdateManual({ payload: formDataRef.current });
      toast.success("Saved successfully");
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.error ?? "Something went wrong");
    }
  };


  const parameterChanges = async (e, index) => {
    setParameters((prevParameters) => {
      const newParameters = [...prevParameters];
      if (newParameters[index]) {
        newParameters[index] = {
          ...newParameters[index],
          title: e.target.value,
        };
      }
      return newParameters;
    });
  };

  const handleCheckPointChange = async (e, parameterIndex, checkpointIndex) => {
    const value = e.target.value;

    setParameters((prevParameters) => {
      const newParameters = [...prevParameters];
      if (newParameters[parameterIndex]?.checkpoints[checkpointIndex]) {
        newParameters[parameterIndex].checkpoints[checkpointIndex] = {
          ...newParameters[parameterIndex].checkpoints[checkpointIndex],
          name: value,
          title: value,
        };
      }
      return newParameters;
    });
  };

  const handleTotalScore = (e, index) => {
    const value = e.target.value;

    setParameters((prevParameters) => {
      const newParameters = [...prevParameters];

      if (newParameters[index]) {
        newParameters[index] = {
          ...newParameters[index],
          totalScore: value,
        };
      }

      return newParameters;
    });
  };

  // handle checkpoint total
  const handleCheckpointTotalScore = (e, parameterIndex, checkpointIndex) => {
    const value = e.target.value;
    setParameters((prevParameters) => {
      const newParameters = [...prevParameters];

      if (newParameters[parameterIndex]?.checkpoints[checkpointIndex]) {
        newParameters[parameterIndex].checkpoints[checkpointIndex] = {
          ...newParameters[parameterIndex].checkpoints[checkpointIndex],
          totalScore: value,
        };
      }

      return newParameters;
    })
  }

  const handleCheckBox = (e, pIndex, cIndex) => {
    setItems((prevParameters) => {
      const parametersArr = [...(prevParameters || [])];

      // Ensure that pIndex and cIndex are initialized
      if (!parametersArr[pIndex]) {
        parametersArr[pIndex] = [];
      }
      if (!parametersArr[pIndex][cIndex]) {
        parametersArr[pIndex][cIndex] = [];
      }

      const cItems = [...parametersArr[pIndex][cIndex]];

      // Toggle the item in the cItems array
      if (cItems.includes(e.target.value)) {
        parametersArr[pIndex][cIndex] = cItems.filter(
          (item) => item !== e.target.value
        );
      } else {
        cItems.push(e.target.value);
        parametersArr[pIndex][cIndex] = cItems;
      }

      return parametersArr;
    });
  };



  const handleDropDownClick = (pIndex, cIndex) => {
    setCurrIndex(pIndex)
    setCurrentCheckPointIndex(cIndex)
    setModal(true)
  }

  const saveDropDown = () => {
    if (!selectedOption) {
      toast.error('Please select a value')
      return
    }

    let controlType = '';
    let placeholder = [];
    let valuesss = [];
    if (selectedOption === 'YesNo') {
      controlType = "confirmation"
      const arr = [
        {
          value: 1,
          display: "Yes"
        },
        {
          value: 0,
          display: "No"
        }
      ];
      const pArr = ["No", "Yes"]
      valuesss = [0, 1]
      placeholder.push(...pArr)
      const newArray = [...dropDownArr];
      if (!newArray[currIndex]) {
        newArray[currIndex] = [];
      }
      newArray[currIndex][currentCheckPointIndex] = arr;
      setDropDownArr(newArray);
    }

    if (selectedOption === 'Number') {
      controlType = 'number';
      const lower = Number(lowerValueChange);
      const upper = Number(upperValueChange);
      valuesss = Array.from(
        { length: upper - lower + 1 },
        (_, index) => lower + index
      );
      placeholder = [...valuesss.map(String)];

      const arr = valuesss.map((num) => ({
        value: num,
        display: num.toString()
      }));

      const newArray = [...dropDownArr];
      if (!newArray[currIndex]) {
        newArray[currIndex] = [];
      }

      newArray[currIndex][currentCheckPointIndex] = arr;
      setDropDownArr(newArray);
    }

    if (selectedOption === 'Customize') {
      controlType = 'custom';
      const customizedFields = [...fields];

      placeholder = customizedFields.map((field) => field.text);
      valuesss = customizedFields.map((field) => field.value);

      const arr = customizedFields.map((field) => ({
        value: field.value,
        display: field.text
      }));

      const newArray = [...dropDownArr];
      if (!newArray[currIndex]) {
        newArray[currIndex] = [];
      }

      newArray[currIndex][currentCheckPointIndex] = arr;
      setDropDownArr(newArray);
    }
    handleInputChange(currIndex, currentCheckPointIndex, "controlType", controlType, null, null)
    handleInputChange(currIndex, currentCheckPointIndex, "controlPlaceholder", placeholder, null, null)
    handleInputChange(currIndex, currentCheckPointIndex, "controlValues", valuesss, null, null)

    setModalState(false)
  }

  const handleModalChange = (event) => {
    const value = event?.target?.value || event;
    setSelectedOption(value);
  };




  return (
    <>
      <ToastContainer />
      <CreationContain>
        {customMatches ? (
          <CreationCardMobile>
            <h4>Open in Desktop!</h4>
          </CreationCardMobile>
        ) : (
          <CreationCard>
            <div className="CreationHeader">
              <div>
                <h4>Create Dealer Evaluation Checklist</h4>
                <p>Fill in the request detail to create new checklist</p>
              </div>
            </div>
            <Grid container spacing={2}>
              <Grid item size={12}>
                <div className="CreationButtonCard">
                  <h4>Element</h4>
                  <button
                    className="CreationButton"
                    onClick={handleAddParameter}
                  >
                    <AddIcon /> Parameter
                  </button>
                </div>
              </Grid>
              <Grid item size={12}>
                <div className="CreationPreview">
                  <div className="CheckListPreviewCard">
                    <div className="ScoreCard">
                      <p>Used / Total Evaluation Scope</p>
                      <p>
                        (<span className="ScoreCount">{totalScoreParameters}</span> /{" "}
                        <span className="ScoreTotalCount">
                          {mvrData.totalScore}
                        </span>
                        )
                      </p>
                    </div>
                    <div className="CheckpointNameCard">
                      <p className="CheckpointName">{mvrData.versionName}</p>
                      <p className="ScoreCount">100</p>
                    </div>

                    {/* Dynamically render added Parameters */}
                    {parameters.map((parameter, index) => (
                      <Accordion
                        key={parameter.id}
                        expanded={
                          expanded[`parameter-${parameter.id}`] || false
                        }
                        onChange={handleAccordionChange(
                          `parameter-${parameter.id}`
                        )}
                        className="Border_PL"
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`parameter-${parameter.id}-content`}
                          id={`parameter-${parameter.id}`}
                          className="Border_PB"
                        >
                          <Typography>Parameter</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="ChecklistAddCard P">
                            {" "}
                            {/* Delete Parameter button */}
                            <button
                              className="CloseCheckpoint"
                              onClick={() =>
                                handleDeleteParameter(parameter.id)
                              }
                            >
                              <CloseIcon />
                            </button>
                            <div className="CheckListInputContain">
                              <span className="FirstName">P</span>
                              <input
                                type="text"
                                placeholder="Enter Parameter"
                                // defaultValue={parameter.title}
                                value={parameter.title}
                                onChange={(e) => parameterChanges(e, index)}
                              />
                            </div>
                            <div>
                              <input
                                type="number"
                                name="totalScore"
                                value={parameter.totalScore}
                                className="ScoreResievedCount"
                                style={{ width: "50px", height: "33px" }}
                                onChange={(e) => handleTotalScore(e, index)}
                              />
                            </div>
                            <div className="ScoreResievedCount">
                              0.00%
                            </div>
                            <div className="ParameterNumber">{index + 1}</div>
                            <CreationButton
                              onClick={() => handleAddCheckpoint(parameter.id)}
                            >
                              <AddIcon className="parameter" /> Checkpoint
                            </CreationButton>
                          </div>

                          {/* Dynamically render Subparameters */}
                          {parameter.checkpoints.map(
                            (checkpoint, checkpointIndex) => (
                              <Accordion
                                key={checkpoint.id}
                                expanded={
                                  expanded[`checkpoint-${checkpoint.id}`] ||
                                  false
                                }
                                onChange={handleAccordionChange(
                                  `checkpoint-${checkpoint.id}`
                                )}
                                className="Border_CPL"
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls={`checkpoint-${checkpoint.id}-content`}
                                  id={`checkpoint-${checkpoint.id}`}
                                  className="Border_CPB"
                                >
                                  <Typography>Check Point</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <div className="ChecklistAddCard CP">
                                    {/* Delete Checkpoint button */}
                                    <button
                                      className="CloseCheckpoint"
                                      onClick={() =>
                                        handleDeleteCheckpoint(
                                          parameter.id,
                                          checkpoint.id
                                        )
                                      }
                                    >
                                      <CloseIcon />
                                    </button>
                                    <div className="CheckListInputContain">
                                      <span className="FirstName">CP</span>
                                      <input
                                        type="text"
                                        placeholder="Enter Checkpoint"
                                        value={checkpoint.name || ""}
                                        onChange={(e) =>
                                          handleCheckPointChange(
                                            e,
                                            parameters.indexOf(parameter),
                                            checkpointIndex
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="ScoreResievedCount">
                                      <input type="number"
                                        name="totalScore"
                                        value={checkpoint.totalScore || ''}
                                        style={{ width: '34px' }}
                                        onChange={(e) => handleCheckpointTotalScore(e, parameters.indexOf(parameter), checkpointIndex)}
                                      />
                                    </div>
                                    {/* <div className="ScorePersentage">0.00%</div> */}
                                    <div className="ParameterNumber">
                                      {index + 1}.{checkpointIndex + 1}
                                    </div>
                                    <div className="SelectContain">
                                      <FormControl fullWidth>
                                        <Select
                                          labelId="checkbox-select-label"
                                          id="checkbox-select"
                                          value={""}
                                          onChange={(e) => handleCheckBox(e, index, checkpointIndex)}
                                          sx={{
                                            height: 50,
                                            ".MuiSelect-select": {
                                              height: '40px !important',
                                              display: 'flex',
                                              alignItems: 'center',
                                              padding: '0'
                                            },
                                          }}
                                          displayEmpty
                                        >
                                          {MVRCheckListMenu.map((menu, mIndex) => (
                                            <MenuItem key={mIndex} value={menu.value}>
                                              {menu.value && <Checkbox checked={items?.[index]?.[checkpointIndex]?.includes(menu.value) || false} />}
                                              {menu.display}
                                            </MenuItem>
                                          ))}
                                        </Select>

                                      </FormControl>

                                    </div>
                                  </div>
                                  <Box mt={2}>
                                    {(items?.[index]?.[checkpointIndex]?.includes("idealRequirement") || false) &&
                                      <div className="ChecklistAddCard P CheckpoinChilds" >
                                        <div className="CheckListInputContain">
                                          <span className="FirstName irColor">IR</span>
                                          <input
                                            onChange={(e) => handleInputChange(index, checkpointIndex, "idealRequirement", e.target.value, e)}
                                            type="text"
                                            value={parameters?.[index]?.[checkpointIndex] || parameter?.checkpoints[checkpointIndex]?.idealRequirement}
                                            placeholder="Enter Ideal Requirment" />
                                        </div>
                                      </div>}


                                    {(items?.[index]?.[checkpointIndex]?.includes("scoringCriteria") || false) &&
                                      <div className="ChecklistAddCard P CheckpoinChilds" >
                                        <div className="CheckListInputContain">
                                          <span className="FirstName srColor">SR</span>
                                          <input
                                            type="text"
                                            onChange={(e) => handleInputChange(index, checkpointIndex, "scoringCriteria", e.target.value, e)}
                                            value={parameters?.[index]?.[checkpointIndex] || parameter?.checkpoints[checkpointIndex]?.criteria}
                                            placeholder="Enter Scoring Criteria" />
                                        </div>
                                      </div>}


                                    {(items?.[index]?.[checkpointIndex]?.includes("addDropdown") || false) &&
                                      <div className="ChecklistAddCard P CheckpoinChilds" >
                                        <div className="CheckListInputContain">
                                          <span className="FirstName ddColor">DD</span>
                                          <select>
                                            <option value="">Select</option>
                                            {dropDownArr.length > 0 && dropDownArr?.[index]?.[checkpointIndex]?.map((option, index) => (
                                              <option key={index} value={option.value}>
                                                {option.display}
                                              </option>
                                            ))}
                                          </select>
                                        </div>

                                        <button
                                          className="AddIcon"
                                          onClick={(e) => handleDropDownClick(index, checkpointIndex)}
                                        >
                                          <AddIcon />
                                        </button>
                                      </div>}


                                    {(items?.[index]?.[checkpointIndex]?.includes("sampleImage") || false) &&
                                      <div className="ChecklistAddCard P CheckpoinChilds" >


                                        <div className="CheckListInputContain">
                                          <span className="FirstName siColor">SI</span>
                                          {parameter?.checkpoints[checkpointIndex]?.sampleImage ? (
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                              <span>{parameter.checkpoints[checkpointIndex]?.imageName}</span>
                                              <IconButton
                                                size="small"
                                                onClick={() => handleRemoveImage(index, checkpointIndex)}
                                                style={{ marginLeft: "8px" }}
                                              >
                                                <CloseIcon fontSize="small" />
                                              </IconButton>
                                            </div>
                                          ) : (
                                            <input
                                              onChange={(e) => handleInputChange(index, checkpointIndex, "sampleImage", e.target.value, e)}
                                              type="file"
                                            />
                                          )}
                                        </div>


                                        {parameter?.checkpoints[checkpointIndex]?.sampleImage && (
                                          <div>
                                            <img
                                              src={parameter.checkpoints[checkpointIndex].imagePreview}
                                              alt={parameter.checkpoints[checkpointIndex].imageName || "Uploaded Image"}
                                              style={{ width: "40px", height: "40px", objectFit: "cover" }}
                                            />
                                          </div>
                                        )}

                                      </div>}


                                    {(items?.[index]?.[checkpointIndex]?.includes("evidenceUpload") || false) &&
                                      <div className="ChecklistAddCard P CheckpoinChilds" >
                                        <div className="CheckListInputContain">
                                          <span className="FirstName cpColor"> <PhotoCameraIcon /></span>
                                          <input
                                            type="text"
                                            onChange={(e) => handleInputChange(index, checkpointIndex, "evidenceUpload", e.target.value, e)}
                                            value={parameters?.[index]?.[checkpointIndex] || parameter?.checkpoints[checkpointIndex]?.imageCount}
                                            placeholder="Enter Evidence Upload" />
                                        </div>
                                        <div className="MandatoryInput">
                                          <input
                                            onChange={(e) => handleInputChange(index, checkpointIndex, "dasf", e.target.value, e)}
                                            checked={parameters?.[index]?.[checkpointIndex] || parameter?.checkpoints[checkpointIndex]?.imageMandate} type="checkbox" />
                                          Mandatory Image
                                        </div>
                                      </div>}



                                    {(items?.[index]?.[checkpointIndex]?.includes("remarks") || false) &&
                                      <div className="ChecklistAddCard P CheckpoinChilds" >
                                        <div className="CheckListInputContain">
                                          <span className="FirstName rColor">R</span>
                                          <input type="text" placeholder="Enter Remarks" />
                                        </div>
                                      </div>}









                                  </Box>


                                </AccordionDetails>
                              </Accordion>
                            )
                          )}
                        </AccordionDetails>
                      </Accordion>
                    ))}

                    <div className="CheckListButtonContain">
                      <button onClick={(e) => handleSave(e, "draft")} className="saveDraft">
                        Save as Draft
                      </button>
                      <button onClick={(e) => handleSave(e, "completed")} className="save">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>

            {/* modal start */}

            {modalState && (
              <ModalContain className={Drawer ? " " : "DrawerOpen"}>
                <div className="CheckpointModal">
                  <div className="ModalHeader">
                    <h4>Add Dropdown Optaions</h4>
                    <button
                      className="CloseButton"
                      onClick={() => setModalState(false)}
                    >
                      <CloseIcon />
                    </button>
                  </div>
                  <div className="ModalBody">
                    <h4>Select Option Type:</h4>
                    <div className="ModalTabsContain">
                      <label>
                        <input
                          type="radio"
                          name="DropDown"
                          value="YesNo"
                          onChange={handleModalChange}
                        />{" "}
                        Yes / No
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="DropDown"
                          value="Number"
                          onChange={handleModalChange}
                        />{" "}
                        Number
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="DropDown"
                          value="Customize"
                          onChange={handleModalChange}
                        />{" "}
                        Customize
                      </label>
                    </div>
                    <div>
                      {/* {selectedOption === "YesNo" && (
                        <div className="ModalFeildContain">
                          <input type="text" placeholder="Yes" readOnly />
                          <input type="text" placeholder="No" readOnly />
                        </div>
                      )} */}
                      {selectedOption === "Number" && (
                        <div className="ModalFeildContain">
                          <input type="text" placeholder="Lower No." onChange={(e) => { setHandleLowerValue(e.target.value) }} />
                          <input type="text" placeholder="Upper No." onChange={(e) => { setHandleUpperValue(e.target.value) }} />
                        </div>
                      )}
                      {selectedOption === "Customize" && (
                        <>
                          {/* Default Field (Always at the Top) */}
                          <div className="ModalFeildContain">
                            <input
                              type="text"
                              placeholder="Text"
                              readOnly
                              value="Default Text"
                            />
                            <input
                              type="text"
                              placeholder="Value"
                              readOnly
                              value="Default Value"
                            />
                            <button
                              className="AddCutomize"
                              onClick={handleAddField}
                            >
                              <AddIcon />
                            </button>
                          </div>

                          {/* Dynamically Added Fields */}
                          {fields.map((field, index) => (
                            <div key={index} className="ModalFeildContain">
                              <input
                                type="text"
                                placeholder="Text"
                                value={field.text}
                                onChange={(e) =>
                                  handleModalInputChange(
                                    index,
                                    "text",
                                    e.target.value
                                  )
                                }
                              />
                              <input
                                type="text"
                                placeholder="Value"
                                value={field.value}
                                onChange={(e) =>
                                  handleModalInputChange(
                                    index,
                                    "value",
                                    e.target.value
                                  )
                                }
                              />
                              <button
                                className="DeleteField"
                                onClick={() => handleDeleteField(index)}
                              >
                                <DeleteIcon />
                              </button>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="ModalFooter">
                    <button onClick={saveDropDown}>Save</button>
                  </div>
                </div>
              </ModalContain>
            )}

            {/* modal end */}
          </CreationCard>
        )}
      </CreationContain>
    </>
  );
};

export default MVRManualCreation;