import { apiCall } from "./authServices";

export const GetMvrTableData = async ({payload}) => {
    try{
        const endpoint = `api/dashboard/mvr-table-data`;
        const res = await apiCall({
            endpoint: endpoint,
            payload: payload,
            method: "POST"
        });
        return res;
    }catch(error){
        throw error;
    }
}