import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid2";
import { FilterField } from "../../style/filter";

const Filter = ({ filters, onFilterChange }) => {
  const [searchValue, setSearchValue] = useState({});
  const [isDropdownOpen, setDropdownOpen] = useState({});
  const [selectedValues, setSelectedValues] = useState({});
  const dropdownRefs = useRef({});

  const handleSearchChange = (e, filterKey) => {
    setSearchValue((prev) => ({
      ...prev,
      [filterKey]: e.target.value,
    }));
    setDropdownOpen((prev) => ({ ...prev, [filterKey]: true }));
  };

  const handleOptionSelect = (filterKey, value, label) => {
    setSearchValue((prev) => ({
      ...prev,
      [filterKey]: label,
    }));
    setDropdownOpen((prev) => ({ ...prev, [filterKey]: false }));

    // Use a timeout to avoid triggering updates during render
    setTimeout(() => {
      onFilterChange(filterKey, value);
    }, 0);
  };

  const handleCheckboxChange = (filterKey, value, label) => {
    setSelectedValues((prev) => {
      const existingValues = prev[filterKey] || [];
      const isAlreadySelected = existingValues.some((item) => item.value === value);

      const updatedValues = isAlreadySelected
        ? existingValues.filter((item) => item.value !== value)
        : [...existingValues, { value, label }];

      // Use a timeout to avoid triggering updates during render
      setTimeout(() => {
        onFilterChange(filterKey, updatedValues.map((item) => item.value));
      }, 0);

      return { ...prev, [filterKey]: updatedValues };
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      Object.keys(dropdownRefs.current).forEach((key) => {
        if (
          dropdownRefs.current[key] &&
          !dropdownRefs.current[key].contains(event.target)
        ) {
          setDropdownOpen((prev) => ({ ...prev, [key]: false }));
        }
      });
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {filters.map((filter, index) => (
        <Grid
          item="true"
          size={{ xs: filter.xs || 6, sm: filter.sm || 4, lg: filter.lg || 2 }}
          key={index}
        >
          <FilterField>
            <label>{filter.label}</label>
            {filter.type === "input" && filter.disable ? (
              <input
                type="text"
                value={filter.value || ""}
                disabled={filter.disable}
                readOnly
              />
            ) : (
              <div
                style={{ position: "relative" }}
                ref={(el) => (dropdownRefs.current[filter.key] = el)}
              >
                <input
                  type="text"
                  placeholder="Search"
                  value={
                    filter.multipleSelect
                      ? searchValue[filter.key] || ""
                      : searchValue[filter.key] || ""
                  }
                  onChange={(e) => handleSearchChange(e, filter.key)}
                  onFocus={() =>
                    setDropdownOpen((prev) => ({ ...prev, [filter.key]: true }))
                  }
                  style={{
                    width: "100%",
                    padding: "8px",
                    boxSizing: "border-box",
                  }}
                />
                {isDropdownOpen[filter.key] && (
                  <ul
                    style={{
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      right: 0,
                      backgroundColor: "white",
                      border: "1px solid #ccc",
                      listStyle: "none",
                      maxHeight: "150px",
                      overflowY: "auto",
                      zIndex: 10,
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    {filter.options
                      .filter((option) =>
                        option.label
                          .toLowerCase()
                          .includes((searchValue[filter.key] || "").toLowerCase())
                      )
                      .map((option, optIndex) => {
                        if (filter.multipleSelect) {
                          const isSelected =
                            selectedValues[filter.key]?.some(
                              (item) => item.value === option.value
                            ) || false;
                          return (
                            <li
                              key={optIndex}
                              style={{
                                padding: "8px",
                                cursor: "pointer",
                                borderBottom: "1px solid #eee",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <input
                                type="checkbox"
                                checked={isSelected}
                                onChange={() =>
                                  handleCheckboxChange(
                                    filter.key,
                                    option.value,
                                    option.label
                                  )
                                }
                                style={{
                                  marginRight: "8px",
                                  width: "14px",
                                  height: "14px",
                                }}
                              />
                              {option.label}
                            </li>
                          );
                        } else {
                          return (
                            <li
                              key={optIndex}
                              onClick={() =>
                                handleOptionSelect(
                                  filter.key,
                                  option.value,
                                  option.label
                                )
                              }
                              style={{
                                padding: "8px",
                                cursor: "pointer",
                                borderBottom: "1px solid #eee",
                              }}
                            >
                              {option.label}
                            </li>
                          );
                        }
                      })}
                  </ul>
                )}
              </div>
            )}
          </FilterField>
        </Grid>
      ))}
    </>
  );
};

export default Filter;
