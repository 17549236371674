import { Box, Button, Typography } from "@mui/material";
import { styled, width } from "@mui/system";
import { Colors } from "../theme";
import { Label } from "@mui/icons-material";


export const FormAccordianContain = styled(Box)(({theme})=>({
    '& .ExpandButtonContain':{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "10px",
        gap:'5px',
        [theme.breakpoints.down("sm")]: {
            paddingRight:'5px',
          },
    },
    '& .MuiAccordionSummary-root':{
        minHeight:'40px',
        '&.Mui-expanded':{
            minHeight:'45px',
        },
    },
    '& .FormAccordianSummary':{
        width:'100%',
        justifyContent:'start',
        '& .MuiAccordionSummary-content':{
            fontSize: "12px",
            display: "flex",
            alignItems: "center",
            gap:'5px',
            alignItems:'center',
            gap:'10px',
            width:'90%',
            margin:'8px 0',
            '& .AccrdianTitle':{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%", // Adjust width as needed
                display: "inline-block",
            },
            '& .ResivedScore':{
                marginLeft:'auto',
                marginRight:'10px',
            }
        },
        '& span':{
            fontSize:'13px',
            '&.CheckIcon':{
                color:'green',
            },
        }
    },
    '& .FormAccordianContent':{
        fontSize:'12px',
        paddingTop:'25px',
        '& .DataContain':{
            display:'flex',
            gap:'10px',
            alignItems:'top',
        },
        '& .ImageContain':{
            width:'50px',
            height:'50px',
            overflow:'hidden',
            borderRadius:'50%',
            '& img':{
                width:'100%',
                height:'auto',
            },
        },
        '& .FieldContain':{
            width:'100%',
            '&.Checkbox':{
                display:'flex',
                alignItems:'center',
                justifyContent:'start',
                gap:'10px',
                border:'1px solid',
                borderColor:Colors.bordercolor,
                paddingLeft:'10px',
                height:'40px',
                // [theme.breakpoints.down("sm")]: {
                //     minHeight:'40px',
                //   },
                '& input':{
                    width:'16px',
                },
                '& label':{
                    margin:'0'
                }
            },
            '& label':{
                display:'block',
                fontWeight:500,
                marginBottom:'6px',
                fontSize:'12px',
            },
            '& input,& select,& textarea':{
                padding:'10px',
                backgroundColor:Colors.white,
                fontSize:'12px',
                width:'100%',
                border:'1px solid',
                borderColor:Colors.bordercolor,
                height:'40px',
                // [theme.breakpoints.down("sm")]: {
                //     minHeight:'40px',
                //   },
            },
            '& textarea':{
                resize:'vertical',
            },
            '& .DatePickerContain':{
                border:'1px solid',
                borderColor:Colors.bordercolor,
                '& .MuiFormControl-root':{
                    overflow:'hidden',
                    '& .MuiInputLabel-root':{
                        top:'-4px',
                    },
                },
            '& input':{
                border:'none',
                padding:'0',
                paddingLeft:'10px',
            },
            '& .MuiOutlinedInput-notchedOutline':{
                display:'none',
            },
            }
        },
    },
    '& .borderB':{
        borderBottom:'1px solid',
        borderColor:Colors.accordianBrderColor,
    },
    '& .borderL':{
        borderLeft:'2px solid',
        borderColor:Colors.accordianBrderColor,
    },

}))

export const ExpandAll = styled(Button)(()=>({
    backgroundColor:'#5198d0',
    color:'#fff',
}))

export const UploadContain = styled(Box)(({theme})=>({
    display:'flex',
    alignItems:'center',
    justifyContent:'space-around',
    gap:'10px',
    overflowX:'auto',
    overflowY:'hidden',
    width:'100%',
    border:'1px solid',
    borderColor:Colors.bordercolor,
    height:'40px',
    paddingLeft:'5px',
    paddingRight:'5px',
    '& .UploadDivider':{
        height:'100%',
        width:'1px',
        backgroundColor:Colors.bordercolor,
        height:'40px',
    },
    '& .UploadImg':{
        width:'30px',
        height:'30px',
        overflow:'hidden',
        borderRadius:'50%',
        border:'1px solid',
        borderColor:Colors.bordercolor,
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        cursor:'pointer',
        '& img':{
            width:'100%',
        },
    },
    '& .UploadImgButton':{
        padding: "10px",
        border: "1px solid #ccc",
        backgroundColor: "#f5f5f5",
        borderRadius: "4px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    '& .EvaluaterUploadContain':{
        display:'flex',
        alignItems:'center',
        justifyContent:'start',
        gap:'10px',
        width:'100%',
        overflow:'auto',
        [theme.breakpoints.down("sm")]: {
            justifyContent:'center',
          },
        '& .UploadbtnContain':{
            width:'40px',
            height:'40px',
        }
    }
        
}))

export const FormModal = styled(Box)(() => ({
    // maxHeight: '400px',
    maxWidth: '500px',
    width: '100%',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column', // Ensures header, body, and footer are stacked vertically
    '& .ModalHeader': {
      position: 'relative',
      padding: '15px 10px',
      backgroundColor: Colors.primary,
      color: Colors.white,
      '& h4': {
        paddingRight: '20px',
        fontWeight: 400,
      },
      '& .CloseButton': {
        position: 'absolute',
        right: '5px',
        top: '5px',
        backgroundColor: 'transparent',
        color: Colors.white,
      },
    },
    '& .ModalBody': {
      flex: 1, // Makes the body take available space between header and footer
      overflow: 'hidden', // Ensures content does not overflow
      display: 'flex', // Centers the image
      justifyContent: 'center',
      alignItems: 'center',
      padding: '10px',
      '& img': {
        maxWidth: '400px', // Image fits within the modal width
        maxHeight: '300px', // Image fits within the modal height
        objectFit: 'contain', // Ensures the image retains its aspect ratio
      },
    },
    '& .ModalFooter': {
      padding: '15px 10px',
      backgroundColor: '#fff',
    },
}));

export const InvisibleLabel = styled(Typography)(()=>({
    fontSize:'12px',
    visibility:'hidden',
    marginBottom:'3px'
}))

export const DatePickerContain =  styled(Box)(()=>({
    '& .MuiPickersCalendarHeader-root':{
        backgroundColor:Colors.datePickerHeader,
    }

}))
  
  