import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels"; // Import the plugin
import { ChartContain, ChartHeader } from "../../style/charts";
import ExcelIcon from '../../assets/images/icons/excel.png'
import { ButtonContain } from "../../style/Home";

// Register the necessary Chart.js components and the plugin
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels // Register the DataLabels plugin
);
const StakedChart = ({ StakedChartData }) => {
    const { title, labels, group1, group2 } = StakedChartData;
  
    // Define colors for datasets
    const colors = [
      { background: "rgba(75, 192, 192, 0.6)", border: "rgba(75, 192, 192, 1)" }, // Color 1
      { background: "rgba(255, 99, 132, 0.6)", border: "rgba(255, 99, 132, 1)" }, // Color 2
      { background: "rgba(54, 162, 235, 0.6)", border: "rgba(54, 162, 235, 1)" }, // Color 3
    ];
  
    // Prepare datasets dynamically for Group 1
    const group1Datasets = Object.keys(group1).map((key, index) => ({
      label: `Group 1 - ${key}`,
      data: group1[key],
      backgroundColor: colors[index % colors.length].background, // Cycle through colors
      borderColor: colors[index % colors.length].border,
      borderWidth: 1,
      stack: "stack1", // Stack for Group 1
    }));
  
    // Prepare datasets dynamically for Group 2
    const group2Datasets = Object.keys(group2).map((key, index) => ({
      label: `Group 2 - ${key}`,
      data: group2[key],
      backgroundColor: colors[index % colors.length].background, // Cycle through colors
      borderColor: colors[index % colors.length].border,
      borderWidth: 1,
      stack: "stack2", // Stack for Group 2
    }));
  
    // Combine datasets
    const datasets = [...group1Datasets, ...group2Datasets];
  
    const data = {
      labels: labels,
      datasets: datasets,
    };
  
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            enabled: true,
            callbacks: {
              label: function (context) {
                return `${context.dataset.label}: ${context.raw}%`;
              },
            },
          },
          legend: {
            position: "bottom",
            labels: {
              boxWidth: 20,
              generateLabels: (chart) => {
                // Custom legend generation
                const uniqueColors = [];
                return chart.data.datasets
                  .filter((dataset) => {
                    const colorExists = uniqueColors.includes(dataset.backgroundColor);
                    if (!colorExists) uniqueColors.push(dataset.backgroundColor);
                    return !colorExists;
                  })
                  .map((dataset, index) => ({
                    text: `Color ${index + 1}`, // Custom label text
                    fillStyle: dataset.backgroundColor,
                    strokeStyle: dataset.borderColor,
                    lineWidth: dataset.borderWidth,
                    hidden: false,
                  }));
              },
            },
          },
          datalabels: {
            display: true,
            color: "white",
            formatter: (value) => `${value}%`,
            anchor: "center",
            align: "center",
            font: {
              weight: "bold",
            },
          },
        },
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false,
            },
          },
          y: {
            stacked: true,
            beginAtZero: true,
            max: 100,
            ticks: {
              stepSize: 10,
              callback: (value) => `${value}%`,
            },
            grid: {
              drawOnChartArea: false,
            },
          },
        },
      };
      
  
    return (
        <ChartContain>
        <ChartHeader>
          <h2 className="Title">{title}</h2>
          <ButtonContain>
                    <button className="ExportButton" style={{margin:'0'}} onClick={() => console.log("Exporting...")}><img src={ExcelIcon} alt="Excel icon" /></button>
              </ButtonContain>
        </ChartHeader>
      <div className="Chart">
        <Bar data={data} options={options} />
      </div>
      </ChartContain>
    );
  };
  
  export default StakedChart;
  
