import { Box, Button } from "@mui/material";
import { minWidth, styled } from "@mui/system";
import { Colors } from "../theme";


export const FormTabsContain = styled(Box)(({theme})=>({
    backgroundColor:'#f5f5f5',
    padding:'20px',
    '& .white_bg':{
        backgroundColor:Colors.white,
        boxShadow:'rgba(0, 0, 0, 0.15) 4.4px 5.4px 3.2px',
    },
    [theme.breakpoints.down("sm")]: {
        backgroundColor:'transparent',
        padding:'0',
      },
}))
export const FormTabs = styled(Box)(({theme})=>({
    '& .MuiTabs-indicator,& .MuiTabs-indicator.css-jkmkwa':{
        display:'none',
    },
    '& .MuiTabs-root':{
        overflow:'auto',
    },
    '& .css-1qltlow-MuiTabs-indicator':{
        backgroundColor:Colors.primary,
        display:'none',
    },
    '& .MuiTabs-flexContainer':{
        justifyContainer:'center',
        '& button':{
            minWidth:'150px',
            height:'105px',
            position:'relative',
            color:Colors.black,
            zIndex:2,
            alignItems:'center',
            justifyContent:'end',
            paddingBottom:'26px',
            borderTopRightRadius:'5px',
            boxShadow: 'rgba(0, 0, 0, 0.15) 4.4px 5.4px 3.2px',
            '&.Mui-selected':{
                color:Colors.primary,
                boxShadow: 'rgba(0, 0, 0, 0.15) 9.4px 5.4px 3.2px',
                '&::after':{
                    // backgroundColor:Colors.white,
                    backgroundColor:'#f9e9e2',
                },
            },
            [theme.breakpoints.down("sm")]: {
                minWidth:'120px',
                height:'85px',
                padding:'16px',
                fontSize:'12px',
              },
            '& .MuiTouchRipple-root':{
                clipPath: 'polygon(0 45%, 100% 0, 100% 100%, 0 100%)',},
            '&:first-of-type':{
                zIndex:3,},
                '&:last-of-type':{
                    zIndex:1,},
            '&::after':{
                content:'" "',
                position:'absolute',
                zIndex:-1,
                width:'100%',
                height:'100%',
                right:'0',
                top:'0',
                // backgroundColor:Colors.formPendngBg,
                backgroundColor:'#d4d4d4',
                clipPath: 'polygon(0 45%, 100% 0, 100% 100%, 0 100%)',
                borderTopRightRadius:'5px',
            },
        }
    },

}))

export const CustomTab = styled(Box)(()=>({
    display:'flex',
    justifyContent:'center',
    padding:'10px',
    '& .tabCon':{
        display:'inline-block',
        boxShadow: 'rgba(0, 0, 0, 0.15) 9.4px 5.4px 3.2px',
        position:'relative',
        '& button':{
            width:'150px',
            height:'105px',
            backgroundColor:'#f5f5f5',
            clipPath: 'polygon(0 44%, 100% 0, 100% 100%, 0 100%)',
            position:'relative',
            borderTopRightRadius:'5px'
        }
    }

}))

export const FormTotalScore = styled(Box)(({theme})=>({
    position:'absolute',
    right:'0',
    top:'-104px',
    backgroundColor:'#fff',
    padding:'17px',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    display:'flex',
    flexDirection:'column-reverse',
    alignItems:'end',
    [theme.breakpoints.down("md")]: {
        position:'static',
        flexDirection:'row',
        justifyContent:'space-between',
        boxShadow:'none',
        padding:'17px 10px',
        paddingBottom:'0',
      },
      [theme.breakpoints.down("sm")]: {
          padding:'17px 5px',
          paddingBottom:'0',
          flexWrap:'wrap',
          rowGap:'8px',
        },
    '& .TotalScore':{
        width:'fit-content',
        padding:'5px',
        border:'1px solid',
        borderColor:Colors.bordercolor,
        fontSize:'12px',
    },
    '& .MomContain':{
        fontSize:'12px',
        marginTop:'5px',
        display:'flex',
        alignItems:'center',
        justifyContent:'end',
        gap:'5px',
        '& span':{
            display:'block',
            width:'20px',
            height:'20px',
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            borderRadius:'50%',
            fontSize:'10px',
            color:Colors.white,
            '&.MoM':{
                backgroundColor:Colors.formMomclr,
            },
            '&.Reconsideration':{
                backgroundColor:Colors.formReconsiderationclr,
            },
        }
    }

}))
