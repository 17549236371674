import React from 'react'
import { Card, CardContain } from '../../style/Home';
import Table from '../../Components/table/Table';
import galleryImage from '../../assets/images/logo.png'
import secondImage from '../../assets/images/icons/bot.png'

const Gallery = () => {
      // table data start
  const headers = [
    { id: 'document_type', label: 'Document Type' },
    { id: 'created_data', label: 'Created Data' },
    { id: 'dealer_code_document', label: 'All / Dealer Code Document', mediaModal:true},
    { id: 'document_name', label: 'Document Name' },
  ];

  const data = [
    { document_type: 'Image', created_data:'11-12-2024' , dealer_code_document: galleryImage, document_name:'Image'},
    { document_type: 'Image', created_data:'11-12-2024' , dealer_code_document: secondImage, document_name:'Image'},
  ];

  // table data end
  return (
    <>
      <CardContain>
     <Card mt={2}>
     <Table headers={headers} data={data} defaultRowsPerPage={10} />
     </Card>
     </CardContain>
    </>
  )
}

export default Gallery