import NewPassword from './pages/login/Newpassword';
import SendOtp from './pages/login/Sendotp';
import GetOtp from './pages/login/Getotp';
import Forgot from './pages/login/Forgot';
import Login from './pages/login/Login';
import Home from './pages/AM/Home';
// import Forgot from './pages/login/Forgot';
// import GetOtp from './pages/login/Getotp';
// import SendOtp from './pages/login/Sendotp';
// import NewPassword from './pages/login/Newpassword';
import HoHome from './pages/HO/Home';
import CreateMVRManual from './pages/HO/CreateMVRManual';
import AuthGuard from './guards/AuthGuards';
import MVRManualCreation from './pages/HO/MVRManualCreation';
import QVRManualCreation from './pages/HO/QVRManualCreation';
import AMDStatus from './pages/AM/AMDStatus';
import MVRFormFill from './pages/AM/MVRFormFill';
import Approval from './pages/HO/Approval';
import ScheduleVisit from './pages/AM/ScheduleVisit';
import MoMStatus from './pages/AM/MoMStatus';
import MoMStatusUpdate from './pages/AM/MoMStatusUpdate';
import MoMEscalatedPoints from './pages/AM/MoMEscalatedPoints';
import Analytics from './pages/AM/Analytics';
import Gallery from './pages/AM/Gallery';

const routes = [
  { path: '/', element: <Login /> },
  { path: '/forgot', element: <Forgot /> },
  { path: '/get-otp', element: <GetOtp /> },
  { path: '/send-otp', element: <SendOtp /> },
  { path: '/new-password', element: <NewPassword /> },
  { path: '/home', element: <AuthGuard><Home /></AuthGuard> },

  // AM links
  { path: '/amdstatus', element: <AMDStatus/>},
  { path: '/mvrformfill', element: <MVRFormFill/>},
  { path: '/schedulevisit', element: <ScheduleVisit/>},
  { path: '/momstatus', element: <MoMStatus/>},
  { path: '/momstatusupdate', element: <MoMStatusUpdate/>},
  { path: '/momescalatedpoints', element: <MoMEscalatedPoints/>},
  { path: '/analytics', element: <Analytics/>},
  { path: '/gallary', element: <Gallery/>},
  // ho links 
  { path: '/hohome', element: <HoHome/>},
  { path: '/hocreatemanual', element: <AuthGuard><CreateMVRManual/></AuthGuard>},
  { path: '/mvrmanualcreation', element: <MVRManualCreation/>},
  { path: '/qvrmanualcreation', element: <QVRManualCreation/>},
  { path: '/approval', element: <Approval/>},
];

export default routes;
