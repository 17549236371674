import React, { useState, useEffect } from "react";
import { ButtonContain, FieldContain, TabsContain } from "../../style/Home";
import { Tabs, Tab, Typography } from "@mui/material"; // Import necessary components
// import { ManualTital } from '../../style/HO/CreateManualCard';
import CreateManualCard from "../../Components/HO/cards/CreateManualCard";
import { CreateManualCardContain } from "../../style/HO/CreateManualCard";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FetchMvr, CreateMvr } from "../../services/mvrServices";
import { formatDatePickerDate } from "../../utils/helper";
import { CloseButton, ContainedButtton, CreateManualModal, DflexContain, ModalContain, SearchContain } from '../../style/HO/CreateManual';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import BasicDatePicker from '../../Components/HO/DatePicker';
import { useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';


const CreateMVRManual = () => {
  const [tabValue, setTabValue] = useState("Total MVR");
  const [modalOpen, setModalOpen] = useState(false);
  const Drawer = useSelector((state) => state.drawer);
  const [validationErrors, setValidationErrors] = useState({});

  const createMvrForm = {
    versionName: "",
    procedureNumber: "",
    validFrom: "",
    validTo: "",
    totalScore: "",
  };
  const [mvrForm, setMVRForm] = useState(createMvrForm);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const fetchMVR = async () => {
    try {
      const res = await FetchMvr();
      if (res.success) {
        setDvrData(res.mvrDetails);
      }
    } catch (error) {
      console.log("error: ", error);
      toast.error(error?.response?.data?.error ?? "Something went wrong");
    }
  };

  useEffect(() => {
    fetchMVR();
  }, []);

  const [dvrData, setDvrData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => {
    setMVRForm(createMvrForm);
    setStartDate(null);
    setEndDate(null);
    // setValidationErrors(null);
    setModalOpen(false);
  };

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setMVRForm({ ...mvrForm, [name]: value });

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validateForm = () => {
    const errors = {};
    if (!mvrForm.versionName.trim())
      errors.versionName = "MVR Version Name is required.";
    if (!mvrForm.procedureNumber.trim())
      errors.procedureNumber = "Procedure No. is required.";
    if (!mvrForm.totalScore) errors.totalScore = "Total score is required.";
    // if (!startDate) errors.validFrom = "Valid From date is required.";
    // if (!endDate) errors.validTo = "Valid To date is required.";
    return errors;
  };

  // Handle form submission (Add new DVR data)
  const handleCreate = async (e) => {
    e.preventDefault();
    try {
      const errors = validateForm();
      if (Object.keys(errors).length > 0) {
        setValidationErrors(errors);
        return; // Prevent submission
      }
      const { versionName, procedureNumber, totalScore } = mvrForm;
      const payload = {
        versionName,
        procedureNumber,
        totalScore: parseInt(totalScore),
        validFrom: formatDatePickerDate(startDate),
        validTo: formatDatePickerDate(endDate),
      };

      let res = await CreateMvr({ payload: payload });
      if (res.success) {
        setDvrData((prevData) => [...prevData, res.mvrDetail]);
      }

      // Close the modal
      setMVRForm(createMvrForm);
      setStartDate(null);
      setEndDate(null);
      setModalOpen(false);
      // setValidationErrors(null);
    } catch (error) {
      console.log("error: ", error);
      toast.error(error?.response?.data?.error ?? "Something went wrong");
    }
  };

  const handleEdit = (id) => {
    // console.log(`Edit DVR with ID: ${id}`);
  };

  const handleDelete = (id) => {
    // console.log(`Delete DVR with ID: ${id}`);
    setDvrData(dvrData.filter((item) => item.id !== id));
  };

  const handlePublish = (id) => {
    console.log('id: ------->', id);
    // console.log(`Publish DVR with ID: ${id}`);
  };

  const handleCopy = (id) => {
    // console.log(`Copy DVR with ID: ${id}`);
  };

  return (
    <>
      <ToastContainer />
      <DflexContain mb={2}>
        <SearchContain>
          <input type="text" placeholder='Search' />
          <SearchIcon />
        </SearchContain>
        <ButtonContain><button style={{ marginTop: '15px' }} onClick={handleOpenModal}>Create MVR Manual</button></ButtonContain>
      </DflexContain>
      {modalOpen && (
        <ModalContain className={Drawer ? " " : "DrawerOpen"}>
          <CreateManualModal>
            <div className="ModalHeader">
              <h4>Create MVR Manual</h4>
              <button onClick={handleCloseModal} className="CloseModal">
                <CloseIcon />
              </button>
            </div>

            <div className="ModalCard">
              <Grid container spacing={2}>
                <Grid item size={6}>
                  <FieldContain>
                    <label htmlFor="name">MVR Version Name</label>
                    <input
                      type="text"
                      placeholder="Enter Name"
                      id="name"
                      name="versionName"
                      value={mvrForm.versionName}
                      onChange={handleChanges}
                    />
                    {validationErrors.versionName && (
                      <span className="error">
                        {validationErrors.versionName}
                      </span>
                    )}
                  </FieldContain>
                </Grid>

                <Grid item size={6}>
                  <FieldContain>
                    <label htmlFor="procedure">Procedure No.</label>
                    <input
                      type="text"
                      placeholder="Enter Procedure No."
                      id="procedure"
                      name="procedureNumber"
                      value={mvrForm.procedureNumber}
                      onChange={handleChanges}
                    />
                    {validationErrors.procedureNumber && (
                      <span className="error">
                        {validationErrors.procedureNumber}
                      </span>
                    )}
                  </FieldContain>
                </Grid>
                <Grid item size={12}>
                  <FieldContain>
                    <label htmlFor="procedure">Total Score</label>
                    <input
                      type="number"
                      placeholder="Enter Total Score"
                      id="score"
                      name="totalScore"
                      value={mvrForm.totalScore}
                      onChange={handleChanges}
                    />
                    {validationErrors.totalScore && (
                      <span className="error">
                        {validationErrors.totalScore}
                      </span>
                    )}
                  </FieldContain>
                </Grid>

                <BasicDatePicker
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                />
              </Grid>
            </div>

            <div className="ModalFooter">
              <ContainedButtton onClick={handleCreate} variant="contained">
                Create
              </ContainedButtton>
              {/* <CloseButton variant="contained">
              Close
            </CloseButton> */}
            </div>
          </CreateManualModal>
        </ModalContain>
      )}

      <TabsContain>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="tabs example"
          className="HomeTabs"
        >
          <Tab label="Total MVR" value="Total MVR" />
          <Tab label="Process MVR" value="Process MVR" />
          <Tab label="Pending MVR" value="Pending MVR" />
        </Tabs>

        {tabValue === "Total MVR" && (
          <>
            <CreateManualCardContain container spacing={2} mt={2}>
              {dvrData.length > 0 ? (
                dvrData.map((item, index) => (
                  <CreateManualCard
                    key={item.id}
                    data={item}
                    index={index}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    onPublish={handlePublish}
                    onCopy={handleCopy}
                  />
                ))
              ) : (
                <Typography variant="body1" color="textSecondary" align="center">
                  No MVR available
                </Typography>
              )}
            </CreateManualCardContain>

          </>
        )}
        {tabValue === "Process MVR" && <>Tab2</>}
        {tabValue === "Pending MVR" && <>Tab3</>}
      </TabsContain>
    </>
  );
};

export default CreateMVRManual;
