import React, { useState } from 'react';
import { FormControl, Select, MenuItem, Checkbox, ListItemText } from '@mui/material';

const MultiSelectDropdown = ({ data, selectedValues, onChange, label = "Select", width = "250px", disabled = false }) => {
    const [open, setOpen] = useState(false);

    const handleSelectChange = (event) => {
        event.stopPropagation();
        onChange(event);
    };

    const handleClose = (event) => {
        // Only close when clicking outside
        if (event.target.tagName !== 'INPUT') {
            setOpen(false);
        }
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <FormControl disabled={disabled} fullWidth sx={{ width: width }}>
            <Select
                labelId="checkbox-select-label"
                id="checkbox-select"
                value={""} // Default empty value
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                sx={{
                    height: 34,
                    ".MuiSelect-select": {
                        display: 'flex',
                        alignItems: 'center',
                        padding: '10px',
                        zIndex: 111
                    },
                }}
                displayEmpty
                onChange={handleSelectChange}
                renderValue={(selected) => {
                    if (!selected) return label;
                    const selectedItem = data?.find(ho => ho?.empCode === selected);
                    return selectedItem ? selectedItem.empName : label;
                }}
            >
                {data?.length === 0 && (
                    <MenuItem key={10000} value="">
                        Select
                    </MenuItem>
                )}
                {data?.map((ho, index) => (
                    <MenuItem key={index} value={ho?.value} onClick={(e) => e.stopPropagation()}>
                        {ho?.value && <Checkbox checked={selectedValues?.includes(ho?.value)} />}{ho.display}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default MultiSelectDropdown;