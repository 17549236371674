import React, { useState } from "react";
import { useLocation,useNavigate } from "react-router-dom";
import { LoginContain } from "../../style/login/Login";
import Logo from "../../assets/images/logo.png";
import seePasswordIcon from "../../assets/images/icons/eye.png";
import hidePasswordIcon from "../../assets/images/icons/eye1.png";
import { Loader } from "../../utils/loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiCall } from "../../services/authServices";

function Newpassword() {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate()
  const [formValues, setFormValues] = useState({
    password: "",
    confirmPassword: "",
  });

  const location = useLocation();
  const payloadData = location?.state?.data;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    // Perform real-time validation for confirmPassword
    if (name === "confirmPassword") {
      if (value !== formValues.password) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          confirmPassword: "Passwords do not match",
        }));
      } else {
        setErrors((prevErrors) => {
          const { confirmPassword, ...rest } = prevErrors;
          return rest;
        });
      }
    }
  };

  const validateForm = () => {
    let formErrors = {};

    if (!formValues.password) {
      formErrors.password = "Password is required";
    }

    if (!formValues.confirmPassword) {
      formErrors.confirmPassword = "Confirm password is required";
    } else if (formValues.password !== formValues.confirmPassword) {
      formErrors.confirmPassword = "Passwords do not match";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const changePassword = async (e) => {
    e.preventDefault();
    try {
      if (validateForm()) {
        console.log("Form is validated:", formValues, payloadData);
        const payload = {
          userId: payloadData.userId,
          userType: payloadData.userType,
          OTP: payloadData.OTP,
          password: formValues.password
        }
        console.log('payload: ', payload);
        const res = await apiCall({
          endpoint: "api/auth/reset-password",
          method: "POST",
          payload: payload,
        });
        if (res && res.success) {
          setTimeout(() => {
            navigate("/");
          }, 2000);
          toast.success("Password reset successfully");
        }
      }
    } catch (error) {
      console.log("Error: ", error);
      toast.error(error?.response?.data?.error ?? "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <LoginContain>
        <div className="LoginLogo">
          <img src={Logo} alt="logo" />
        </div>
        <div className="LoginCard">
          <div className="LoginTitle">
            <h4>Reset Password</h4>
            <p>EasyGo Audit support team is just a tap away!</p>
          </div>
          <form onSubmit={changePassword}>
            <div className="LoginGroup">
              <label htmlFor="password" className="forgot-label">
                Enter New Password
              </label>
              <div className="PasswrdInpt">
                <input
                  type={open ? "text" : "password"}
                  id="password"
                  name="password"
                  value={formValues.password}
                  placeholder="Enter new password"
                  onChange={handleChange}
                />
                {errors.password && <div className="error">{errors.password}</div>}
                <button
                  type="button"
                  onClick={() => setOpen(!open)}
                >
                  <img
                    src={open ? hidePasswordIcon : seePasswordIcon}
                    alt="Toggle visibility"
                  />
                </button>
              </div>
            </div>
            <div className="LoginGroup">
              <label htmlFor="confirmPassword" className="forgot-label">
                Confirm Password
              </label>
              <div className="PasswrdInpt">
                <input
                  type={open ? "text" : "password"}
                  id="confirmPassword"
                  name="confirmPassword"
                  value={formValues.confirmPassword}
                  placeholder="Confirm password"
                  onChange={handleChange}
                />
                {errors.confirmPassword && (
                  <div className="error">{errors.confirmPassword}</div>
                )}
                <button
                  type="button"
                  onClick={() => setOpen(!open)}
                >
                  <img
                    src={open ? hidePasswordIcon : seePasswordIcon}
                    alt="Toggle visibility"
                  />
                </button>
              </div>
            </div>
            <div className="LoginGroup LoginBtn">
              <button type="submit" disabled={loading}>
                {loading ? <Loader /> : "Reset Password"}
              </button>
            </div>
          </form>
        </div>
        <ToastContainer />
      </LoginContain>
    </>
  );
}

export default Newpassword;
