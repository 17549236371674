import { Box } from "@mui/material";
import { styled } from "@mui/system";
import { Colors } from "../theme";


export const TimeLineContain = styled(Box)(()=>({
    maxWidth:'305px',
    '& .TimeLine':{
        display:'flex',
        flexWrap:'wrap',
        alignItems:'end',
        backgroundColor:Colors.white,
        padding:'10px',
        borderRadius:'5px',
        gap:'5px',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        '& .TimeLineTitle':{
            color:Colors.primary,
        },
        '& span':{
            fontSize:'12px',
            '&.TimelineStart,&.TimelineEnd':{
                fontWeight:500,
    
            },},
    }
}))